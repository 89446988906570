import React, { useEffect, useRef } from "react";
import styles from "./NextRound.module.css";
import {ReactComponent as ClockSVG} from "../../../assets/dashboard/clock.svg";
import classNames from "classnames";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAccount } from "wagmi";
import PausedState from "./PausedState";
import {ReactComponent as TriangleSVG} from "../../../assets/dashboard/triangle.svg";
import {ReactComponent as InfoSVG} from "../../../assets/dashboard/info.svg";
import { useERC20, usePrediction, useStats } from "../../../core";
import { getTokenIconName } from "../../../tokenIcon/tokenIcon";
import { numberWithCommas } from "../../../utils/libs";
import { getGroupsByKey } from "../../../core/utils/networkUtils";
import binance from "../../../assets/binance-coin-bnb-logo-freelogovectors.net_.png";
import Loading from "../../Loading/Loading";


type Props = { data: any; partnerCode?: any };

function NextRound({ data, partnerCode }: Props) {

  const [amount, setAmount] = useState(0);
  const [timeLeft, setTimeLeft] = useState(0);
  const [openDropDown, setOpenDropDown] = useState(false);

  const [inputError, setInputError] = useState("");
  const [tokenBalance, setTokenBalance] = useState(0);
  // console.log("next round data", roundData)

 


  // // // / / / / // / // / /
  const { getMultiRoundData, getMinBetAmount, betBull, betBear } =
  usePrediction();
const { getUserBettingTokenBalance } = useStats();
const { approvePrediction, isApproved } = useERC20();
const currentGroup = useSelector((state:any) => state.currentGroup);
const timerTick = useSelector((state:any) => state.timerTick);
const roundLockTimestamp = useSelector((state:any) => state.roundLockTimestamp);
const group = useSelector((state:any) => state.currentGroup);
const { isConnected,address } = useAccount();
const paused = useSelector((state:any) => state.paused);
const calculating = useSelector((state:any) => state.calculating);
const duration = useSelector((state:any) => state.duration);
const currentIcon = getTokenIconName(currentGroup.token);
const bet = useSelector((state:any) => state.bet);
const network = useSelector((state:any) => state.network);
const timer = useSelector((state:any) => state.roundLockTimestamp);
const [bettingState, setBettingState] = useState<any>({});
const audioFlag = useSelector((state:any) => state.audio);
const num = useSelector((state:any) => state.num);
const audio = new Audio(
  "https://ipfs.io/ipfs/bafybeicxzxynapxah5bkwtu2tip4xpod4qc4c3gzeltqu4aeadbbmw3ozi/mixkit-coins-handling-1939.wav"
);
const currentRoundNo = useSelector((state:any) => state.num);
const currentRoundNoRef = useRef(currentRoundNo);
currentRoundNoRef.current = currentRoundNo;

const [roundsData, setRoundsData] = useState(null);
const roundData:any = roundsData?.[currentGroup?.predictionId]?.[currentRoundNo];



  // // // / / / / // / // / /


  // const { minBetAmount } = useRound();
  // const { bettingUp, bettingDown, betDown, betUp } = useBet();
  const dispatch = useDispatch();
  const networkRef = useRef(network);
  networkRef.current = network;
  let pool;
  let position;
  let isEntered = false;
  if (roundData) {
    pool = numberWithCommas(roundData.totalAmount);
    if (roundData.userBet && roundData.userBet?.originalAmount > 0) {
      isEntered = true;
      position = roundData.userBet.position;
    }
  }

  //////////
  const maxBetAmount =
  tokenBalance > 0
      ? (tokenBalance * 0.95)?.toFixed(3)
      :  2;
  const selectAmounts = [
    { value:  0.01 , text: "MIN" },
    { value:  0.25 , text:  25 },
    { value:  0.5, text:  50 },
    { value:  1, text: 100 },
    { value: maxBetAmount, text: "MAX" },
  ];

  ////


  const isLimitedTime = () => {
    var time = Math.max(0, Math.round(timer - Date.now() / 1000 - 10));
    // from 15 sec- 0 sec
    if (time <= 15 && time > 0) {
      return true;
    }
    return false;
  };
  // useEffect(() => {
  //   if (timerTick > 0 && timerTick % 10 == 0) {
  //     reloadData();
  //   }
  // }, [timerTick]);
  // useEffect(() => {
  //   reloadData();
  // }, [network, address, currentRoundNo, bet]);

  useEffect(() => {
    const timerCount = setInterval(() => {
      let time = Math.max(0, Math.round(timer - Date.now() / 1000 - 10));
      // console.log("timeleft", time);
      setTimeLeft(time);
    }, 1000);

    // Cleanup function to clear the interval when the component unmounts or when the dependencies change
    return () => clearInterval(timerCount);
  }, [timer]);

  const reloadData = () => {
    if (currentRoundNo > 0) {
      getMultiRoundData(currentRoundNo, 1).then((roundsData:any) => {
        if (
          roundsData &&
          networkRef.current == network &&
          currentRoundNoRef.current == currentRoundNo
        ) {
          setRoundsData(roundsData);
        } else {
          setRoundsData(null);
        }
      });
    }
  };
  useEffect(() => {
    if (timerTick > 0 && timerTick % 10 == 0) {
      reloadData();
    }
  }, [timerTick]);
  useEffect(() => {
    reloadData();
  }, [network, address, currentRoundNo, bet]);


  const cryptoGroups = [{token:'BTC'},{token:'ETH'}]

  function isValidPositiveNumber(str:any) {
    return /^(?=.*[1-9])\d*(?:\.\d+)?$/.test(str);
  }

  function onConnect() {
    dispatch({
      type: "toggleConnectModal",
      displayConnectModal: true,
    });
  }

  const selectAsset = (asset:any) => {
    console.log("asset",asset)
    dispatch({
      type: "groupChange",
      currentGroup: getGroupsByKey(asset),
    });
  };

  const checkIfValidToBet = (): boolean => {
    if (!amount) {
      setInputError("Error: Enter amount to predict");
      return false;
    }
    if (!isValidPositiveNumber(amount)) {
      setInputError("Error: invalid amount to predict");
      return false;
    }
    if (Number(tokenBalance) <= 0 || Number(amount) > Number(tokenBalance)) {
      setInputError("Error: You donot have enough Balance");
      return false;
    }
    if (Number(amount) < Number(0.01)) {
      setInputError("Error: Amount Lower than MIN Amount");
      return false;
    }
    if (Number(amount) > Number(maxBetAmount)) {
      setInputError("Error: Amount Higher than MAX Amount");
      return false;
    }

    return true;
  };


  useEffect(() => {
    if (address) {
      getUserBettingTokenBalance().then((balance) => {
       
          setTokenBalance(Number(balance.toString()));
    });
    } else {
      setTokenBalance(0);
    }
  }, [address, currentGroup, bet]);

  useEffect(()=>{
    setAmount(Number(selectAmounts[0].value));
  },[])

  function onBetUp() {
    if (audioFlag == true) {
      audio.play().then(() => {
        return;
      });
    }
    setBettingUp(true);
    betBull(num, Number(amount).toString(), partnerCode).then((res:any) => {
      if (res.status == true) {
        setTimeout(function () {
          setBettingUp(false);
          dispatch({ type: "betChange" });
          dispatch({ type: "setRefreshUserHistory" });
        }, 5000);
      } else {
        setBettingUp(false);
      }
    });
  }
  //////////
  function onBetDown() {
    if (audioFlag == true) {
      audio.play().then(() => {
        return;
      });
    }
    setBettingDown(true);
    betBear(num, Number(amount).toString(), partnerCode).then((res:any) => {
      if (res.status == true) {
        setTimeout(function () {
          setBettingDown(false);
          dispatch({ type: "betChange" });
          dispatch({ type: "setRefreshUserHistory" });
        }, 5000);
      } else {
        setBettingDown(false);
      }
    });
  }


    /////////
    const setBettingUp = (s:any) => {
      const tmp = bettingState;
      if (tmp[currentGroup?._id]) {
        tmp[currentGroup?._id].up = s;
      } else {
        tmp[currentGroup?._id] = { up: s };
      }
      setBettingState({ ...tmp });
    };
    const setBettingDown = (s:any) => {
      const tmp = bettingState;
      if (tmp[currentGroup?._id]) {
        tmp[currentGroup?._id].down = s;
      } else {
        tmp[currentGroup?._id] = { down: s };
      }
      setBettingState({ ...tmp });
    };
    const bettingUp = bettingState[currentGroup?._id]?.up;
    const bettingDown = bettingState[currentGroup?._id]?.down;
    /////////


  return (
    <div className={styles.nextRound}>
      <div className={styles.header}>
        <ClockSVG />
        <div className={styles.title}>Next Round</div>
      </div>

      <div className={styles.body}>
        <div className={styles.bg}></div>
        <TooltipComponent />
        <div className={styles.top}>
          <div className={styles.roundData}>
            {paused ? (
              <PausedState />
            ) : (
              <div className={styles.marketData}>
                <div className={styles.label}>Select Market</div>

                {/* Previous buttons */}

                <div className={styles.marketToSelect}>
                  <div
                    onMouseEnter={() => setOpenDropDown(true)}
                    onMouseLeave={() => setOpenDropDown(false)}
                    className={styles.marketBtnWrap}
                  >
                    <div
                      className={classNames(
                        styles.selectBtn,
                        openDropDown && styles.dropdownOpened
                      )}
                    >
                      {currentGroup.token}/USDT
                      <span>
                        <ArrowSVG />
                      </span>
                    </div>
                    {openDropDown && (
                      <div className={styles.dropdown}>
                        <ul>
                          {cryptoGroups.map((data: any, ix: number) => {
                            return (
                              data.token != currentGroup.token && (
                                <li
                                  className={classNames(
                                    styles.dropdownli,
                                    currentGroup.token == data.token
                                      ? styles.activedropdownli
                                      : ""
                                  )}
                                  onClick={() => {
                                    setOpenDropDown(false);
                                    selectAsset(data.token);
                                  }}
                                  key={ix}
                                >
                                  {data.token}
                                  /USDT
                                </li>
                              )
                            );
                          })}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
                <div className={styles.startsIn}>
                  {calculating ? (
                    <>
                      <div className={styles.calculating}>
                        Betting disabled during <br /> round execution
                      </div>
                    </>
                  ) : (
                    <>
                      <div className={styles.label}>Betting Closes In</div>
                      <div
                        className={classNames(
                          styles.startData,
                          isLimitedTime() && styles.limitedTime
                        )}
                      >
                        <TimerValue />
                      </div>
                    </>
                  )}
                </div>
              </div>
            )}

            <div className={styles.predictButtons}>
              <div className={classNames(styles.arrowBtn, styles.predictRise)}>
                <PricePredictionBigArrow
                  direction="up"
                  className={paused ? styles.pausedBtn : styles.won}
                />
                <span>X{roundData?.payoutUp}</span>
              </div>
              <div className={classNames(styles.arrowBtn, styles.predictFall)}>
                <PricePredictionBigArrow
                  direction="down"
                  className={paused ? styles.pausedBtn : styles.lost}
                />
                <span>X{roundData?.payoutDown}</span>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.bottom}>
          {isEntered ? (
            <>
              <div className={classNames(styles.entered, styles.enteredData)}>
                <div
                  className={classNames(
                    styles.enteredBtn,
                    position == 0 ? styles.upBtn : styles.downBtn
                  )}
                >
                  <span>Entered</span>
                  <span>
                    <b>{position == 0 ? "Up" : "Down"}</b>
                  </span>
                </div>

                {/* Image of predicted token */}
                <div>
                  {/* <TokenIcon
                    tokenName={currentGroup?.token}
                    className={styles.tokenImg}
                  /> */}
                  <img src={currentIcon} className={styles.betTokenIcon}/>
                </div>
              </div>
              <div className={styles.entered}>
                <div>Position Size:</div>
                <div>
                  {" "}
                  {roundData?.userBet?.amount} {currentGroup?.bettingToken}
                </div>
              </div>
              <div className={styles.entered}>
                <div>Multiplier</div>
                <div>
                  X{position == 0 ? roundData?.payoutUp : roundData?.payoutDown}
                </div>
              </div>
              <div className={styles.entered}>
                <div>Prize Pool:</div>
                <div>
                  {" "}
                  {pool} {currentGroup?.bettingToken}
                </div>
              </div>
            </>
          ) : (
            <>
              <div className={styles.flexLabels}>
                <div>Commit {currentGroup?.bettingToken}</div>

                <div>Wallet Balance: {tokenBalance?.toFixed(3)}</div>
              </div>

              <div
                className={classNames(
                  styles.input,
                  inputError && styles.inputErrorBox
                )}
              >
                {/* <TokenIcon
                  tokenName={currentGroup?.bettingToken}
                  className={styles.tkn}
                /> */} <img src={binance} width={"20px"}/>

                <input
                  type="text"
                  onWheel={(e: any) => e?.target?.blur()}
                  value={amount ?? ""}
                  onChange={(event: any) => {
                    setInputError("");
                    setAmount(event.currentTarget.value);
                  }}
                  placeholder="Amount to Predict"
                />
              </div>

              {inputError && (
                <div className={styles.inputError}>{inputError}</div>
              )}

              {/* <div className={styles.bets}>
                {selectAmounts.map((d: any, ix: number) => (
                  <div
                    key={ix}
                    onClick={() => {
                      setInputError("");
                      setAmount(d.value);
                    }}
                    className={classNames(
                      styles.betAmountBtn,
                      d.value == amount ? styles.activeBtn : ""
                    )}
                  >
                    {d.text}
                  </div>
                ))}
              </div> */}

              <div className={styles.bets}>
                <div
                  onClick={() => {
                    setInputError("");
                    setAmount(Number(selectAmounts[0].value));
                  }}
                  className={classNames(
                    styles.betAmountBtn,
                    selectAmounts[0].value == amount ? styles.activeBtn : ""
                  )}
                >
                  Min
                </div>
                <div className={styles.inputSlider}>
                  <input
                    type="range"
                    min={selectAmounts[0].value}
                    max={Number(
                      selectAmounts[selectAmounts.length - 1].value >
                        selectAmounts[0].value
                        ? selectAmounts[selectAmounts.length - 1].value
                        : selectAmounts[0].value
                    )}
                    step={ 0.001 }
                    value={amount}
                    className={classNames(
                      styles.slider,
                      selectAmounts[selectAmounts.length - 1].value <=
                        selectAmounts[0].value && styles.disabledSlider
                    )}
                    onChange={(e) => {
                      setInputError("");
                      setAmount(Number(e.target.value));
                    }}
                    id="myRange"
                    disabled={
                      selectAmounts[selectAmounts.length - 1].value <=
                      selectAmounts[0].value
                    }
                  />
                  <div className={styles.sliderTrack}></div>
                </div>
                <div
                  onClick={() => {
                    setInputError("");
                    setAmount(
                      Number(selectAmounts[selectAmounts.length - 1].value)
                    );
                  }}
                  className={classNames(
                    styles.betAmountBtn,
                    selectAmounts[selectAmounts.length - 1].value == amount
                      ? styles.activeBtn
                      : ""
                  )}
                >
                  Max
                </div>
              </div>

              <div
                className={classNames(styles.flexLabels, styles.prizelabels)}
              >
                <div>Prize Pool</div>
                <div>
                  {pool} {currentGroup?.bettingToken}
                </div>
              </div>

              {calculating || paused || timeLeft <= 0 ? (
                <DisabledButtons />
              ) : (
                <div className={styles.betButtons}>
                  <button
                    className={classNames(
                      styles.betBtn,
                      styles.upBtn,
                      (bettingDown || bettingUp) && styles.disabled
                    )}
                    onClick={() => {
                      // console.log("click");
                      if (!(address && isConnected)) {
                        onConnect();
                      } else if (checkIfValidToBet())
                        onBetUp();
                    }}
                    disabled={bettingUp || bettingDown}
                  >
                    <span>UP</span>
                    {bettingUp ? (
                     <Loading iconSize={10} loaderSize={35}/>
                     
                    ) : (
                      <span>X{roundData?.payoutUp}</span>
                    )}
                  </button>
                  <button
                    className={classNames(
                      styles.betBtn,
                      styles.downBtn,
                      (bettingUp || bettingDown) && styles.disabled
                    )}
                    onClick={() => {
                      if (!(address && isConnected)) {
                        onConnect();
                      } else if (checkIfValidToBet())
                        onBetDown();
                    }}
                    disabled={bettingDown || bettingUp}
                  >
                    <span>DOWN</span>
                    {bettingDown ? (
                       <Loading iconSize={10} loaderSize={35}/>
                    ) : (
                      <span>X{roundData?.payoutDown}</span>
                    )}
                  </button>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

const TooltipComponent = () => {
  return (
    <div className={styles.tooltipWrap}>
      <div className={classNames(styles.tooltipBtn)}>
        <InfoSVG />
        <div className={styles.tooltipData}>
          <b>Dynamic Multipliers:</b> <br />
          {`Multipliers are dynamic: they are determined by the amount in the UP vs DOWN Prize Pool`}{" "}
          <br />
          <b>Entry Fee: 5%</b>
        </div>
      </div>
    </div>
  );
};

const ArrowSVG = () => {
  return (
    <svg
      width="10"
      height="6"
      viewBox="0 0 10 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.8"
        d="M4.86374e-05 0.995538C-0.000314713 1.12361 0.0232344 1.25016 0.068965 1.36588C0.114696 1.48159 0.181445 1.58354 0.264309 1.66423L4.54962 5.80498C4.67742 5.93107 4.83772 6 5.00315 6C5.16858 6 5.32888 5.93107 5.45668 5.80498L9.74199 1.51849C9.88784 1.37297 9.97957 1.16387 9.99698 0.937176C10.0144 0.710485 9.95607 0.484774 9.83484 0.3097C9.71361 0.134625 9.5394 0.0245285 9.35054 0.00362778C9.16168 -0.0172729 8.97364 0.0527349 8.82779 0.198251L4.99958 4.03037L1.17137 0.326846C1.06653 0.222013 0.938869 0.155421 0.803491 0.134949C0.668111 0.114477 0.530679 0.140982 0.407455 0.211329C0.284233 0.281675 0.180379 0.392918 0.108178 0.531894C0.0359774 0.670871 -0.00154591 0.831765 4.86374e-05 0.995538Z"
        fill="currentColor"
      />
    </svg>
  );
};

const DisabledButtons = () => {
  return (
    <div className={styles.betButtons}>
      <button className={classNames(styles.betBtn, styles.disabledBtn)}>
        UP
      </button>
      <button className={classNames(styles.betBtn, styles.disabledBtn)}>
        Down
      </button>
    </div>
  );
};

const PricePredictionBigArrow = ({
  className,
  direction,
}: {
  className: string;
  direction: "up" | "down";
}) => {
  return (
    <div
      className={classNames(
        styles.bigtriangle,
        className,
        direction === "up" ? styles.upTriangle : styles.downTriangle
      )}
    >
      <TriangleSVG />
    </div>
  );
};

const TimerValue = () => {
  const timerTick = useSelector((state:any) => state.timerTick);
  const timer = useSelector((state:any) => state.roundLockTimestamp);
  var time = Math.max(0, Math.round(timer - Date.now() / 1000 - 10));

  const h = Math.floor(time / 3600);
  time = time % 3600;
  const m = Math.floor(time / 60);
  const s = time % 60;

  var timerStr =
    h > 0
      ? h.toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        }) + ":"
      : "";
  timerStr +=
    m.toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    }) + ":";

  timerStr += s.toLocaleString("en-US", {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });
  let _temp = timerStr.split(":");
  if (_temp.length < 2) {
    return <></>;
  }
  let toReturnStr = `${_temp[0]} min ${_temp[1]} sec`;
  return <>{toReturnStr}</>;
};

export default NextRound;
