import { useDispatch, useSelector } from "react-redux";
import styles from "./SelectToken.module.css";
import { getGroupsByKey } from "../../../core/utils/networkUtils";
import OkPng from "../../../assets/dashboard/ok.png"
const SelectToken = () => {


 
  const currentGroup = useSelector((state:any) => state.currentGroup);

  const dispatch = useDispatch();
  const selectAsset = (asset:string) => {
    dispatch({
      type: "groupChange",
      currentGroup: getGroupsByKey(asset),
    });
  };

  const cryptoGroups = [{token:'ETH'},{token:"BTC"}]

  return (
    <section className={styles.selectToken}>
      {cryptoGroups.map((data: any, ix: number) => (
        <div
          onClick={() => selectAsset(data.token)}
          key={ix}
          className={styles.btn}
        >
          {currentGroup?.token == data.token && (
            <img src={OkPng} className={styles.img} />
          )}
          <div>{data.token}/USDT</div>
        </div>
      ))}
    </section>
  );
};
export default SelectToken;
