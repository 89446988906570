//0 bsc main
//1 polygon main
//+10 for testnets

const contracts = {
  multidata: {
    0: '0x26c0149B330BEbBFAE4Ba80652c6053D203831A8',
    10: '0x72F5d79121405F96D6656c56056f56dF825b60ab',
    1: '0xcecad53DA330E26117984057aecfdF793c019eFe',
    11: '',
    2: 'TAwah62qTG4hLEQP59peSRGSSx5FTfZmBk',
    12: 'TKEiQ3EPkeEJK5wUECFuArbBfMPxxPzRHc'
  },
  factory: {
    decentralized: {
      0: '0x6495D54266EA0017987745757d723124fb19Fb97',
      10: '0xf18586778b91a3f5C213d7d5c85fC9A1622D606C',
      1: '0xa389795f945c6aae4Ef4f2f44db0C5845792B45B',
      11: '0xF9459E9960e1f2424e2cc6482086Ff8932E0A29C'
    },
    centralized: {
      0: '0x3fc376530Ac35d37Dd1Fa794F922e0f30CbB2c46',
      10: '0x7C504FfC2ba5727D4EC437a1F501177933Ea44BE',
      1: '0x24992f91cFaAae97CF0B09057a4c40dC644b0368',
      11: '0x5FF9569354543D39F93C5428582a0dD406709cfe'
    }
  },
  reference: {
    0: '0x4Af6850b0d6dc2Cc6118D959b3b0B9a4553c486b',
    10: '0xb2bA348d4D138B1071c9aa4A2176F01d1ca9F37D',
    1: '0x062EB9830D1f1f0C64ac598eC7921f0cbD6d4841',
    11: '0x9419caB40d6378aeF7bC5Ae5A6E39Ea30ca951cf'
  },
  leadership: {
    0: '0x3405681fe0aA0f8d2Bd6113F852C506e096a5D8C',
    10: '0x33aACDA2d76B9EAAe6498d25bcA45325B497e49F',
    1: '',
    11: ''
  },
  multicall: {
    0: '0x41263cba59eb80dc200f3e2544eda4ed6a90e76c',
    10: '0xae11C5B5f29A6a25e955F0CB8ddCc416f522AF5C',
    1: '0x11ce4B23bD875D7F5C6a31084f55fDe1e9A87507',
    11: '0x08411ADd0b5AA8ee47563b146743C13b3556c9Cc'
  },
  router: {
    0: '0x10ED43C718714eb63d5aA57B78B54704E256024E',
    10: '0xD99D1c33F9fC3444f8101754aBC46c52416550D1',
    1: '0xa5E0829CaCEd8fFDD4De3c43696c57F7D7A678ff',
    11: '0xa5E0829CaCEd8fFDD4De3c43696c57F7D7A678ff',
    2: 'TXk8rQSAvPvBBNtqSoY6nCfsXWCSSpTVQF'
  },
  auction: {
    0: '0xfA5f8DCB753c56Af9764BC5cBdf7467e4a13d93d',
    10: '0xfA5f8DCB753c56Af9764BC5cBdf7467e4a13d93d',
    1: '',
    11: ''
  },
  soloPrediction: {
    0: '0x599974D3f2948b50545Fb5aa77C9e0bddc230ADE',
    10: '0xf51D8672e5BfB5F0ce6687DA18988DE73370D3B8',
    1: '0x764C3Ea13e7457261E5C1AaD597F281f3e738240',
    11: '0xb812ABd8ef54A4dAF4BcB20257d9F2c4c560095f'
  },
  raffle: {
    0: '',
    10: '0xf243c2EFbB18a007921E151fd1212415D01464b3',
    1: '0xb35a6e1A32D333EEaa7176C365138b65408E30c5',
    11: '0x6704Cb68Ee412A434a4166A2d60439Da63cEc5e5'
  },
  centralized: {
    0: '0x22dB94d719659d7861612E0f43EE28C9FF9909C7',
    1: '0x8251E5EBc2d2C20f6a116144800D569FAF75d746',
  },
}

export default contracts
