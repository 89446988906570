import React from 'react'
import { useSelector } from 'react-redux';
import useWindowDimensions from '../../utils/useWindowDimensions';
import useRounds from '../../core/hooks/useRounds';
import styles from "./newPrediction.module.css";
import LiveRound from './LiveRound/LiveRound';
import { isEmpty } from '../../core/utils/libs';
import NextRound from './NextRound/NextRound';
import classnames from "classnames";
import PastRounds from './PastRounds/tabletmode/PastRounds';
import DashboardSm from './small/DashboardSm';
import Loading from '../Loading/Loading';
// import NextRound from './NextRound/NextRound';
type Props = {}

function NewPrediction({}: Props) {
  
  const network = useSelector((state:any) => state.network);
  const { height, width } = useWindowDimensions();
  
  const {slideTypes,slides} = useRounds();


  const getLiveRoundData = ()=>{
   let toReturn =  slides.filter((_slide:any)=> _slide.type===slideTypes.live);
   
   if(!isEmpty(toReturn)){
    return toReturn[0];
   }
   return {}
  }
  const getNextRoundData = ()=>{
   let toReturn =  slides.filter((_slide:any)=> _slide.type===slideTypes.next);
   if(!isEmpty(toReturn)){
    return toReturn[0];
   }
   return {}
  }
  const getPastRounds = ()=>{
    let toReturn =  slides.filter((_slide:any)=> _slide.type===slideTypes.expire);

    if(!isEmpty(toReturn)){
    return toReturn;
   }
   return []
  }

  return (
    <div className={styles.dashboardWrapper}>
      {
         slides && slides.length>0  ?
       <>
        {width>600?
        <><div className={styles.dashboard}>
             <LiveRound data={getLiveRoundData()} />
             <NextRound data={getNextRoundData()} />
             
        </div> 
        <PastRounds data={getPastRounds()}/></>: 
        <div>
            <DashboardSm pastRoundData={getPastRounds()} liveData= {getLiveRoundData()} nextroundData={getNextRoundData()}/>
        </div>
        }
       </> :
       <div className={styles.loaderWrapper}>
        <div className={styles.loader}>

        <Loading iconSize={35} loaderSize={100}/>
        </div>
        </div>
      }

    </div>
  )
}

export default NewPrediction