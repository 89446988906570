import styles from "./PausedRound.module.css";
import pausedImg from "../../../assets/paused.png";

const PausedRound = () => {
  return (
    <section className={styles.pausedRound}>
      <div className={styles.top}>
        {/*<div className={classNames(styles.lf, "center")}>
          <LeftIcon className={styles.lfi} />
  </div>*/}
        
        <div>Market Paused</div>
      </div>
      <div className={styles.content}>
        <div>Please come back later!</div>
        <div className={styles.rw}>
          <div>Prediction Market is Paused</div>
          {/* <img src={pausedImg} className={styles.img} /> */}
        </div>
      </div>
    </section>
  );
};

export default PausedRound;
