import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import usePrice from "./usePrice";
import configSolo from "../utils/constants/configSolo";
import decimals from "../utils/constants/decimals";
import useExecuteContract from "./useExecuteContract";
import messages from "../utils/constants/messages";
import BigNumber from "bignumber.js";
import allContracts from "../utils/constants/contracts";
import erc20ABI from "../utils/constants/ABI/erc20.json";
import centralizedABI from "../utils/constants/ABI/centralized";
import { createMessage, updateMessage, messageStatus } from "../utils/message";
import { useAccount } from "wagmi";
import axios from "axios";

const api = process.env.REACT_APP_API_URI || "https://api.prdt.finance";
const addressZero = "0x0000000000000000000000000000000000000000";
const networkNames = {
  0: "BSC",
  1: "POLYGON",
  10: "BSC_TESTNET",
  11: "POLYGON_TESTNET",
};

const timeoutMS = 8000;

const useCentralized = () => {
  const { callContractWagmi, sendContractWagmi } = useExecuteContract();
  const { getPriceBINANCE } = usePrice();
  const { address: userAddress } = useAccount();
  const [timeSync, setTimeSync] = useState(null);
  const configs = useSelector((state) => state.configs);
  const currentNetwork = useSelector((state) => state.network);

  const getIntervalIndex = (roundSettings, interval) => {
    const intervalIndeces = Object.keys(roundSettings).map((r) => parseInt(r));

    if (interval >= intervalIndeces[intervalIndeces.length - 1])
      return intervalIndeces[intervalIndeces.length - 1];

    for (var i = 0; i < intervalIndeces.length; i++) {
      if (interval >= intervalIndeces[i] && interval < intervalIndeces[i + 1]) {
        return intervalIndeces[i];
      }
    }
  };

  const getBetSettings = (target, interval) => {
    if (!configs || !configs.roundSettings) return;
    const index = getIntervalIndex(configs.roundSettings, interval);
    if (!index) return;
    const roundData = configs.roundSettings[index];
    return {
      payout: roundData.payout[target] || roundData.payout.default,
      minBet: roundData.minBet[target] || roundData.minBet.default,
      maxBet: roundData.maxBet[target] || roundData.maxBet.default,
    };
  };

  const getTargetPrices = async () => {
    if (!configs || !configs.allowedTargets) return;
    const targetTokens = configs.allowedTargets;
    const calls = [];
    for (let i = 0; i < targetTokens.length; i++) {
      calls.push(getPriceBINANCE(targetTokens[i].toUpperCase() + "USDT"));
    }
    const prices = await Promise.all(calls);
    let priceObj = {};
    for (let i = 0; i < targetTokens.length; i++) {
      const tokenName = targetTokens[i].toUpperCase();
      priceObj = { ...priceObj, [tokenName]: prices[i].price };
    }
    return priceObj;
  };

  const getTargetPrice = async (tokenName) => {
    const priceData = await getPriceBINANCE(tokenName.toUpperCase() + "USDT");
    return priceData.price;
  };

  const getServerTimeDifference = async () => {
    if (timeSync) return timeSync;
    const result = await axios.get(`${api}/api/v1/time`, {
      withCredentials: true,
    });
    if (!result) return 0;
    const diff = Date.now() - result.data;
    setTimeSync(diff);
    return diff;
  };

  const getUserBets = async (numBet, offset = 0) => {
    const result = await axios.get(
      `${api}/api/v1/prediction/bets/${networkNames[currentNetwork]}`,
      { withCredentials: true } // set cookie from Express server
    );
    if (!result.data) {
      return { ongoingRounds: [], completedRounds: [] };
    }

    const userRounds = result.data;

    const ongoingRounds = [];
    const completedRounds = [];

    const timeDiff = await getServerTimeDifference();

    for (let i = 0; i < userRounds.length; i++) {
      const bettingTokenName = userRounds[i].bettingToken.toUpperCase();
      const targetToken = userRounds[i].targetToken.toUpperCase();

      if (!userRounds[i].calculated) {
        const roundData = {
          ...userRounds[i],
          timer: 0,
          cancelled: false,
          targetToken,
          bettingTokenName,
        };

        //NOT CANCELLED
        const _timer = Math.ceil(
          (new Date(userRounds[i].closeTimestamp) - Date.now() + timeDiff) /
            1000
        );

        roundData.timer = _timer;
        roundData.serverTimeDiff = timeDiff;

        ongoingRounds.push(roundData);
      } else {
        let roundResult = 0;
        if (userRounds[i].startPrice === userRounds[i].closePrice)
          roundResult = 2;
        else if (
          parseFloat(userRounds[i].startPrice) >
          parseFloat(userRounds[i].closePrice)
        ) {
          roundResult = 1;
        }

        const result = roundResult === userRounds[i].position;

        const roundData = {
          ...userRounds[i],
          roundResult: roundResult,
          result: result,
          cancelled: false,
          targetToken,
          bettingTokenName,
        };

        completedRounds.push(roundData);
      }
    }

    return { ongoingRounds, completedRounds };
  };

  const isValidWalletAddress = (addr) => {
    if (!addr) return false;
    return addr.length == 42 && addr.substring(0, 2) == "0x";
  };

  const checkAndLoadPartnerCode = (_partnerCode) => {
    if (_partnerCode) {
      const partnerName = _partnerCode.toLowerCase();
      localStorage.setItem("partner_" + userAddress, partnerName);
      return partnerName;
    }
    const partnerName = localStorage.getItem("partner_" + userAddress);
    return partnerName;
  };

  const betBear = async ({
    targetTokenName,
    bettingTokenAddress,
    interval,
    amount,
    source = null,
    partnerName = null,
  }) => {
    const result = await _bet({
      targetTokenName,
      bettingTokenAddress: isValidWalletAddress(bettingTokenAddress)
        ? bettingTokenAddress
        : addressZero,
      interval,
      amount,
      position: 1,
      partnerName: checkAndLoadPartnerCode(partnerName),
      source,
    });
    return result;
  };

  const betBull = async ({
    targetTokenName,
    bettingTokenAddress,
    interval,
    amount,
    source = null,
    partnerName = null,
  }) => {
    const result = await _bet({
      targetTokenName,
      bettingTokenAddress: isValidWalletAddress(bettingTokenAddress)
        ? bettingTokenAddress
        : addressZero,
      interval,
      amount,
      position: 0,
      partnerName: checkAndLoadPartnerCode(partnerName),
      source,
    });
    return result;
  };

  const bet = async ({
    targetTokenName,
    bettingTokenAddress,
    interval,
    amount,
    position,
    source = null,
    partnerName = null,
  }) => {
    const result = await _bet({
      targetTokenName,
      bettingTokenAddress: isValidWalletAddress(bettingTokenAddress)
        ? bettingTokenAddress
        : addressZero,
      interval,
      amount,
      position,
      partnerName: checkAndLoadPartnerCode(partnerName),
      source,
    });
    return result;
  };

  const _bet = async ({
    targetTokenName,
    bettingTokenAddress,
    interval,
    amount,
    position,
    partnerName,
    source,
  }) => {
    const tokenName = getBettingTokenName(bettingTokenAddress);

    const positionType = position === 0 ? "Long" : "Short";

    const messageId = createMessage("bet");

    const messageBeginning = `${targetTokenName.toUpperCase()}-USDT ${positionType}`;

    updateMessage(
      messageId,
      messageStatus.pending,
      messageBeginning + " Opening",
      null
    );

    try {
      const result = await axios.post(
        `${api}/api/v1/prediction/bets`,
        {
          network: networkNames[currentNetwork],
          token: tokenName,
          target: targetTokenName,
          interval,
          position,
          amount,
          partnerName,
          source,
          host: window.document.referrer || window.location.hostname,
        },
        { withCredentials: true } // set cookie from Express server
      );
    } catch (err) {
      console.log(err);

      const errorMessage = err.response
        ? err.response.data.message
        : "Please try again";
      updateMessage(messageId, messageStatus.error, errorMessage, null);
      return { status: false, message: errorMessage };
    }

    updateMessage(
      messageId,
      messageStatus.succesfull,
      messageBeginning + " Opened",
      null
    );
    return { status: true, message: "bet success" };
  };

  const autoPlay = async ({
    bettingTokenName,
    targetTokenName,
    interval,
    position,
    amount,
    maxAmount = 0,
    stopLoss = 0,
    stopProfit = 0,
    resetOnLoss = false,
    resetOnWin = false,
    increaseOnLoss = 0,
    increaseOnWin = 0,
    switchPositionOnLoss = false,
    switchPositionOnWin = false,
    numBets = 0,
    partnerName = null,
  }) => {
    const _partnerName = checkAndLoadPartnerCode(partnerName);

    const messageId = createMessage("autoplay");

    const messageBeginning = `${targetTokenName.toUpperCase()}-USDT Trading Bot `;

    updateMessage(
      messageId,
      messageStatus.pending,
      messageBeginning + " Starting",
      null
    );

    try {
      await axios.post(
        `${api}/api/v1/prediction/autoPlay`,
        {
          network: networkNames[currentNetwork],
          token: bettingTokenName,
          target: targetTokenName,
          interval,
          position,
          amount,
          partnerName: _partnerName,
          maxAmount,
          stopLoss,
          stopProfit,
          resetOnLoss,
          resetOnWin,
          increaseOnLoss,
          increaseOnWin,
          switchPositionOnLoss,
          switchPositionOnWin,
          numBets,
        },
        { withCredentials: true } // set cookie from Express server
      );
    } catch (err) {
      console.log(err);

      const errorMessage = err.response
        ? err.response.data.message
        : "Please try again";
      updateMessage(messageId, messageStatus.error, errorMessage, null);
      return { status: false, message: errorMessage };
    }

    updateMessage(
      messageId,
      messageStatus.succesfull,
      messageBeginning + " Started",
      null
    );
    return { status: true, message: "success" };
  };

  const cancelAutoPlay = async () => {
    const messageId = createMessage("cancel trading bot");

    try {
      await axios.delete(
        `${api}/api/v1/prediction/autoPlay/cancel`,
        { withCredentials: true } // set cookie from Express server
      );
    } catch (err) {
      console.log(err);

      const errorMessage = err.response
        ? err.response.data.message
        : "Please try again";
      updateMessage(messageId, messageStatus.error, errorMessage, null);
      return { status: false, message: errorMessage };
    }

    updateMessage(
      messageId,
      messageStatus.succesfull,
      "trading bot canceled",
      null
    );
    return { status: true, message: "trading bot canceled" };
  };

  const getOngoingAutoPlay = async () => {
    try {
      const result = await axios.get(
        `${api}/api/v1/prediction/autoPlay/ongoing`,
        { withCredentials: true }
      );

      return result.data;
    } catch (err) {
      return null;
    }
  };

  const withdraw = async (bettingTokenAddress, amount) => {
    if (!bettingTokenAddress) return;
    const tokenName = getBettingTokenName(bettingTokenAddress);
    if (!tokenName) return;
    const messageId = createMessage("withdraw");

    try {
      const result = await axios.post(
        `${api}/api/v1/prediction/withdraws`,
        {
          network: networkNames[currentNetwork],
          token: tokenName,
          amount,
        },
        { withCredentials: true } // set cookie from Express server
      );
    } catch (err) {
      console.log(err);

      const errorMessage = err.response
        ? err.response.data.message
        : "Please try again";
      updateMessage(messageId, messageStatus.error, errorMessage, null);
      return { status: false, message: errorMessage };
    }

    updateMessage(
      messageId,
      messageStatus.succesfull,
      "Withdrawal request submitted.",
      null,
      "Withdraw request"
    );
    return { status: true, message: "Withdrawal request submitted." };
  };

  const depositToken = async (_bettingTokenAddress, amount) => {
    const bettingToken = isValidWalletAddress(_bettingTokenAddress)
      ? _bettingTokenAddress
      : addressZero;

    const tokenName = getBettingTokenName(_bettingTokenAddress);
    const tokenDecimals = decimals[currentNetwork][tokenName] || 18;
    const _amountWei = new BigNumber(amount.toString()).multipliedBy(
      10 ** tokenDecimals
    );
    const amountWei = Number(_amountWei.toString()).toLocaleString("fullwide", {
      useGrouping: false,
    });

    const predictionAddress = allContracts.centralized[currentNetwork];
    let result;
    if (bettingToken === addressZero) {
      result = await sendContractWagmi({
        address: predictionAddress,
        abi: centralizedABI,
        functionName: "addBalance",
        network: currentNetwork,
        getGasPrice: true,
        value: amountWei,
      });
    } else {
      result = await sendContractWagmi({
        address: predictionAddress,
        abi: centralizedABI,
        functionName: "addTokenBalance",
        network: currentNetwork,
        getGasPrice: true,
        args: [bettingToken, amountWei],
      });
    }

    return result;
  };

  const approveBettingToken = async (_bettingTokenAddress) => {
    const bettingToken = isValidWalletAddress(_bettingTokenAddress)
      ? _bettingTokenAddress
      : addressZero;

    const predictionAddress = allContracts.centralized[currentNetwork];

    const result = await sendContractWagmi({
      address: bettingToken,
      abi: erc20ABI,
      functionName: "approve",
      network: currentNetwork,
      getGasPrice: true,
      args: [
        predictionAddress,
        "115792089237316195423570985008687907853269984665640564039457584007913129639935",
      ],
    });

    return result;
  };

  const isNativeToken = (_bettingTokenAddress) => {
    return _bettingTokenAddress === addressZero;
  };

  const getTokenAllowance = async (_bettingTokenAddress) => {
    const bettingToken = isValidWalletAddress(_bettingTokenAddress)
      ? _bettingTokenAddress
      : addressZero;

    if (bettingToken === addressZero) return "10000000000000000000000000000000";
    if (!userAddress) return 0;

    const predictionAddress = allContracts.centralized[currentNetwork];

    const [err, result] = await callContractWagmi({
      address: bettingToken,
      abi: erc20ABI,
      functionName: "allowance",
      args: [userAddress, predictionAddress],
    });

    return result.toString();
  };

  const subAllowance = (bettingTokenAddress, amount, allowance) => {
    const tokenName = getBettingTokenName(bettingTokenAddress);
    const tokenDecimals = decimals[currentNetwork][tokenName] || 18;
    const amountWei = new BigNumber(amount.toString()).multipliedBy(
      10 ** tokenDecimals
    );
    const newAllowence = new BigNumber(allowance.toString()).minus(amountWei);
    return newAllowence.toString();
  };

  const checkIfEnoughAllowance = (_bettingTokenAddress, amount, allowance) => {
    const tokenName = getBettingTokenName(_bettingTokenAddress);
    const tokenDecimals = decimals[currentNetwork][tokenName] || 18;
    const amountWei = new BigNumber(amount.toString()).multipliedBy(
      10 ** tokenDecimals
    );
    return BigNumber(allowance.toString()).isGreaterThanOrEqualTo(amountWei);
  };

  const isTokenApproved = async (_bettingTokenAddress) => {
    const bettingToken = isValidWalletAddress(_bettingTokenAddress)
      ? _bettingTokenAddress
      : addressZero;

    if (bettingToken === addressZero) return true;
    if (!userAddress) return false;

    const predictionAddress = allContracts.centralized[currentNetwork];

    const [err, result] = await callContractWagmi({
      address: bettingToken,
      abi: erc20ABI,
      functionName: "allowance",
      args: [userAddress, predictionAddress],
    });

    return BigNumber(result.toString()).isGreaterThan(
      BigNumber("1000000000000000000000000")
    );
  };

  const getBettingTokens = () => {
    if (currentNetwork == -1) return [];
    return Object.keys(configSolo.bettingTokens[currentNetwork]);
  };

  const getBettingTokenName = (tokenAddress) => {
    if (currentNetwork == -1) return null;
    const obj = configSolo.bettingTokens[currentNetwork];
    return Object.keys(obj).find((key) => obj[key] === tokenAddress);
  };

  const getBettingTokenAddress = (_tokenName) => {
    if (currentNetwork == -1) return addressZero;
    return configSolo.bettingTokens[currentNetwork][_tokenName];
  };

  const getUserBalances = async () => {
    const result = await axios.get(
      `${api}/api/v1/prediction/balances/`,
      {
        withCredentials: true,
        headers: {
          "Access-Control-Allow-Origin": "*",
          "content-type": "application/json",
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
      } // set cookie from Express server
    );
    return result.data;
  };

  const getUserBalance = async (_bettingTokenAddress) => {
    const tokenName = getBettingTokenName(_bettingTokenAddress);
    const result = await axios.get(
      `${api}/api/v1/prediction/balances/${networkNames[currentNetwork]}/${tokenName}`,
      { withCredentials: true } // set cookie from Express server
    );

    const balance = result.data.amount || "0";
    return balance;
  };

  const getSettings = async () => {
    let result;
    try {
      result = await axios.get(`${api}/api/v1/settings`);
      let config = result.data;
      return config;
    } catch (err) {
      console.log(err)
      return null;
    }
  };

  const isPaused = async () => {
    let result;
    try {
      result = await axios.get(`${api}/api/v1/paused`);
      return result.data.paused;
    } catch (err) {
      return false;
    }
  };

  const isAuthenticated = async () => {
    if (!userAddress) return false;
    let result;
    try {
      result = await axios.get(`${api}/auth/authenticate`, {
        withCredentials: true,
      });
      return (
        result.status === 200 &&
        result.data.address.toLowerCase() === userAddress.toLowerCase()
      );
    } catch (err) {
      return false;
    }
  };

  const logout = async () => {
    const result = await axios.get(`${api}/auth/logout`, {
      withCredentials: true,
    });
    return result;
  };

  const withdrawList = async (network, token) => {
    try {
      const result = await axios.get(
        `${api}/api/v1/prediction/withdraws`,

        {
          params: {
            network: network,
            token: token,
          },
          withCredentials: true,
        }
      );
      return result.data;
    } catch (err) {
      console.log(err);
      return null;
    }
  };
  const depositList = async (network, token) => {
    try {
      const result = await axios.get(
        `${api}/api/v1/prediction/deposits`,

        {
          params: {
            network: network,
            token: token,
          },
          withCredentials: true,
        }
      );
      return result.data;
    } catch (err) {
      console.log(err);
      return null;
    }
  };

  const getAlltimeReferenceData = async () => {
    try {
      const result = await axios.get(`${api}/api/v1/prediction/users/bonus`, {
        withCredentials: true,
      });
      if (!result.data) return null;

      const data = result.data.balances.reduce(
        (acc, cur) => {
          if (!acc.graphTotalVolume[cur.network])
            acc.graphTotalVolume[cur.network] = 0;
          acc.graphTotalVolume[cur.network] +=
            cur.totalReferenceBonusUSD +
            cur.totalReferrerBonusUSD1 +
            cur.totalReferrerBonusUSD2 +
            cur.totalReferrerBonusUSD3;

          acc.graphVolumePerLevel.level1 +=
            cur.totalReferenceBonusUSD + cur.totalReferrerBonusUSD1;
          acc.graphVolumePerLevel.level2 += cur.totalReferrerBonusUSD2;
          acc.graphVolumePerLevel.level3 += cur.totalReferrerBonusUSD3;

          return acc;
        },
        {
          graphTotalVolume: {},
          graphVolumePerLevel: { level1: 0, level2: 0, level3: 0 },
        }
      );

      data.graphLevel = {
        level1: result.data.referenceGroups.level1 || 0,
        level2: result.data.referenceGroups.level2 || 0,
        level3: result.data.referenceGroups.level3 || 0,
      };

      return data;
    } catch (err) {
      console.log(err);
      return null;
    }
  };

  const getReferenceHistory = async ({ groupDays, numGroup, allTime }) => {
    const referenceHistory = await getAlltimeReferenceData();

    try {
      const result = await axios.get(
        `${api}/api/v1/prediction/references/history`,
        {
          params: {
            sender: userAddress,
            groupDays,
            numGroup,
          },
          withCredentials: true,
        }
      );
      if (!result.data) return null;

      if (!allTime) {
        referenceHistory.graphTotalVolume = {};
        referenceHistory.graphVolumePerLevel = {
          level1: 0,
          level2: 0,
          level3: 0,
        };
      }

      const data = result.data.reduce(
        (acc, cur) => {
          const total =
            cur.level1 + cur.level2 + cur.level3 + cur.referenceBonus;

          if (!allTime) {
            const network = cur._id.network;
            if (!referenceHistory.graphTotalVolume[network])
              referenceHistory.graphTotalVolume[network] = 0;

            referenceHistory.graphTotalVolume[network] += total;

            referenceHistory.graphVolumePerLevel.level1 +=
              cur.level1 + cur.referenceBonus;
            referenceHistory.graphVolumePerLevel.level2 += cur.level2;
            referenceHistory.graphVolumePerLevel.level3 += cur.level3;
          }

          const dateGroup = cur._id.dateGroup;
          const dateIndex = numGroup - dateGroup - 1;

          if (!acc.timeline[dateIndex]) acc.timeline[dateIndex] = 0;
          acc.timeline[dateIndex] += total;

          return acc;
        },
        { timeline: Array(numGroup).fill(0) }
      );

      for (let i = 1; i < numGroup; i++) {
        data.timeline[i] += data.timeline[i - 1];
      }

      referenceHistory.graphTimeline = data.timeline;

      return referenceHistory;
    } catch (err) {
      console.log(err);
      return null;
    }
  };

  const addReference = async (referrerAddress) => {
    const messageId = createMessage("add reference");

    try {
      await axios.post(
        `${api}/api/v1/prediction/users/reference/add`,
        {
          referrerAddress,
        },
        { withCredentials: true } // set cookie from Express server
      );
    } catch (err) {
      console.log(err);
      const errorMessage = err.response
        ? err.response.data.message
        : "Please try again";
      updateMessage(messageId, messageStatus.error, errorMessage, null);
      return { status: false, message: errorMessage };
    }

    updateMessage(messageId, messageStatus.succesfull, "reference added", null);
    return { status: true, message: "reference added" };
  };

  const cancelWithdrawRequest = async (_id) => {
    const messageId = createMessage("cancel withdraw");

    try {
      const result = await axios.post(
        `${api}/api/v1/prediction/withdraws/cancel`,
        {
          withdrawId: _id,
        },
        { withCredentials: true } // set cookie from Express server
      );
    } catch (err) {
      console.log(err);

      const errorMessage = err.response
        ? err.response.data.message
        : "Please try again";
      updateMessage(messageId, messageStatus.error, errorMessage, null);
      return { status: false, message: errorMessage };
    }

    updateMessage(
      messageId,
      messageStatus.succesfull,
      "withdraw request canceled",
      null
    );
    return { status: true, message: "withdraw request canceled" };
  };

  const canAddReference = async () => {
    try {
      const result = await axios.get(
        `${api}/api/v1/prediction/users/canAddReference`,
        {
          withCredentials: true,
        }
      );
      return result.data;
    } catch (err) {
      console.log(err);
      return null;
    }
  };

  const getUserTickets = async () => {
    try {
      const result = await axios.get(`${api}/api/v1/prediction/users/tickets`, {
        withCredentials: true,
      });
      return result.data;
    } catch (err) {
      console.log(err);
      return null;
    }
  };

  const getTotalTickets = async () => {
    try {
      const result = await axios.get(
        `${api}/api/v1/prediction/rewards/tickets`,
        {
          withCredentials: true,
        }
      );
      return result.data;
    } catch (err) {
      console.log(err);
      return null;
    }
  };

  const getRewards = async () => {
    try {
      const result = await axios.get(`${api}/api/v1/prediction/rewards`, {
        withCredentials: true,
      });
      return result.data;
    } catch (err) {
      console.log(err);
      return null;
    }
  };

  const getLastJackpot = async () => {
    try {
      const result = await axios.get(
        `${api}/api/v1/prediction/rewards/jackpot`,
        {
          withCredentials: true,
        }
      );
      return result.data;
    } catch (err) {
      console.log(err);
      return null;
    }
  };

  return {
    getUserBets,
    bet,
    betBull,
    betBear,
    withdraw,
    isTokenApproved,
    approveBettingToken,
    getBettingTokens,
    getBettingTokenAddress,
    getBettingTokenName,
    getUserBalance,
    getUserBalances,
    getTargetPrices,
    getTargetPrice,
    isPaused,
    getSettings,
    depositToken,
    isAuthenticated,
    logout,
    depositList,
    withdrawList,
    getReferenceHistory,
    addReference,
    canAddReference,
    cancelWithdrawRequest,
    autoPlay,
    cancelAutoPlay,
    getOngoingAutoPlay,
    isNativeToken,
    getTokenAllowance,
    checkIfEnoughAllowance,
    subAllowance,
    getBetSettings,
    getRewards,
    getUserTickets,
    getTotalTickets,
    getLastJackpot,
  };
};

export default useCentralized;
