import BigNumber from 'bignumber.js'
import { useSelector } from 'react-redux'
import erc20ABI from '../utils/constants/ABI/erc20.json'
import routerABI from '../utils/constants/ABI/router.json'
import allContracts from '../utils/constants/contracts'
import swapTokens from '../utils/constants/swapTokens'
import decimals from '../utils/constants/decimals'
import useExecuteContract from './useExecuteContract'
import { useAccount } from 'wagmi'
import rpcs from '../utils/constants/rpcs'
import { JsonRpcProvider } from '@ethersproject/providers'
const BIG_TEN = new BigNumber(10)

const slippagePercentage = 5

const useSwap = () => {
  const { address: userAddress } = useAccount()
  const currentNetwork = useSelector((state) => state.network)
  const { sendContractWagmi, callContractWagmi } = useExecuteContract()

  const isNativeToken = (tokenName) => {
    return tokenName === 'BNB' || tokenName === 'MATIC'
  }

  const getAmountOut = async (amountIn, tokenIn, tokenOut) => {
    if (currentNetwork === -1) return 0

    const tokenInAddr = swapTokens[currentNetwork][tokenIn]
    const tokenInDecimals = decimals[currentNetwork][tokenIn] || 18
    const tokenOutAddr = swapTokens[currentNetwork][tokenOut]
    const tokenOutDecimals = decimals[currentNetwork][tokenOut] || 18

    if (tokenInAddr === tokenOutAddr) return amountIn

    amountIn = new BigNumber(amountIn * 10 ** tokenInDecimals).toFixed()

    const [err, result] = await callContractWagmi({
      address: allContracts['router'][currentNetwork],
      abi: routerABI,
      functionName: 'getAmountsOut',
      args: [amountIn, [tokenInAddr, tokenOutAddr]]
    })

    if (err) return 0

    const amountOut = new BigNumber(result[1].toString())
      .dividedBy(10 ** tokenOutDecimals)
      .toFixed()

    return amountOut
  }

  const exchangeToken = async (amountIn, amountOutMin, tokenIn, tokenOut) => {
    if (currentNetwork === -1)
      return { status: false, message: 'Wrong network' }
    if (tokenIn === tokenOut)
      return { status: false, message: 'Cant convert same token' }
    let err = null
    let result
    const deadline = Math.ceil(Date.now() / 1000) + parseInt(1800)

    const tokenInDecimals = decimals[currentNetwork][tokenIn] || 18
    const tokenOutDecimals = decimals[currentNetwork][tokenOut] || 18

    amountIn = new BigNumber(amountIn * 10 ** tokenInDecimals).toFixed()
    amountOutMin = new BigNumber(
      amountOutMin * 10 ** tokenOutDecimals
    ).toFixed()

    const tokenInAddr = swapTokens[currentNetwork][tokenIn]
    const tokenOutAddr = swapTokens[currentNetwork][tokenOut]
    const amountOut = new BigNumber(amountOutMin)
      .multipliedBy(100 - slippagePercentage)
      .dividedBy(100)
      .integerValue(BigNumber.ROUND_DOWN)

    if (tokenIn === 'WBNB' && tokenOut === 'BNB') {
      const options = {
        address: tokenInAddr,
        abi: erc20ABI,
        functionName: 'withdraw',
        getGasPrice: true,
        args: [amountIn]
      }

      result = await sendContractWagmi(options)
    } else if (isNativeToken(tokenIn)) {
      const options = {
        address: allContracts['router'][currentNetwork],
        abi: routerABI,
        functionName: 'swapExactETHForTokens',
        getGasPrice: true,
        network: currentNetwork,
        gasLimit: '200000',
        value: Number(amountIn).toString(),
        args: [
          Number(amountOut).toString(),
          [tokenInAddr, tokenOutAddr],
          userAddress,
          deadline
        ]
      }

      result = await sendContractWagmi(options)
    } else if (isNativeToken(tokenOut)) {
      const options = {
        address: allContracts['router'][currentNetwork],
        abi: routerABI,
        functionName: 'swapExactTokensForETH',
        getGasPrice: true,
        network: currentNetwork,
        args: [
          Number(amountIn).toString(),
          Number(amountOut).toString(),
          [tokenInAddr, tokenOutAddr],
          userAddress,
          deadline
        ]
      }

      result = await sendContractWagmi(options)
    } else {
      const options = {
        address: allContracts['router'][currentNetwork],
        abi: routerABI,
        functionName: 'swapExactTokensForTokens',
        getGasPrice: true,
        network: currentNetwork,
        args: [
          amountIn,
          '0',
          [tokenInAddr, tokenOutAddr],
          userAddress,
          deadline
        ]
      }

      result = await sendContractWagmi(options)
    }

    return result
  }

  const approveRouter = async (tokenIn) => {
    if (currentNetwork === -1 || !userAddress) return
    const tokenInAddr = swapTokens[currentNetwork][tokenIn]
    const routerAddress = allContracts['router'][currentNetwork]

    const options = {
      address: tokenInAddr,
      abi: erc20ABI,
      functionName: 'approve',
      getGasPrice: true,
      network: currentNetwork,
      args: [
        routerAddress,
        '90000000000000000000000000000000000000000000000000000000000000000000000'
      ]
    }

    const result = await sendContractWagmi(options)

    return result
  }

  const isRouterApproved = async (tokenIn) => {
    if (isNativeToken(tokenIn)) return true
    if (currentNetwork === -1 || !userAddress) return false
    if (!swapTokens[currentNetwork][tokenIn]) return false
    const routerAddress = allContracts['router'][currentNetwork]

    const tokenInAddr = swapTokens[currentNetwork][tokenIn]

    const [err, result] = await callContractWagmi({
      address: tokenInAddr,
      abi: erc20ABI,
      functionName: 'allowance',
      args: [userAddress, routerAddress]
    })

    if (err) return false

    return result > '1000000000000000000000000'
  }

  const getNativeBalance = async () => {
    try {
      const _provider = new JsonRpcProvider(rpcs[currentNetwork].targetRpc)
      const result = await _provider.getBalance(userAddress)

      const balance = new BigNumber(result.toString())
        .dividedBy(BIG_TEN.pow(18))
        .toFixed()

      return balance
    } catch (err) {
      console.log('getBalance error', err)
      return 0
    }
  }

  const getTokenBalance = async (token, inWei = false) => {
    if (isNativeToken(token)) {
      const result = await getNativeBalance()
      return result
    }

    const tokenAddr = swapTokens[currentNetwork][token]

    const [err, result] = await callContractWagmi({
      address: tokenAddr,
      abi: erc20ABI,
      functionName: 'balanceOf',
      args: [userAddress]
    })

    if (err) return 0

    if (inWei) return result

    const tokenDecimals = decimals[currentNetwork][token] || 18
    const amount = new BigNumber(result.toString())
      .dividedBy(10 ** tokenDecimals)
      .toFixed()
    return amount
  }

  const getTokensList = () => {
    return Object.keys(swapTokens)
  }

  return {
    getAmountOut,
    exchangeToken,
    approveRouter,
    isRouterApproved,
    getNativeBalance,
    getTokenBalance,
    getTokensList
  }
}

export default useSwap
