import styles from "./Loading.module.css";
import img from "../../assets/NIOB-Token-Icon.ba84a2d1.svg";
const Loading = ({iconSize=25,loaderSize=70}:{iconSize?:number,loaderSize?:number}) => {
  
  const iconSizeStyle = {
    height:`${iconSize}px`,
    width:`${iconSize}px`
  }

  const loaderSizeIcon = {
    height:`${loaderSize}px`,
    width:`${loaderSize}px`
  }

  return (
    <div className={styles.loading}>
       <div className={styles.box} style={loaderSizeIcon}>
      <img src={img} className={styles.icon}  style={iconSizeStyle} />
       </div>
    </div>
  );
};
export default Loading;
