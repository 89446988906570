function isNumeric(str) {
    if (typeof str != "string") return false // we only process strings!  
    return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}

function numberWithCommas(x) {
    var parts = x?.toString()?.split(".");
    if (parts?.length > 0) {
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return parts.join(".");
    }
    return x;
    //return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
const isEmpty = (data) => {
    return (
      typeof data === "undefined" ||
      !data ||
      data === undefined ||
      data === null ||
      (typeof data === "object" && Object.keys(data).length === 0) ||
      (typeof data === "string" && data.trim().length === 0)
    );
  };


  
export { isNumeric, numberWithCommas,isEmpty };