//56 bsc main
//97 bsc testnet
//80001 polygon testnet

const rpcs = {
  0: {
    targetChainId: '0x38',
    chainId: '56',
    targetRpc: 'https://bsc-dataseed3.defibit.io',
    rpcOptions: [
      'https://bsc-dataseed3.defibit.io',
      'https://bsc-dataseed.binance.org',
      'https://bsc-dataseed4.binance.org'
    ],
    targetChainName: 'BSC Mainnet'
  },
  10: {
    targetChainId: '0x61',
    chainId: '97',
    targetRpc: 'https://data-seed-prebsc-1-s1.binance.org:8545/',
    targetChainName: 'bsctestnet'
  },
  1: {
    targetChainId: '0x89',
    chainId: '137',
    targetRpc: 'https://polygon-rpc.com/',
    targetChainName: 'Polygon Mainnet'
  },
  11: {
    targetChainId: '0x13881',
    chainId: '80001',
    targetRpc: "https://polygon-mumbai.g.alchemy.com/v2/gLNiptocRq3fXYRtsi1rTZbgiC4coOA-",
    targetChainName: 'Polygon Testnet'
  }
}

export default rpcs
