import {ReactComponent as ClockIcon} from "../../../assets/dashboard/clock1.svg";
import styles from "./PastRounds.module.css";
import classNames from "classnames";
import {ReactComponent as CollapseIcon} from "../../../assets/dashboard/expand.svg";
// import {ReactComponent as ExpandIcon} from "../../../assets/dashboard/expand.svg";
import { useState } from "react";
import { useSelector } from "react-redux";
import { numberWithCommas } from "../../../utils/libs";
const PastRounds = ({ roundsData }: { roundsData: any }) => {
  const [expand, setExpand] = useState(false);
  const currentGroup = useSelector((state:any) => state.currentGroup);
  const list = [...roundsData].splice(-8);

  const decimal = currentGroup?.showDecimal ?? 2;

  return (
    <section className={styles.pastRounds}>
      <div className={styles.top} 
            onClick={() => setExpand(prev=>!prev)}>
        <div  className={styles.clock}>

        <ClockIcon />
        </div>
        <div>Past Rounds</div>
        <div className={styles.cp}>
          {list.map((_roundData: any, ix: number) => {
            const roundData = _roundData.roundData
            const diff = roundData.closePrice - roundData.lockPrice;
            return (
              <Circle
                key={ix}
                className={
                  diff > 0 ? styles.green : diff < 0 ? styles.red : styles.gray
                }
              />
            );
          })}
        </div>
       
          <>
           <CollapseIcon
            className={styles.exp}
          />
          </>
         
      </div>
      {expand && (
        <div className={styles.content}>
          <div className={classNames(styles.row, styles.tp)}>
            <div className={classNames(styles.itm, styles.t1)}>Round</div>
            <div className={classNames(styles.itm, styles.t1)}>
              Strike Price
            </div>
            <div className={classNames(styles.itm, styles.t1)}>
              Locked Price
            </div>
          </div>
          {list.map((roundData: any, ix: number) => (
            <Row key={ix} roundData={roundData.roundData} decimal={decimal} />
          ))}
        </div>
      )}
    </section>
  );
};

const Row = ({ roundData, decimal }: { roundData: any; decimal: number }) => {
  const diff = roundData.closePrice - roundData.lockPrice;

  return (
    <div className={classNames(styles.row, styles.rw)}>
      <div className={classNames(styles.itm, styles.fitm)}>
        <Circle
          className={classNames(
            diff > 0 && styles.green,
            diff < 0 && styles.red
          )}
        />
        <div className={styles.t2}>#{roundData.roundNo}</div>
      </div>
      <div className={classNames(styles.itm, styles.t2)}>
        ${numberWithCommas(Number(roundData.closePrice).toFixed(decimal))}
      </div>
      <div className={classNames(styles.itm, styles.t2)}>
        ${numberWithCommas(Number(roundData.lockPrice).toFixed(decimal))}
      </div>
    </div>
  );
};
const Circle = ({ className }: { className: String }) => {
  return <div className={classNames(styles.circle, className)} />;
};
export default PastRounds;
