const messages = {
  info: {
    betBull: 'Opening long REPLACEtoken',
    betBear: 'Opening short REPLACEtoken',
    claim: 'Claiming',
    addBalance: 'Depositing',
    addTokenBalance: 'Depositing',
    betBullWithToken: 'Opening long REPLACEtoken',
    betBearWithToken: 'Opening short REPLACEtoken',
    hostedBetBear: 'Opening short REPLACEtoken',
    hostedBetBull: 'Opening long REPLACEtoken',
    enter: 'Buying Tickets',
    approve: 'Approving',
    claimBNB: 'Claiming',
    enableAddress: 'Enabling',
    claimReferenceBonus: 'Claiming',
    setReferrer: 'Referring',
    swapExactTokensForTokens: 'Swapping',
    swapExactTokensForETH: 'Swapping',
    swapExactETHForTokens: 'Swapping',
    bid: 'Bidding',
    completeRound: 'Completing round'
  },
  success: {
    betBull: 'REPLACEtoken long opened',
    betBear: 'REPLACEtoken short opened',
    claim: 'Claim success',
    betBullWithToken: 'REPLACEtoken long opened',
    betBearWithToken: 'REPLACEtoken short opened',
    hostedBetBear: 'REPLACEtoken short opened',
    hostedBetBull: 'REPLACEtoken long opened',
    addBalance: 'Deposit - Successfull',
    addTokenBalance: 'Deposit - Successfull',
    enter: 'Tickets bought successfully',
    approve: 'Approve success',
    claimBNB: 'Claim success',
    enableAddress: 'Wallet enabled',
    claimReferenceBonus: 'Claim success',
    setReferrer: 'You have successfully been referred',
    swapExactTokensForTokens: 'Swap success',
    swapExactTokensForETH: 'Swap success',
    swapExactETHForTokens: 'Swap success',
    bid: 'Bid success',
    completeRound: 'Round completed'
  },
  globalDefaultSuccess: 'Success',
  errorIncludes: {
    'User denied transaction signature': 'Transaction cancelled',
    'user rejected transaction': 'Transaction cancelled',
    'insufficient funds': 'Insufficient funds',
    '<minbet': 'Minimum bet size is REPLACEmin',
    '>maxbet': 'Maximum bet size is REPLACEmax',
    'no balance': 'No balance to claim',
    completed: 'Round is already complete, please try on new round',
    'not enough ticket': 'Not enough tickets, please try again',
    'You can not set a referrer after making a bet':
      'You can not set a referrer after making a bet.',
    nobonuses: 'Nothing to claim',
    'need min bid increment':
      'A user made the same bid before you. Please try again with a higher bid.',
    TRANSFER_FROM_FAILED: 'Swap failed. Balance is not enough',
    INSUFFICIENT_OUTPUT_AMOUNT: 'Swap failed',
    'gas required exceeds allowance': 'Gas cost exceeds your balance'
  },
  errorDefaults: {
    betBull: 'Position could not be opened please try again',
    betBear: 'Position could not be opened please try again',
    swapExactTokensForTokens: 'Swap failed'
  },
  globalDefaultError: 'Transaction failed'
}

export default messages
