function reducer(
  state = {
    niobObj: {
      tokenprice: 0,
      totalvaluelocked: 0,
      totalsupply: 0,
      maxsupply: 0,
    },
    menuState: true,
    userHistory: null,
    userOldHistory: null,
    refreshUserHistory: false,
    reloadRounds: false,
    tokenPrices: {},
    calculating: false,
    roundsData: null,
    timerTick: 0,
    displayConnectModal:false,
    showSmHistory:false,
    roundLockTimestamp: null,
    onUserLotteryTicket: null,
    onCollect: null,
    onRewardPool: null,
    onJackpot: null,
    onJackpotResult: null,
    onLottery: null,
    boostMode: false,
    showAutoModeDialog: false,
    tardingMode: 0,
    showLastRound:false,
    onAutoTarde: null,
    onBet: null,
    onDeposit: null,
    onWithdraw: null,
    depositListChange: false,
    withdrawalListChange: false,
    configs: {},
    isAuthenticated: false,
    userBalances: [],
    showDepositWithdrawDialog: 0,
    messages: [],
    raffleDataChange: false,
    switchToNetwork: null,
    proSelectedTargetToken: null,
    proUpdateBalance: false,
    proTargetPrices: null,
    proShowConnect: false,
    proHistoryChange: false,
    isDarkTheme: null,
    network: -1,
    menuItem: 0,
    currentGroup: {},
    timeLeft: -1,
    timeSync: null,
    page: "LandingPage",
    num: 0,
    showMenu: false,
    connectToWallet: false,
    chartData: [],
    web3: null,
    userAddress: null,
    approve: false,
    paused: false,
    claim: false,
    bet: false,
    prices: {
      BTC: 0,
      BNB: 0,
      ETH: 0,
      TSLA: 0,
      CAKE: 0,
      MATIC: 0,
      XVS: 0,
      BANANA: 0,
    },
    pricePercents: {
      BTC: 0,
      BNB: 0,
      ETH: 0,
      TSLA: 0,
      CAKE: 0,
      MATIC: 0,
      XVS: 0,
      BANANA: 0,
    },
    closeLive: false,
    closeNext: false,
    gotoNextSlide: false,
    gotoLiveSlide: false,
    visible: true /*for menu*/,
    audio: true,
    pricesOnePrediction: null,
    lockTime: null,
    auctionLeftTimer: null,
    updateAuctionData: false,
  },
  action
) {
  //currenctCurrency=prediction
  //num=currentround

  switch (action.type) {
    case "niobObjChange":
      var x = {
        tokenprice: action.niobObj.tokenprice,
        totalvaluelocked: action.niobObj.totalvaluelocked,
        totalsupply: action.niobObj.totalsupply,
        maxsupply: action.niobObj.maxsupply,
      };

      return {
        ...state,
        niobObj: x,
        // prices: action.prices
      };
    case "menuStateChange":
      return {
        ...state,
        menuState: action.menuState,
      };
    case "setRefreshUserHistory":
      return {
        ...state,
        refreshUserHistory: !state.refreshUserHistory,
      };
    case "setUserHistory":
      return {
        ...state,
        userHistory: action.userHistory,
      };
    case "setUserOldHistory":
      return {
        ...state,
        userOldHistory: action.userOldHistory,
      };
    case "setReloadRounds":
      return {
        ...state,
        reloadRounds: !state.reloadRounds,
      };
    case "setTokenPrice":
      const tkps = state.tokenPrices;
      tkps[action.token] = { price: action.price, updateAt: action.updateAt };
      return {
        ...state,
        tokenPrices: { ...tkps },
      };
    case "setCalculating":
      return {
        ...state,
        calculating: action.calculating,
      };
    case "setRoundsData":
      return {
        ...state,
        roundsData: action.roundsData,
      };
    case "toggleTimerTick":
      return {
        ...state,
        timerTick: state.timerTick + 1,
      };
    case "setRoundLockTimestamp":
      return {
        ...state,
        roundLockTimestamp: action.roundLockTimestamp,
      };
    case "setOnUserLotteryTicket":
      return {
        ...state,
        onUserLotteryTicket: action.onUserLotteryTicket,
      };
    case "setOnRewardPool":
      return {
        ...state,
        onRewardPool: action.onRewardPool,
      };
    case "setOnJackpotResult":
      return {
        ...state,
        onJackpotResult: action.onJackpotResult,
      };
    case "setOnJackpott":
      return {
        ...state,
        onJackpot: action.onJackpot,
      };
    case "setOnCollect":
      return {
        ...state,
        onCollect: action.onCollect,
      };
    case "setOnLottery":
      return {
        ...state,
        onLottery: action.onLottery,
      };
    case "setBoostMode":
      return {
        ...state,
        boostMode: action.boostMode,
      };
    case "showAutoModeDialogChange":
      return {
        ...state,
        showAutoModeDialog: action.showAutoModeDialog,
      };
    case "setTardingMode":
      return {
        ...state,
        tardingMode: action.tardingMode,
      };

    case "showLastRound":
      return{
        ...state,
        showLastRound:action.showLastRound
      }
    case "setOnAutoTarde":
      return {
        ...state,
        onAutoTarde: action.onAutoTarde,
      };
    case "setOnBet":
      return {
        ...state,
        onBet: action.onBet,
      };
    case "setOnDeposit":
      return {
        ...state,
        onDeposit: action.onDeposit,
      };
    case "setOnWithdraw":
      return {
        ...state,
        onWithdraw: action.onWithdraw,
      };
    case "toggleConnectModal":
      return{
        ...state,
        displayConnectModal:action.displayConnectModal
      };
    case "setWithdrawalListChange":
      return {
        ...state,
        withdrawalListChange: !state.withdrawalListChange,
      };
    case "setDepositListChange":
      return {
        ...state,
        depositListChange: !state.depositListChange,
      };
    case "setConfigs":
      return {
        ...state,
        configs: action.configs,
      };
    case "showDepositWithdrawDialogChange":
      return {
        ...state,
        showDepositWithdrawDialog: action.showDepositWithdrawDialog,
      };
    case "setIsAuthenticated":
      return {
        ...state,
        isAuthenticated: action.isAuthenticated,
      };
    case "setUserBalances":
      return {
        ...state,
        userBalances: action.userBalances,
      };
    case "setMessages":
      return {
        ...state,
        messages: action.messages,
      };
    case "raffleDataChanged":
      return {
        ...state,
        raffleDataChange: !state.raffleDataChange,
      };
    case "switchToNetworkChange":
      return {
        ...state,
        switchToNetwork: action.switchToNetwork,
      };
    case "proUpdateSelectedTargetToken":
      return {
        ...state,
        proSelectedTargetToken: action.proSelectedTargetToken,
      };
    case "proSetHistoryChange":
      return {
        ...state,
        proHistoryChange: !state.proHistoryChange,
      };
    case "proUpdateTargetPrices":
      return {
        ...state,
        proTargetPrices: action.proTargetPrices,
      };
    case "proShowConnectChange":
      return {
        ...state,
        proShowConnect: action.proShowConnect,
      };
    case "proUpdateBalance":
      return {
        ...state,
        proUpdateBalance: !state.proUpdateBalance,
      };
    case "auctionLeftTimerChange":
      return {
        ...state,
        auctionLeftTimer: action.auctionLeftTimer,
      };
    case "updateAuctionDataToggle":
      return {
        ...state,
        updateAuctionData: !state.updateAuctionData,
      };
    case "auctionLeftTimerDecrement":
      return {
        ...state,
        auctionLeftTimer: state.auctionLeftTimer
          ? state.auctionLeftTimer - 1
          : state.auctionLeftTimer,
      };
    case "themeChange":
      return {
        ...state,
        isDarkTheme: action.isDarkTheme,
      };
    case "menuContentChange":
      return {
        ...state,
        menuItem: action.menuItem,
      };
    case "networkChange":
      return {
        ...state,
        network: action.network,
      };
    case "visibleChange":
      return {
        ...state,
        visible: action.visible,
      };

    case "lockTimeChange":
      return {
        ...state,
        lockTime: action.lockTime,
      };

    case "pageChange":
      return {
        ...state,
        page: action.page,
      };

    case "groupChange":
      return {
        ...state,
        currentGroup: action.currentGroup,
      };
    case "numChange":
      return {
        ...state,
        num: action.num,
      };
    case "audioChange":
      return {
        ...state,
        audio: action.audio,
      };
    case "connectToWalletChange":
      return {
        ...state,
        connectToWallet: action.connectToWallet,
      };
    case "showMenuChange":
      return {
        ...state,
        showMenu: action.showMenu,
      };
    case "chartDataChange":
      return {
        ...state,
        chartData: action.chartData,
      };
    case "timeLeftChange":
      return {
        ...state,
        timeLeft: action.timeLeft,
      };

    case "web3Change":
      return {
        ...state,
        web3: action.web3,
        userAddress: action.userAddress,
      };

    case "approveChange":
      return {
        ...state,
        approve: action.approve,
      };

    case "pausedChange":
      return {
        ...state,
        paused: action.paused,
      };

    case "claimChange":
      return {
        ...state,
        claim: action.claim,
      };

    case "toggleSmHistory":
      return{
        ...state,
        showSmHistory:!state.showSmHistory
      };

    case "betChange":
      return {
        ...state,
        bet: !state.bet,
      };
    case "timeSyncChange":
      return {
        ...state,
        timeSync: action.timeSync,
      };

    case "gotoNextSlideChange":
      return {
        ...state,
        gotoNextSlide: !state.gotoNextSlide,
      };
    case "gotoLiveSlideChange":
      return {
        ...state,
        gotoLiveSlide: !state.gotoLiveSlide,
      };
    case "closeLiveChange":
      return {
        ...state,
        closeLive: action.closeLive,
      };
    case "closeNextChange":
      return {
        ...state,
        closeNext: action.closeNext,
      };

    case "pricesChange":
      return {
        ...state,
        prices: {
          BTC: action.prices["BTC"],
          BNB: action.prices["BNB"],
          ETH: action.prices["ETH"],
          TSLA: action.prices["TSLA"],
          CAKE: action.prices["CAKE"],
          MATIC: action.prices["MATIC"],
          XVS: action.prices["XVS"],
          BANANA: action.prices["BANANA"],
        },
        // prices: action.prices
      };
    case "pricesOnePredictionChange":
      return {
        ...state,
        pricesOnePrediction: action.prices,
      };

    case "pricesChange2":
      var x = {
        BTC: state.prices["BTC"],
        BNB: state.prices["BNB"],
        ETH: state.prices["ETH"],
        TSLA: state.prices["TSLA"],
        CAKE: state.prices["CAKE"],
        MATIC: state.prices["MATIC"],
        XVS: state.prices["XVS"],
        BANANA: state.prices["BANANA"],
      };
      x[action.token] = action.prices[action.token];

      return {
        ...state,
        prices: x,
        // prices: action.prices
      };
    case "pricePercentsChange":
      return {
        ...state,
        pricePercents: {
          BTC: action.pricePercents["BTC"],
          BNB: action.pricePercents["BNB"],
          ETH: action.pricePercents["ETH"],
          TSLA: action.pricePercents["TSLA"],
          CAKE: action.pricePercents["CAKE"],
          MATIC: action.pricePercents["MATIC"],
          XVS: action.pricePercents["XVS"],
          BANANA: action.pricePercents["BANANA"],
        },
      };

    default:
      return state;
  }
}

export default reducer;
