import { usePrediction, usePrice } from "../core";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAccount } from "wagmi";

const SetupSlider = () => {
  const {
    getCurrentRoundNo,
    getCurrentTimestamp,
    getRoundLockTimestamp,
    hasStopped,
    getMultiRoundData,
  } = usePrediction();
  const { getPriceByGroup } = usePrice();

  const dispatch = useDispatch();
  const { address } = useAccount();
  const timerTick = useSelector((state: any) => state.timerTick);
  const reloadRounds = useSelector((state: any) => state.reloadRounds);
  const timeSync = useSelector((state: any) => state.timeSync);

  const network = useSelector((state: any) => state.network);
  const networkRef = useRef(network);
  networkRef.current = network;

  const currentRoundNo = useSelector((state: any) => state.num);
  const currentRoundNoRef = useRef(currentRoundNo);
  currentRoundNoRef.current = currentRoundNo;

  const currentGroup = useSelector((state: any) => state.currentGroup);
  const currentGroupRef = useRef(currentGroup);
  currentGroupRef.current = currentGroup;

  const roundLockTimestamp = useSelector(
    (state: any) => state.roundLockTimestamp
  );

  /////
  const reloadRoundNumber = async () => {
    if (network >= 0) {
      /////
      const roundNum = await getCurrentRoundNo();
      if (roundNum && roundNum > 0) {
        const lockTimestamp = await getRoundLockTimestamp(roundNum);
        if (
          timeSync != null &&
          lockTimestamp &&
          lockTimestamp > 0 &&
          network == networkRef.current &&
          currentRoundNoRef.current < roundNum
        ) {
          dispatch({ type: "numChange", num: roundNum });
          dispatch({
            type: "setRoundLockTimestamp",
            roundLockTimestamp: lockTimestamp + timeSync,
          });
        }
      }
    }
  };
  /////////////
  useEffect(() => {
    const interval = setInterval(() => {
      dispatch({ type: "toggleTimerTick" });
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    getCurrentTimestamp().then((serverTime) => {
      if (serverTime > 0) {
        const timeSync = Date.now() / 1000 - serverTime;
        dispatch({ type: "timeSyncChange", timeSync: timeSync });
      }
    });
  }, []);

  useEffect(() => {
    if (timeSync != null && network >= 0) {
      dispatch({ type: "numChange", num: 0 });
      dispatch({
        type: "setRoundLockTimestamp",
        roundLockTimestamp: null,
      });

      dispatch({ type: "setRoundsData", roundsData: null });

      reloadRoundNumber();
    }
  }, [network, timeSync]);

  useEffect(() => {
    if (network >= 0 && timerTick % 180 == 0) {
      hasStopped().then((stoped: any) => {
        if (network == networkRef.current) {
          dispatch({ type: "pausedChange", paused: stoped });
        }
      });
    }
  }, [network, timerTick]);
  ///////////////////////////////////////////
  ////////////////////////////////////////////
  useEffect(() => {
    if (currentRoundNo > 0) {
      getMultiRoundData(currentRoundNo, 22).then((roundsData) => {
        if (
          roundsData &&
          networkRef.current == network &&
          currentRoundNoRef.current == currentRoundNo
        ) {
          //console.log("ts1", roundsData);
          dispatch({ type: "setRoundsData", roundsData: roundsData });
        }
      });
    }
  }, [address, currentRoundNo, reloadRounds]);
  ///////////////// Handle calculating state
  useEffect(() => {
    if (timerTick % 2 == 0) {
      const now = Date.now() / 1000;
      if (roundLockTimestamp - now <= 0) {
        dispatch({ type: "setCalculating", calculating: true });
        reloadRoundNumber();
      } else {
        dispatch({ type: "setCalculating", calculating: false });
      }
    }
  }, [timerTick]);
  //////////////////
  useEffect(() => {
    if (currentGroup && timerTick % 3 == 0) {
      getPriceByGroup(currentGroup).then((res: any) => {
        if (res?.price && currentGroup._key === currentGroupRef.current._key) {
          dispatch({
            type: "setTokenPrice",
            token: currentGroup.token,
            price: res.price,
            updateAt: Date.now(),
          });
        }
      });
    }
  }, [timerTick, currentGroup]);
  return <></>;
};
export default SetupSlider;
