import axios from "axios";
import { getTokenById } from "./constants";
const supported_resolutions = ["1", "5", "15", "30", "60", "120", "240"];
const configurationData = {
  supported_resolutions: supported_resolutions,
  exchanges: [{ value: "Pyth", name: "Pyth", desc: "Pyth" }],
  symbols_types: [{ name: "crypto", value: "crypto" }],
};

const datafeedFactory = () => {
  return {
    resetChartData: null,
    lastBarsCache: new Map(),
    channelToSubscription: new Map(),

    onReady: (callback) => {
      //console.log("[onReady]: Method call");
      setTimeout(() => callback(configurationData));
    },
    searchSymbols: (userInput, exchange, symbolType, onResultReadyCallback) => {
      //console.log("[searchSymbols]: Method call");
    },
    resolveSymbol: (
      symbolId,
      onSymbolResolvedCallback,
      onResolveErrorCallback,
      extension
    ) => {
      // console.log("[resolveSymbol]: Method call", symbolName);
      const tkn = getTokenById(symbolId);
      const symbolInfo = {
        id: tkn?.id,
        ticker: tkn?.id,
        exchange: "Pyth",
        name: tkn?.chartTitle,
        description: tkn?.chartTitle,
        type: tkn.group,
        session: "24x7",
        minmov: 1,
        pricescale: tkn?.pricescale ?? 100,
        has_intraday: true,
        visible_plots_set: "ohlc",
        has_weekly_and_monthly: false,
        supported_resolutions: supported_resolutions,
        volume_precision: 2,
        data_status: "streaming",
        has_seconds: true,
        chartKey: tkn?.chartKey,
      };
      //console.log("[resolveSymbol]: Symbol resolved", symbolName);
      onSymbolResolvedCallback(symbolInfo);
    },

    getBarsForMinutes: function (
      symbolInfo,
      resolution,
      periodParams,
      onHistoryCallback
    ) {
      const _to = Math.round(Date.now() / 1000);
      const _from = _to - resolution * 60 * 2000;
      const apiUrl = `https://benchmarks.pyth.network/v1/shims/tradingview/history?symbol=${symbolInfo.chartKey}&resolution=${resolution}&from=${_from}&to=${_to}`;
      axios
        .get(apiUrl, {
          headers: {},
        })
        .then((res) => {
          /////
          const bars = [];
          if (
            res &&
            res.status == 200 &&
            res?.data?.s == "ok" &&
            res?.data?.t?.length > 0
          ) {
            for (let i = 0; i < res.data.t.length; i++) {
              bars.push({
                time: res.data.t[i] * 1000,
                low: res.data.l[i],
                high: res.data.h[i],
                open: res.data.o[i],
                close: res.data.c[i],
              });
            }
          }

          if (periodParams.firstDataRequest) {
            const cacheId = this.getCacheId(symbolInfo.id, resolution);
            if (bars.length > 0) {
              this.lastBarsCache.set(cacheId, {
                resolution,
                bar: bars[bars.length - 1],
              });
            } else {
              this.lastBarsCache.delete(cacheId);
            }
          }
          onHistoryCallback(bars, { noData: true });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getBars: function (
      symbolInfo,
      resolution,
      periodParams,
      onHistoryCallback,
      onErrorCallback
    ) {
      if (!periodParams.firstDataRequest) {
        onHistoryCallback([], {
          noData: true,
        });
      }
      //////////
        this.getBarsForMinutes(
          symbolInfo,
          resolution,
          periodParams,
          onHistoryCallback
        );
      
    },

    /////////
    subscribeBars: function (
      symbolInfo,
      resolution,
      onRealtimeCallback,
      subscriberUID,
      onResetCacheNeededCallback
    ) {
      if (symbolInfo.id && resolution) {
        this.channelToSubscription.set(subscriberUID, {
          tokenId: symbolInfo.id.toUpperCase(),
          resolution,
          callback: onRealtimeCallback,
          onResetCacheNeededCallback: onResetCacheNeededCallback,
        });
      }
    },
    unsubscribeBars: function (subscriberUID) {
      this.channelToSubscription.delete(subscriberUID);
    },
    getIntervalMilliseconds: function (interval) {
      let value = parseInt(interval.slice(0, -1));
        value = parseInt(interval);
        return value * 60000;
      
    },
    getCacheId: function (tokenId, resolution) {
      return tokenId.toUpperCase() + "-" + resolution;
    },
    /* animationFrameId: null,
    smoothUpdate: function (callback, lastBar, nextBar) {
      //let animationFrameId;
      let totalGap = nextBar.close - lastBar.close;
      let totalIntervals = 10;
      let interval = 0;
      function animatePriceUpdate() {
        console.log(interval);
        interval++;
        if (interval <= totalIntervals) {
          let price = lastBar.close + (totalGap / totalIntervals) * interval;
          let bar = {
            ...lastBar,
            close: price,
          };
          callback(bar);
          this.animationFrameId = requestAnimationFrame(animatePriceUpdate);
        }
      }
      this.animationFrameId = requestAnimationFrame(animatePriceUpdate);
    },*/
    update: function (selectedToken, tokenPriceObj) {
      const tokenId = selectedToken?.id?.toLocaleUpperCase();
      const price = Number(tokenPriceObj.price);
      this.channelToSubscription?.forEach((subs, subsId) => {
        if (tokenId === subs.tokenId) {
          const cacheId = this.getCacheId(tokenId, subs.resolution);
          const lastBar = this.lastBarsCache.get(cacheId);
          if (lastBar && lastBar.bar && lastBar.resolution == subs.resolution) {
            const t1 = lastBar.bar.time;
            const t2 = tokenPriceObj.timestamp;
            const intervalSeconds = this.getIntervalMilliseconds(
              subs.resolution
            );
            const n = Math.floor((t2 - t1) / intervalSeconds);
            const t3 = t1 + n * intervalSeconds;
            let bar;
            const ign = intervalSeconds >= 60000 ? 1 : 60;
            if (n > ign) {
              subs?.onResetCacheNeededCallback?.();
              this.resetChartData?.();
            } else {
              if (n == 1) {
                bar = {
                  time: t3,
                  open: price,
                  high: price,
                  low: price,
                  close: price,
                };
              } else {
                bar = {
                  ...lastBar.bar,
                  high: Math.max(lastBar.bar.high, price),
                  low: Math.min(lastBar.bar.low, price),
                  close: price,
                };
              }
              this.lastBarsCache.set(cacheId, {
                resolution: lastBar.resolution,
                bar,
              });
              //cancelAnimationFrame(this.animationFrameId);
              //this.smoothUpdate(subs.callback, lastBar.bar, bar);
              subs.callback(bar);
            }
          }
        }
      });
    },
  };
};

export default datafeedFactory;
