import styles from "./Top.module.css"
import { useSelector, useDispatch } from "react-redux";
import {useState} from "react"
import classnames from "classnames"
function Top(){
    const dispatch = useDispatch();
const [selects,setSelects]=useState([true,false]);
var iconCss=[styles.iconSelected,null];
var textCss=[styles.textSelected,null];
if(selects[1]==true)
{
    iconCss=[null,styles.iconSelected];
    textCss=[null,styles.textSelected];
}

    return(<div className={styles.total}>
<div onClick={()=>{setSelects([true,false]); dispatch({type: "menuContentChange", menuItem: 0});}}><i className={classnames("fas fa-clock", styles.icon,iconCss[0])}></i><span className={classnames(styles.text,textCss[0])}>Predictions</span></div>
<div onClick={()=>{window.open("https://docs.niob.finance/prdt-getting-started/","_blank")}}><i className={classnames("fa fa-question-circle", styles.icon)}></i><span className={styles.text}>How It Works</span></div>
<div onClick={()=>{setSelects([false,true]); dispatch({type: "menuContentChange", menuItem: 1});}}><i className={classnames("fas fa-history", styles.icon,iconCss[1])}></i><span className={classnames(styles.text,textCss[1])}>History</span></div>
    </div>)
}
export default Top;