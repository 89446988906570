import { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import logo from "../assets/Group_11729.png";
import styles from "./Slider.module.css";
import classnames from "classnames";
import "swiper/components/pagination/pagination.min.css";
import "swiper/swiper.min.css";
import { useSelector } from "react-redux";
import SwiperCore, { Mousewheel, Pagination } from "swiper/core";
import leftArrow from "../assets/Group_11748.png";
import rightArrow from "../assets/Icon awesome-arrow-alt-circle-right.png";
import SliderPannel from "./SliderPannel";
import useWindowDimensions from "../utils/useWindowDimensions";
import PauseSlide from "./PauseSlide";
import Loading from "./Loading/Loading";
SwiperCore.use([Pagination, Mousewheel]);

export default function Slider() {
  //////////////////////////
  const network = useSelector((state) => state.network);
  const { height, width } = useWindowDimensions();
  const currentGroup = useSelector((state) => state.currentGroup);
  const roundData = useSelector((state) => state.roundsData);
  /////////////
  const now = new Date();
  const currentDay = now.getUTCDay();
  const currentHour = now.getUTCHours();
  const tradingHoursCfg = currentGroup.tradingHours;
  const tradingDays = tradingHoursCfg?.days;
  const tradingStartHour = tradingHoursCfg?.startHour;
  const tradingEndHour = tradingHoursCfg?.endHour;

  const isTradingHours =
    tradingHoursCfg == null ||
    (tradingDays.includes(currentDay) &&
      currentHour >= tradingStartHour &&
      currentHour < tradingEndHour);
  //////////
  const slideTypes = {
    expire: 1,
    live: 2,
    next: 3,
    future: 4,
    leader: 5,
    chart: 6,
    lottery: 7,
    pause: 8,
  };
  const slides = [];
  const circles = [];
  if (roundData && currentGroup?.predictionId >= 0) {
    const list = Object.entries(roundData[currentGroup.predictionId] ?? {})
      .map(([key, value]) => value)
      .sort((a, b) => a.roundNo - b.roundNo);

    list.forEach((d, idx, arr) => {
      // if (idx === arr.length - 1) return;
      if (idx < arr.length - 2) {
        slides.push({
          type: slideTypes.expire,
          roundData: d,
        });

        const diff = Number(d?.closePrice - d?.lockPrice);
        if (diff == 0) {
          circles.push(0);
        } else if (diff > 0) {
          circles.push(1);
        } else if (diff < 0) {
          circles.push(-1);
        }
      }
      if (idx == arr.length - 2) {
        if (isTradingHours) {
          slides.push({
            type: slideTypes.live,
            roundData: d,
          });
        } else {
          slides.push({ type: slideTypes.pause, roundData: d });
        }
      }
    });
  }
  if (slides?.length > 0) {
    if (isTradingHours) {
      slides.push({ type: slideTypes.next });
    }
  }
  //////////////////////////
  const menuState = useSelector((state) => state.menuState);
  const [swiper, setSwiper] = useState(null);
  const round = useSelector((state) => state.num);

  var expireCss = null;
  if (menuState == false && width > 960) {
    expireCss = styles.expired11;
  }
  const params = {
    container: ".swiper-container",
    mousewheel: true,
  };
  var num = "auto";
  var space = 0;
  var flag = true;
  var flag2 = false;
  const activeSlide = 20;
  useEffect(() => {
    if (swiper && slides && activeSlide < slides.length)
      swiper.slideTo(activeSlide);
  }, [network]);
  const partnerCode = "0x49562e9a8dde1006577bf6d08644e15e2194601b";
  return (
    <>
      <div className={styles.arrows}>
        <div
          className={styles.left}
          id="previous"
          onClick={() => {
            var index = swiper.activeIndex;
            if (index >= 1) swiper.slideTo(index - 1);
          }}
        >
          <img src={leftArrow} className={styles.le}></img>
        </div>
        <img src={logo} className={styles.logo}></img>
        <div
          onClick={() => {
            var index = swiper.activeIndex;
            if (index <= 22) swiper.slideTo(index + 1);
          }}
          className={styles.right}
          id="next"
        >
          <img src={rightArrow} className={styles.le}></img>
        </div>
      </div>
      <div className={styles.slider}>
        <Swiper
          onSwiper={setSwiper}
          {...params}
          slidesPerView={num}
          centeredSlides={flag}
          spaceBetween={space}
          initialSlide={activeSlide}
          slideToClickedSlide={true}
          className={styles.swiper}
          allowPageScroll={"vertical"}
          allowTouchMove={flag2}
          mousewheel={{
            forceToAxis: true,
          }}
        >
          {slides?.map((slide, ix) => {
            if (slide.type == slideTypes.expire)
              return (
                <SwiperSlide key={ix} className={styles.expired1}>
                  {({ isActive, isPrev, isNext }) => (
                    <SliderPannel
                      roundData={slide.roundData}
                      isActive={true}
                      status="expired"
                      url={slide.url}
                    />
                  )}
                </SwiperSlide>
              );
            else if (slide.type == slideTypes.live)
              return (
                <SwiperSlide key={ix} className={styles.live}>
                  {({ isActive, isPrev, isNext }) => (
                    <SliderPannel
                      roundData={slide.roundData}
                      isActive={isActive}
                      status={"live"}
                      url={slide.url}
                    />
                  )}
                </SwiperSlide>
              );
            else if (slide.type == slideTypes.next)
              return (
                <SwiperSlide key={ix} className={styles.next}>
                  {({ isActive, isPrev, isNext }) => (
                    <SliderPannel
                      isActive={isActive}
                      status={"next"}
                      partnerCode={partnerCode}
                    />
                  )}
                </SwiperSlide>
              );
            else if (slide.type == slideTypes.pause) {
              return (
                <SwiperSlide key={ix} className={styles.expired}>
                  <PauseSlide roundData={slide.roundData} />
                </SwiperSlide>
              );
            } else return <></>;
          })}
        </Swiper>
        {slides.length == 0 && (
          <div className={styles.loading}>
            <Loading />
          </div>
        )}
      </div>
      <div className={styles.total}>
        <div className={styles.past}>Past round results:</div>
        <div className={styles.circles}>
          <div>
            {circles.slice(0, 11).map((state, ix) => (
              <button
                key={ix}
                className={classnames(
                  styles.circle,
                  state == -1 && styles.red,
                  state == 0 && styles.gray,
                  state == 1 && styles.green
                )}
                onClick={() => {
                  swiper.slideTo(ix + 10);
                }}
              ></button>
            ))}
          </div>
          <div>
            {circles.slice(11, 21).map((state, ix) => (
              <button
                key={ix}
                className={classnames(
                  styles.circle,
                  state == -1 && styles.red,
                  state == 0 && styles.gray,
                  state == 1 && styles.green
                )}
                onClick={() => {
                  swiper.slideTo(ix + 10);
                }}
              ></button>
            ))}
          </div>
        </div>
        <div className={styles.rounds}>
          <div>{round - 21}</div>
          <div>{round - 2}</div>
        </div>
      </div>
    </>
  );
}
