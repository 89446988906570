function numberWithCommas(x) {
  var parts = x?.toString()?.split(".");
  if (parts?.length > 0) {
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }
  return x;
  //return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export { numberWithCommas };
