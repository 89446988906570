import classnames from "classnames";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAccount } from "wagmi";
import logo2 from "../assets/NIOB-Token-Icon.ba84a2d1.svg";
import certic from "../assets/certik-logotype-h-w.svg";
import icon2 from "../assets/download.png";
import icon from "../assets/menu.png";
import logo from "../assets/nioblogo.svg";
import styles from "./Header.module.css";
import Modal from "./Modal.js";
function Header(props) {
  const dispatch = useDispatch();
  const menuState = useSelector((state) => state.menuState);
  const { address } = useAccount();

  var str = "Connect";
  if (address != null) {
    str = address.slice(0, 5) + "..." + address.slice(address.length - 4);
  }
  var currentIcon = icon;
  if (menuState == false) currentIcon = icon2;
  var visibleCss = null;
  if (props.visible == false) {
    visibleCss = styles.notVisible;
  }

  // const [show, setShow] = useState(false);

  const show = useSelector((state)=>state.displayConnectModal);

  const setShow = (state)=>{
    dispatch({type:'toggleConnectModal',displayConnectModal:state})
  }
  var css = styles.hide;
  if (show == true) css = null;
  return (
    <div className={classnames(styles.total, visibleCss)}>
      <div className={classnames(styles.modal, css)}>
        <Modal setShow={setShow} userAddress={address} />
      </div>
      <div className={styles.col1}>
        <div>
          <img
            src={currentIcon}
            className={styles.icon}
            onClick={() => {
              dispatch({ type: "menuStateChange", menuState: !menuState });
            }}
          ></img>
        </div>
        <div>
          {" "}
          <img src={logo} className={styles.logo}></img>
          <img src={logo2} className={styles.logo2}></img>
        </div>
      </div>
      <div className={styles.col2}>
        <div>
          <img src={certic} className={styles.certic}></img>
        </div>
        <div>
          {" "}
          <button
            className={styles.connectButton}
            onClick={() => {
              setShow(true);
            }}
          >
            {str}
          </button>
        </div>
      </div>
    </div>
  );
}
export default Header;
