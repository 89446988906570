const formatDate = (date: Date): string => {
    const year = date.getFullYear() % 100; // Get last two digits of the year
    const month = padZero(date.getMonth() + 1);
    const day = padZero(date.getDate());
  
    return `${month}/${day}/${year}`;
  };
  
  const formatTime = (date: Date): string => {
    const hours = padZero(date.getHours());
    const minutes = padZero(date.getMinutes());
    const seconds = padZero(date.getSeconds());
  
    return `${hours}:${minutes}:${seconds}`;
  };
  
  const padZero = (num: number): string => {
    return num < 10 ? `0${num}` : `${num}`;
  };
  
  const getCurrentTime = (_date: Date = new Date()): string => {
    const formattedDate = formatDate(_date);
    const formattedTime = formatTime(_date);
  
    return `${formattedDate} ${formattedTime}`;
  };
  
  const getDateAndTimeFromTimestamp = (_timeStamp: number): string => {
    const date = new Date(_timeStamp * 1000); // Convert seconds to milliseconds
  
    return getCurrentTime(date);
  };
  
  export { getCurrentTime, getDateAndTimeFromTimestamp };
  