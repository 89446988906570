import { ReactComponent as CursorIcon } from "../assets/cursor.svg";
import classnames from "classnames";
import { useSelector } from "react-redux";
import DirectionArrow from "./DirectionArrow";
import styles from "./PauseSlide.module.css";
import { ReactComponent as InfoIcon } from "../assets/info.svg";
import { useEffect } from "react";
import ReactTooltip from "react-tooltip";
import { TokenIcon } from "../tokenIcon/tokenIcon";

const PauseSlide = (props: any) => {
  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  const timerTick = useSelector((state: any) => state.timerTick);

  const currentRoundNo = useSelector((state: any) => state.num);
  const currenctGroup = useSelector((state: any) => state.currentGroup);
  const roundData = props.roundData;

  /////////
  const tradingHoursCfg = currenctGroup.tradingHours;
  const tradingDays = tradingHoursCfg?.days;
  const firstTradingDays = tradingDays?.[0];
  const lastTradingDays = tradingDays?.[tradingDays?.length - 1];
  const tradingStartHour = tradingHoursCfg?.startHour;
  const tradingEndHour = tradingHoursCfg?.endHour;
  /////////
  const openInterval = (tradingEndHour ?? 0) - (tradingStartHour ?? 0);
  const closePer = openInterval > 0 ? (1 - openInterval / 24) * 100 : 0;
  /////////
  const now: any = new Date();
  const currentDay = now.getUTCDay();
  const currentHour = now.getUTCHours();
  //const currentMinutes = now.getUTCMinutes();
  //const currentSeconds = now.getUTCSeconds();
  ///////

  ///////
  const isWeekend =
    !tradingDays?.includes(currentDay) ||
    (currentDay == firstTradingDays && currentHour < tradingStartHour) ||
    (currentDay == lastTradingDays && currentHour >= tradingEndHour);
  ///////

  const endDate: any = new Date(now);
  endDate.setUTCHours(tradingEndHour, 0, 0, 0);
  if (currentHour >= tradingEndHour) {
    endDate.setDate(now.getDate() + 1);
  }

  const startDate: any = new Date(endDate);
  startDate.setDate(endDate.getDate() - 1);

  var openDate: any = new Date(endDate);
  openDate.setHours(endDate.getHours() - openInterval);
  ///////
  if (isWeekend) {
    const daysUntilNextMonday = (1 + 7 - currentDay) % 7;
    openDate = new Date(now);
    openDate.setUTCDate(now.getUTCDate() + daysUntilNextMonday);
    openDate.setUTCHours(1, 0, 0, 0);
  }
  ///////
  const diff = Math.max(0, Math.floor(openDate - now) / 1000);
  const cursor = ((now - startDate) / 86400000) * 100;
  /////////
  var time = diff;
  const d = Math.floor(time / 86400);
  time = time % 86400;

  const h = Math.floor(time / 3600);
  time = time % 3600;

  const m = Math.floor(time / 60);
  const s = Math.floor(time % 60);

  var timerStr = "";
  if (d > 0) {
    timerStr +=
      d.toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }) + ":";
  }
  if (h > 0) {
    timerStr +=
      h.toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }) + ":";
  }
  timerStr +=
    m.toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    }) + ":";

  timerStr += s.toLocaleString("en-US", {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });
  /////

  return (
    <div
      className={classnames(styles.container, styles.zoomin, styles.shadowPrev)}
      style={
        props.sliderbg
          ? { backgroundColor: props.sliderbg }
          : { backgroundColor: props?.style?.slideBackgroundColor }
      }
    >
      <div className={classnames(styles.row1)}>
        <i className={classnames(styles.clock, "fas fa-pause")} />

        <div
          className={classnames(
            styles.col1,
            "font_24",
            "font_5_s",
            "font_26_m"
          )}
        >
          <div className={styles.live}>Paused</div>
        </div>
        <div
          className={classnames(styles.col2, "font_5", "font_5_s", "font_26_m")}
        >
          <div className={styles.three}>#{currentRoundNo}</div>
        </div>
        <div className={styles.col3}>
          <TokenIcon
            onClick={undefined}
            className={styles.icon1_prev}
            tokenName={currenctGroup?.token}
          />
        </div>
      </div>
      <div className={styles.row}>
        <DirectionArrow
          direction="up"
          text={roundData?.payoutUp ? "X" + roundData?.payoutUp : ""}
          color="green"
        />
        <div className={styles.content}>
          <div className={styles.cr1}>
            <div className={styles.t1}>Market Paused</div>
            <span data-tip="This market is paused due to low volatility during<br/> weekends and weekdays from  20:00 to 01:00 UTC">
              <InfoIcon className={styles.info} />
            </span>
          </div>
          {isWeekend ? (
            <>
              <div className={styles.cr3}>
                <div className={styles.t1}>Paused on weekends</div>
              </div>
            </>
          ) : (
            <>
              <div className={styles.cr2}>
                <span className={styles.t2}>Close</span>
                <span className={styles.t2}>Open</span>
              </div>
              <div className={styles.par}>
                <div className={styles.pr}>
                  <div
                    className={styles.close}
                    style={{ width: closePer + "%" }}
                  />
                </div>
                <CursorIcon
                  className={styles.cursor}
                  style={{ marginLeft: cursor + "%" }}
                />
              </div>
            </>
          )}
          <div className={styles.cr3}>
            <span className={styles.t2}> Opens in: {timerStr}</span>
          </div>
        </div>
        <DirectionArrow
          direction="down"
          text={roundData?.payoutDown ? "X" + roundData?.payoutDown : ""}
          color="red"
        />
      </div>
    </div>
  );
};
export default PauseSlide;
