import ENUMS from "./enums";
const Network = ENUMS.Network;
const API = ENUMS.API;

export var groupData = {
  BTC: {
    predictionId: "0",
    minBetAmount: 0.001,
    tradingviewSymbol: "BINANCE:BTCUSDT",
    network: Network.BSC,
    address: "0x00199E444155f6a06d74CF36315419d39b874f5c",
    showDecimal: 2,
    intervalSeconds: 300,
    bettingToken: "BNB",
    apiType: API.BINANCE,
    token: "BTC",
    apicode: "BTCUSDT",
    nativeBet: true,
    currencySymbol: "$",
  },
  BNB: {
    predictionId: "3",
    minBetAmount: 0.001,
    tradingviewSymbol: "BINANCE:BNBUSDT",
    network: Network.BSC,
    address: "0x00199E444155f6a06d74CF36315419d39b874f5c",
    showDecimal: 2,
    intervalSeconds: 300,
    bettingToken: "BNB",
    apiType: API.BINANCE,
    token: "BNB",
    apicode: "BNBUSDT",
    nativeBet: true,
    currencySymbol: "$",
  },
  ETH: {
    predictionId: "1",
    minBetAmount: 0.001,
    tradingviewSymbol: "BINANCE:ETHUSDT",
    network: Network.BSC,
    address: "0x00199E444155f6a06d74CF36315419d39b874f5c",
    showDecimal: 2,
    intervalSeconds: 300,
    bettingToken: "BNB",
    apiType: API.BINANCE,
    token: "ETH",
    apicode: "ETHUSDT",
    nativeBet: true,
    currencySymbol: "$",
  },
  JPY: {
    predictionId: "20",
    minBetAmount: 0.001,
    tradingviewSymbol: "FX_IDC:USDJPY",
    network: Network.BSC,
    address: "0x00199E444155f6a06d74CF36315419d39b874f5c",
    showDecimal: 3,
    intervalSeconds: 300,
    bettingToken: "BNB",
    apiType: API.FOREX,
    token: "JPY",
    apicode: "USD/JPY",
    isForex: true,
    nativeBet: true,
    tradingHours: {
      days: [1, 2, 3, 4, 5],
      startHour: 1,
      endHour: 20,
    },
    currencySymbol: "¥",
  },
  BTCPOLY: {
    predictionId: "0",
    minBetAmount: 0.1,
    tradingviewSymbol: "BINANCE:BTCUSDT",
    network: Network.POLYGON,
    address: "0x9f9564BE7b566dfE4B091a83a591752102aF3F33",
    showDecimal: 2,
    intervalSeconds: 300,
    bettingToken: "MATIC",
    apiType: API.BINANCE,
    token: "BTC",
    apicode: "BTCUSDT",
    nativeBet: true,
    currencySymbol: "$",
  },
  ETHPOLYn: {
    predictionId: "1",
    minBetAmount: 0.1,
    tradingviewSymbol: "BINANCE:ETHUSDT",
    network: Network.POLYGON,
    address: "0x9f9564BE7b566dfE4B091a83a591752102aF3F33",
    showDecimal: 2,
    intervalSeconds: 300,
    bettingToken: "MATIC",
    apiType: API.BINANCE,
    token: "ETH",
    apicode: "ETHUSDT",
    nativeBet: true,
    currencySymbol: "$",
  },
  MATICPOLYn: {
    predictionId: "2",
    minBetAmount: 0.1,
    tradingviewSymbol: "BINANCE:MATICUSDT",
    network: Network.POLYGON,
    address: "0x9f9564BE7b566dfE4B091a83a591752102aF3F33",
    showDecimal: 4,
    intervalSeconds: 300,
    bettingToken: "MATIC",
    apiType: API.BINANCE,
    token: "MATIC",
    apicode: "MATICUSDT",
    nativeBet: true,
    currencySymbol: "$",
  },
};

export const oldGroupData = [
  {
    predictionId: 0,
    minBetAmount: 0.1,
    tradingviewSymbol: "BINANCE:BTCUSDT",
    tokenCode: "BTCm",
    network: Network.POLYGON,
    address: "0xd71b0366CD2f2E90dd1F80A1F0EA540F73Ac0EF6",
    showDecimal: 2,
    intervalSeconds: 300,
    bettingToken: "MATIC",
    apiType: API.BINANCE,
    token: "BTC",
    apicode: "BTCUSDT",
    isStock: false,
    nativeBet: true,
    classic: true,
    currencySymbol: "$",
  },
  {
    predictionId: "0",
    minBetAmount: 0.01,
    tradingviewSymbol: "BINANCE:BTCUSDT",
    tokenCode: "BTC",
    network: Network.BSC,
    address: "0x3Df33217F0f82c99fF3ff448512F22cEf39CC208",
    showDecimal: 2,
    intervalSeconds: 300,
    bettingToken: "BNB",
    apiType: API.BINANCE,
    token: "BTC",
    apicode: "BTCUSDT",
    isStock: false,
    nativeBet: true,
    classic: true,
    currencySymbol: "$",
  },
];
if (process.env.REACT_APP_TEST == "true") {
  groupData = {
    BTC: {
      minBetAmount: 0.01,
      tradingviewSymbol: "BINANCE:BTCUSDT",
      tokenCode: "BTC",
      network: Network.BSC_TESTNET,
      address: "0x3Df33217F0f82c99fF3ff448512F22cEf39CC208",
      showDecimal: 2,
      intervalSeconds: 100,
      bettingToken: "BNB",
      apiType: API.BINANCE,
      token: "BTC",
      apicode: "BTCUSDT",
      isStock: false,
      nativeBet: true,
    },
    ETHPOLY: {
      predictionId: "1",
      minBetAmount: 0.1,
      tradingviewSymbol: "BINANCE:ETHUSDT",
      network: Network.POLYGON_TESTNET,
      address: "0x7975b9BfaE064E3f3e447ED5815B098e984205ff",
      showDecimal: 2,
      intervalSeconds: 100,
      bettingToken: "MATIC",
      apiType: API.BINANCE,
      token: "ETH",
      apicode: "ETHUSDT",
      nativeBet: true,
    },
    JPY: {
      predictionId: "9999", //
      network: Network.POLYGON_TESTNET, //
      token: "JPY", //
      bettingToken: "MATIC", //
      minBetAmount: 0.1, //
      address: "0x7975b9BfaE064E3f3e447ED5815B098e984205ff", //?
      showDecimal: 5, //
      intervalSeconds: 100,
      apiType: API.BINANCE,
      apicode: "JPYUSDT",
      apiTokenName: "USD/JPY",
      isStock: false,
      nativeBet: true,
    },
    //"cTESLA" : { "ids": [1000], "noFactory":false, "tokenCode":"Teslaf", "network":0, "address":"0xE8cBE746f2e68EAa91608ae1d93232B26fFd7DA6", "showDecimal":2, "intervalSeconds":1800, "bettingToken":"BNB", "candlestickApi":null, "apiType": API.FTX, "token":"TSLA", "apicode":"TSLA", "isStock":true, "nativeBet": true },
    //"XVS" : { "ids": [1001], "noFactory":false, "tokenCode":"XVSf", "network":0, "address":"0x32427E1b13373bDDf9dBcB0A4070Ac42Fb94c563", "showDecimal":3, "intervalSeconds":900, "bettingToken":"BNB", "apiType": API.BINANCE, "token":"XVS", "apicode":"XVSUSDT", "isStock":false, "nativeBet": true },
    //"BTC": { "minBetAmount": 0.01, "tradingviewSymbol": "KUCOIN:BNBUSDT", "tokenCode": "BNB", "network": Network.BSC_TESTNET, "address": "0x07cE71753A69fFA1118100b4D7CfBb1450D1E6Bb", "showDecimal": 3, "intervalSeconds": 300, "bettingToken": "BNB", "apiType": API.KUCOIN, "token": "BNB", "apicode": "BNB-USDT", "isStock": false, "nativeBet": true },
    //"ETH": { "minBetAmount": 0.01, "tradingviewSymbol": "KUCOIN:ETHUSDT", "tokenCode": "ETH", "network": Network.BSC_TESTNET, "address": "0x01485B7B79DCE29E6531F6c17b5A9652eB2C819f", "showDecimal": 3, "intervalSeconds": 300, "bettingToken": "BNB", "apiType": API.BINANCE, "token": "ETH", "apicode": "ETHUSDT", "isStock": false, "nativeBet": true },
    //"MATIC": { "minBetAmount": 0.01, "tradingviewSymbol": "KUCOIN:MATICUSDT", "tokenCode": "Matic", "network": Network.POLYGON_TESTNET, "address": "0xc25922DAB8a2f14DddE0D094280691bE0f665D7F", "showDecimal": 4, "intervalSeconds": 300, "bettingToken": "MATIC", "apiType": API.KUCOIN, "token": "MATIC", "apicode": "MATIC-USDT", "isStock": false, "nativeBet": true },
    //"TESLA": { "minBetAmount": 0.01, "tradingviewSymbol": "FTX:TSLAUSD", "tokenCode": "Tesla", "network": Network.POLYGON_TESTNET, "address": "0x1b3fcDf7199F6F0515f4A2221f0b4762a0B51771", "showDecimal": 3, "intervalSeconds": 300, "bettingToken": "MATIC", "apiType": API.FTX, "token": "TSLA", "apicode": "TSLA", "isStock": true, "nativeBet": true },
    //"BTCTRON": { "minBetAmount": 0.01, "tradingviewSymbol": "BINANCE:TRXUSDT", "tokenCode": "TRX", "blockchain": "TRON", "network": Network.TRON_TESTNET, "address": "TYLzGMuVpEcQzjJuoHwFL8sax9DowzPBz4", "showDecimal": 2, "intervalSeconds": 300, "bettingToken": "TRX", "apiType": API.BINANCE, "token": "BTC", "apicode": "BTCUSDT", "isStock": false, "nativeBet": true },
  };
}

export const loadGroupData = async () => {
  return groupData;
};

export const getGroupData = () => {
  return groupData;
};
