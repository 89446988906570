import styles from "./Modal.module.css";
import {
  connectMathWallet,
  connectTrustWallet,
  connectCoin98,
  connectMetamask,
  connectWalletConnect,
  tryReconnect,
  onDisconnect,
  connectBinanse,
} from "./connectioncode.js";
import { useDispatch, useSelector } from "react-redux";
import React from "react";
import meta from "../assets/metamask-2728406-2261817.png";
import wallet from "../assets/1024x1024bb.png";
import coin from "../assets/logo1.png";
import binance from "../assets/favicon.png";
import trust from "../assets/trust-wallet-token.png";
import math from "../assets/Logo_Icon_black.png";
import classnames from "classnames";
import view1 from "../assets/view1.png";
import view2 from "../assets/view2.png";
import { isMobile } from "react-device-detect";
import { useDisconnect, useConnect } from "wagmi";
import { getChainByInternalID } from "../wagmi/chains";
import {
  bscConnector,
  coinbaseConnector,
  injectedConnector,
  metaMaskConnector,
  walletConnectConnector,
} from "../wagmi/wagmi";

function Modal(props) {
  const { disconnect } = useDisconnect();
  const network = useSelector((state) => state.network);

  const dispatch = useDispatch();

  const { connect, connectors, error, isLoading, pendingConnector } =
    useConnect({
      onSuccess(data, variables, context) {
        //closeModal();
        props.setShow(false);
      },
    });

  //////////
  const redirectTo = (url) => {
    const win = window?.open(url, "_self");
    win?.focus();
    //closeModal();
    props.setShow(false);
  };
  //////////
  const chainId = getChainByInternalID(network)?.id;

  const onConnect = (walletName) => {
    if (isMobile && !window.ethereum) {
      if (walletName == "Metamask") {
        const url =
          "https://metamask.app.link/dapp/" +
          window?.location?.host +
          window?.location?.pathname +
          window?.location?.search;
        redirectTo(url);
      } else if (walletName == "TrustWallet") {
        const url =
          "https://link.trustwallet.com/open_url?coin_id=966&url=" +
          window?.location?.href;
        redirectTo(url);
      } else {
        connect({ connector: walletConnectConnector, chainId: chainId });
      }
    } else {
      switch (walletName) {
        case "WalletConnect":
          connect({ connector: walletConnectConnector, chainId: chainId });
          break;
        case "Binance":
          connect({ connector: bscConnector, chainId: chainId });
          break;
        case "Coin98":
          connect({ connector: injectedConnector, chainId: chainId });
          break;
        case "TrustWallet":
          connect({ connector: injectedConnector, chainId: chainId });
          break;
        case "Metamask":
          connect({ connector: metaMaskConnector, chainId: chainId });
          break;
        case "Injected":
          connect({ connector: injectedConnector, chainId: chainId });
          break;
        case "MathWallet":
          connect({ connector: injectedConnector, chainId: chainId });
          break;
        default:
          connect({ connector: walletConnectConnector, chainId: chainId });
          break;
      }
    }
  };

  var headerStr = "Connect to a wallet";
  var state1 = null;
  var state2 = styles.hide;
  if (props.userAddress != null) {
    headerStr = "Your Wallet";
    state1 = styles.hide;
    state2 = null;
  }

  return (
    <div
      className={styles.modal}
      onClick={(e) => {
        props.setShow(false);
      }}
    >
      <div
        className={styles.modalContent}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className={styles.header}>
          {headerStr}{" "}
          <button
            className={styles.close}
            onClick={() => {
              props.setShow(false);
            }}
          ></button>
        </div>
        <div className={classnames(styles.body, state1)}>
          <button
            className={styles.button}
            onClick={() => onConnect("Metamask")}
          >
            MetaMask<img src={meta} className={styles.icon}></img>
          </button>
          <button
            className={styles.button}
            onClick={() => onConnect("WalletConnect")}
          >
            WalletConnect <img src={wallet} className={styles.icon}></img>
          </button>
          <button
            className={styles.button}
            onClick={() => onConnect("Binance")}
          >
            Binance <img src={binance} className={styles.icon}></img>
          </button>
          <button className={styles.button} onClick={() => onConnect("Coin98")}>
            Coin98 <img src={coin} className={styles.icon}></img>
          </button>
          <button
            className={styles.button}
            onClick={() => onConnect("MathWallet")}
          >
            MathWallet <img src={math} className={styles.icon}></img>
          </button>
          <button
            className={styles.button}
            onClick={() => onConnect("TrustWallet")}
          >
            TrustWallet <img src={trust} className={styles.icon}></img>
          </button>
        </div>
        <div className={classnames(styles.body, state2)}>
          <div className={styles.userAddress}>{props.userAddress}</div>
          <div className={styles.view}>
            <div>
              <a
                href={"https://bscscan.com/address/" + props.userAddress}
                target="_blank"
              >
                View on BscScan<img src={view1} className={styles.view1}></img>
              </a>
            </div>
            <div
              onClick={() => {
                navigator.clipboard.writeText(props.userAddress);
              }}
            >
              Copy<img src={view2} className={styles.view2}></img>
            </div>
          </div>
          <div>
            <button
              className={styles.logout}
              onClick={() => {
                props.setShow(false);
                dispatch({ type: "web3Change", web3: null, userAddress: null });
                disconnect();
                onDisconnect();
              }}
            >
              Logout
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Modal;
