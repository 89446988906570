import React from "react";
import styles from "./calculating.module.css";
// import LoadingAnimation from "@/components/pro/loading/LoadingAnimation";
import classNames from "classnames";
import Loading from "../../Loading/Loading";
type Props = { isExpanded: boolean };

function Calculating({ isExpanded }: Props) {
  return (
    <div
      className={classNames(
        styles.calculating,
        isExpanded ? styles.expanded : styles.contracted
      )}
    >
      <Loading iconSize={25} loaderSize={60}/>
      <div className={styles.text}>Calculating</div>
    </div>
  );
}

export default Calculating;
