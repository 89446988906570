import React from "react";
import styles from "./PausedState.module.css";
type Props = {};

function PausedState({}: Props) {
  return (
    <div className={styles.paused}>
      Please come back later!
      <br />
      Prediction Market is Paused
    </div>
  );
}

export default PausedState;
