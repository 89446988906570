import ENUMS from "./constants/enums";
import { groupData } from "./constants/groupData";

const getDefaultTokenByNetworkId = (netId) =>
  groupData[
    Object.keys(groupData).find((key) => groupData[key].network == netId)
  ];

const getNetworkNameById = (network) => {
  if (network == ENUMS.Network.BSC || network == ENUMS.Network.BSC_TESTNET) {
    return "bsc";
  } else if (
    network == ENUMS.Network.POLYGON ||
    network == ENUMS.Network.POLYGON_TESTNET
  ) {
    return "poly";
  } else if (
    network == ENUMS.Network.TRON ||
    network == ENUMS.Network.TRON_TESTNET
  ) {
    return "tron";
  }
  return null;
};

const getGroupById = (id) =>
  id >= 0 ? groupData[Object.keys(groupData)[id]] : null;

const getPolyId = () =>
  process.env.REACT_APP_TEST == "true"
    ? ENUMS.Network.POLYGON_TESTNET
    : ENUMS.Network.POLYGON;

const getBscId = () =>
  process.env.REACT_APP_TEST == "true"
    ? ENUMS.Network.BSC_TESTNET
    : ENUMS.Network.BSC;

function getGroupIndex(groupName) {
  return Object.keys(groupData).findIndex((key) => key === groupName);
}
function getNetworkIndex(networkName) {
  if (
    networkName == ENUMS.Network.BSC ||
    networkName == ENUMS.Network.BSC_TESTNET
  )
    return 0;
  else if (
    networkName == ENUMS.Network.POLYGON ||
    networkName == ENUMS.Network.POLYGON_TESTNET
  )
    return 1;
  else if (
    networkName == ENUMS.Network.TRON ||
    networkName == ENUMS.Network.TRON_TESTNET
  )
    return 2;
  return -1;
}
function getNetworks() {
  return process.env.REACT_APP_TEST == "true"
    ? [
        { name: "BSC", id: ENUMS.Network.BSC_TESTNET },
        { name: "POLY", id: ENUMS.Network.POLYGON_TESTNET },
        { name: "TRON", id: ENUMS.Network.TRON_TESTNET },
      ]
    : [
        { name: "BSC", id: ENUMS.Network.BSC },
        { name: "POLY", id: ENUMS.Network.POLYGON },
        { name: "TRON", id: ENUMS.Network.TRON },
      ];
}

const groups = Object.keys(groupData).map((key, index) => ({
  _key: key,
  _id: index,
  ...groupData[key],
}));
const getGroupsByNetwork = (network) =>
  groups.filter((d) => d.network == network);

const getGroupsByKey = (key) => groups.find((d) => d._key == key);

export {
  getGroupsByKey,
  getGroupsByNetwork,
  getNetworkIndex,
  getNetworks,
  getGroupIndex,
  getPolyId,
  getBscId,
  getGroupById,
  getNetworkNameById,
  getDefaultTokenByNetworkId,
};
