import { useSelector } from "react-redux";
import Header from "./Header";
import styles from "./PastRounds.module.css";
import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import PastRoundData from "../PastRoundData";

type Props = { data: any };

const slidePerView = 1.65;
const spaceBetween = 10;

function PastRounds({ data }: Props) {
  const [open, setOpen] = useState(false);
  const [isEnd, setIsEnd] = useState(false);

  const list:any = data?.sort((a: any, b: any) => b.roundData.roundNo - a.roundData.roundNo) ?? [];;

  const toggleOpen = () => {
    setOpen((prev) => !prev);
  };

  const currentGroup = useSelector((state:any) => state.currentGroup);
  // const list = roundData?.sort((a: any, b: any) => b.roundNo - a.roundNo) ?? [];
  const decimal = currentGroup?.showDecimal ?? 2;

  return (
    <div className={styles.pastRounds}>
      <Header toggleOpen={toggleOpen} open={open} list={list} />

      {open && (
        <div className={styles.swiperWrapper}>
          <Swiper
            spaceBetween={spaceBetween}
            slidesPerView={slidePerView}
            onSlideChange={(index) => {
              if (index?.isEnd) {
                setIsEnd(true);
              } else {
                setIsEnd(false);
              }
            }}
            breakpoints={{
              1500:{
                slidesPerView:2.75,
                spaceBetween:20
              },
              768:{
                slidesPerView:2.185,
                spaceBetween:15
              },


            }}
            onSwiper={(swiper) => console.log(swiper)}
          >
            {list.map((_roundData: any, ix: number) => (
              <SwiperSlide key={ix}>
                <PastRoundData roundData={_roundData} decimal={decimal} />
              </SwiperSlide>
            ))}
          </Swiper>
          {!isEnd && <div className={styles.bgGradient} />}
        </div>
      )}
    </div>
  );
}

export default PastRounds;
