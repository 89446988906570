//@ts-nocheck
import { Chain } from "wagmi";

export interface ChainWithInternalID extends Chain {
  internalId: number;
}

export const bsc: ChainWithInternalID = {
  id: 56,
  internalId: 0,
  name: "BNB Smart Chain",
  network: "bsc",
  rpcUrls: {
    public: "https://bsc-dataseed3.defibit.io",
    default: "https://bsc-dataseed3.defibit.io",
  },
  blockExplorers: {
    default: { name: "BscScan", url: "https://bscscan.com" },
    etherscan: { name: "BscScan", url: "https://bscscan.com" },
  },
  nativeCurrency: {
    name: "Binance Chain Native Token",
    symbol: "BNB",
    decimals: 18,
  },
  multicall: {
    address: "0xcA11bde05977b3631167028862bE2a173976CA11",
    blockCreated: 15921452,
  },
};

export const bscTest: ChainWithInternalID = {
  id: 97,
  internalId: 10,
  name: "BNB Smart Chain Testnet",
  network: "bsc-testnet",
  nativeCurrency: {
    decimals: 18,
    name: "Binance Chain Native Token",
    symbol: "tBNB",
  },
  rpcUrls: {
    public: "https://data-seed-prebsc-1-s2.binance.org:8545/",
    default: "https://data-seed-prebsc-1-s2.binance.org:8545/",
  },
  blockExplorers: {
    default: { name: "BscScan", url: "https://testnet.bscscan.com" },
  },
  multicall: {
    address: "0xcA11bde05977b3631167028862bE2a173976CA11",
    blockCreated: 17422483,
  },
  testnet: true,
};

export const polygon: ChainWithInternalID = {
  id: 137,
  internalId: 1,
  name: "Polygon Mainnet",
  network: "polygon",
  rpcUrls: {
    public: "https://polygon-rpc.com/",
    default: "https://polygon-rpc.com/",
  },
  blockExplorers: {
    default: { name: "PolygonScan", url: "https://polygonscan.com" },
    etherscan: { name: "PolygonScan", url: "https://polygonscan.com" },
  },
  nativeCurrency: {
    name: "MATIC",
    symbol: "MATIC",
    decimals: 18,
  },
  multicall: {
    address: "0xcA11bde05977b3631167028862bE2a173976CA11",
    blockCreated: 15921452,
  },
};

export const mumbai: ChainWithInternalID = {
  id: 80001,
  internalId: 11,
  name: "Polygon Mumbai Testnet",
  network: "mumbai",
  rpcUrls: {
    public: "https://matic-mumbai.chainstacklabs.com",
    default: "https://matic-mumbai.chainstacklabs.com",
  },
  blockExplorers: {
    default: {
      name: "MumbaiPolygonScan",
      url: "https://mumbai.polygonscan.com",
    },
    etherscan: {
      name: "MumbaiPolygonScan",
      url: "https://mumbai.polygonscan.com",
    },
  },
  nativeCurrency: {
    name: "MATIC",
    symbol: "MATIC",
    decimals: 18,
  },
  multicall: {
    address: "0xcA11bde05977b3631167028862bE2a173976CA11",
    blockCreated: 15921452,
  },
};

export const chains = [bsc, bscTest, polygon, mumbai];

export const getChainByInternalID = (id: number) => {
  return chains.find((c) => c.internalId === id);
};
