import erc20ABI from "../utils/constants/ABI/erc20.json";
import tokenContracts from "../utils/constants/tokenContracts";
import useExecuteContract from "./useExecuteContract.js";
import useGroup from "./useGroup.js";
import { useAccount } from "wagmi";

const useERC20 = () => {
  const { address: userAddress } = useAccount();
  const { getGroup, getTargetGroup } = useGroup();
  const { callContractWagmi, sendContractWagmi } = useExecuteContract();

  const approvePrediction = async (groupName) => {
    const group = getTargetGroup(groupName);
    const bettingTokenAddress =
      tokenContracts[group.network][group.bettingToken];
    if (!bettingTokenAddress)
      return { status: false, message: "no betting token", txlink: "" };

    const result = await sendContractWagmi({
      address: bettingTokenAddress,
      abi: erc20ABI,
      functionName: "approve",
      args: [
        group.address,
        "90000000000000000000000000000000000000000000000000000000000000000000000",
      ],
    });

    return result;
  };

  const isApprovedByConnection = async (w3, address) => {
    const group = getGroup();
    if (!group.nativeBet) {
      const bettingTokenAddress =
        tokenContracts[group.network][group.bettingToken];
      if (!bettingTokenAddress)
        return { status: false, message: "no betting token", txlink: "" };

      const [err, result] = await callContractWagmi({
        address: bettingTokenAddress,
        abi: erc20ABI,
        functionName: "allowance",
        args: [userAddress, group.address],
        network: group.network,
      });
      if (err) return false;

      return result > "1000000000000000000000000";
    }

    return { status: true, message: "native token", txlink: "" };
  };

  const isApproved = async () => {
    const group = getGroup();
    if (!group.nativeBet) {
      const bettingTokenAddress =
        tokenContracts[group.network][group.bettingToken];
      if (!bettingTokenAddress)
        return { status: false, message: "no betting token", txlink: "" };

      const [err, result] = await callContractWagmi({
        address: bettingTokenAddress,
        abi: erc20ABI,
        functionName: "allowance",
        args: [userAddress, group.address],
        network: group.network,
      });
      if (err) return false;

      return result > "1000000000000000000000000";
    }

    return { status: true, message: "native token", txlink: "" };
  };

  return { approvePrediction, isApproved, isApprovedByConnection };
};

export default useERC20;
