import React from "react";
import styles from "./header.module.css";
import {ReactComponent as ClockSVG} from "../../../../assets/dashboard/clock.svg";
import classNames from "classnames";
import classnames from "classnames";
type Props = { toggleOpen: () => void; open: boolean; list: any };
const ArrowDownSVG = () => {
  return (
    <svg
      width="15"
      height="10"
      viewBox="0 0 15 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.8"
        d="M0.500069 2.25844C0.499561 2.45056 0.53253 2.64038 0.596553 2.81395C0.660575 2.98753 0.754025 3.14045 0.870034 3.26148L6.86947 9.4726C7.04838 9.66174 7.2728 9.76514 7.50441 9.76514C7.73601 9.76514 7.96043 9.66174 8.13935 9.4726L14.1388 3.04287C14.343 2.82459 14.4714 2.51094 14.4958 2.1709C14.5201 1.83086 14.4385 1.4923 14.2688 1.22969C14.099 0.967073 13.8552 0.801929 13.5908 0.770577C13.3264 0.739226 13.0631 0.844237 12.8589 1.06251L7.49941 6.81069L2.13991 1.25541C1.99314 1.09816 1.81442 0.998267 1.62489 0.96756C1.43536 0.936852 1.24295 0.976609 1.07044 1.08213C0.897928 1.18765 0.752531 1.35451 0.651451 1.56298C0.55037 1.77144 0.497837 2.01278 0.500069 2.25844Z"
        fill="#444444"
      />
    </svg>
  );
};

const PastArrow = () => {
  return (
    <svg
      width="11"
      height="18"
      viewBox="0 0 11 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.8"
        d="M9.17485 8.7738e-05C8.94004 -0.000564575 8.70804 0.0418224 8.49589 0.124138C8.28374 0.206453 8.09684 0.326603 7.94891 0.475758L0.357546 8.18931C0.126375 8.41935 0 8.70789 0 9.00567C0 9.30344 0.126375 9.59198 0.357546 9.82202L8.2161 17.5356C8.48288 17.7981 8.86624 17.9632 9.28184 17.9946C9.69744 18.0259 10.1112 17.9209 10.4322 17.7027C10.7532 17.4845 10.955 17.1709 10.9933 16.831C11.0317 16.491 10.9033 16.1526 10.6365 15.89L3.61099 8.99924L10.4008 2.10846C10.593 1.91975 10.7151 1.68997 10.7526 1.44628C10.7901 1.2026 10.7415 0.955221 10.6126 0.733421C10.4836 0.511621 10.2797 0.324682 10.0249 0.194721C9.77007 0.0647602 9.4751 -0.00278091 9.17485 8.7738e-05Z"
        fill="#444444"
      />
    </svg>
  );
};

function Header({ toggleOpen, open, list }: Props) {

  return (
    <div className={styles.header} onClick={toggleOpen}>
      <div className={styles.left}>
        <span className={styles.clockSvg}>
          <ClockSVG />
        </span>
        <span>Past Rounds</span>
      </div>
      <div className={styles.right}>
        <div className={styles.rounds}>
          {list && list.map((roundData: any, ix: number) => {
            
            return (
              <div  key={ix} 
                className={ classnames
                  (styles.circle, roundData?.roundState == -1 && styles.red,
                  roundData?.roundState == 0 && styles.gray,
                  roundData?.roundState == 1 && styles.green)
                }
              />
            );
          })}
          <div
          className={classNames(styles.arrowbtn, open && styles.flip)}
          
        >
          <ArrowDownSVG />
        </div>
        </div>
        
      </div>
    </div>
  );
}

const Circle = ({ className }: { className: String }) => {
  return <div className={classNames(styles.circle, className)} />;
};

export default Header;
