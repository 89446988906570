import { useSelector } from 'react-redux'
import useGroup from './useGroup'
import useSunSwap from './useSunSwap'
import ENUMS from '../utils/constants/enums'
import useUniswap from './useUniswap'
import swapTokens from '../utils/constants/swapTokens'
import BigNumber from 'bignumber.js'
import { useAccount } from 'wagmi'
import useStats from './useStats'
const Network = ENUMS.Network

const nativeTokens = {
  0: 'BNB',
  10: 'BNB',
  1: 'MATIC',
  11: 'MATIC',
  2: 'TRX',
  12: 'TRX'
}

const useExchange = () => {
  const { getGroup } = useGroup()
  const { address: userAddress } = useAccount()
  const currentNetwork = useSelector((state) => state.network)
  const { getUserNativeTokenBalance } = useStats()
  const {
    getTokenBalance__sunswap,
    getUserTokens__sunswap,
    isRouterApproved__sunswap,
    approveRouter__sunswap,
    swapToken__sunswap,
    getAmountOut__sunswap
  } = useSunSwap()
  const {
    getTokenBalance__uniswap,
    getUserTokens__uniswap,
    isRouterApproved__uniswap,
    approveRouter__uniswap,
    swapToken__uniswap,
    getAmountOut__uniswap
  } = useUniswap()

  const getNativeToken = () => {
    const groupData = getGroup()
    return nativeTokens[groupData.network]
  }

  const getNativeBalance = async () => {
    const balance = await getUserNativeTokenBalance()
    return balance
  }

  const getUserTokensByCurrentNetwork = async () => {
    if (!userAddress || currentNetwork === -1) return []
    let chain
    if (currentNetwork === Network.BSC) chain = 'bsc'
    else if (currentNetwork === Network.POLYGON) chain = 'polygon'
    else if (currentNetwork === Network.POLYGON_TESTNET) chain = 'mumbai'
    else if (currentNetwork === Network.BSC_TESTNET) chain = 'bsc testnet'
    else return []

    const tokens = swapTokens[currentNetwork]
    const tokenNames = Object.keys(tokens)

    const data = await fetch(
      `https://deep-index.moralis.io/api/v2/${userAddress}/erc20?chain=${chain}`,
      {
        headers: {
          Accept: 'application/json',
          'X-Api-Key':
            'UXE7S6HewaxJa7TcR1nRFU7aLJVqxw2wv96avRLKqoSdn57ses5Otdu7ddLyg11h'
        }
      }
    )

    let result = await data.json()

    const nativeBalance = await getNativeBalance()

    const userBalance = tokenNames.reduce((acc, cur) => {
      const token = result.find((r) => r.symbol === cur)
      let balance = 0
      if (cur === 'MATIC' || cur === 'BNB') balance = nativeBalance
      else
        balance = token
          ? BigNumber(token.balance)
              .dividedBy(10 ** token.decimals)
              .toFixed()
          : 0
      acc.push({ symbol: cur, balance })
      return acc
    }, [])

    return userBalance
  }

  const getUserTokens = async () => {
    if (!userAddress) return []
    const groupData = getGroup()

    if (groupData.network === Network.TRON)
      return await getUserTokens__sunswap()
    return await getUserTokens__uniswap()
  }

  const getAmountOut = async (amountIn, tokenInAddr) => {
    if (!amountIn || amountIn == 0) return 0
    const groupData = getGroup()

    if (groupData.network === Network.TRON)
      return await getAmountOut__sunswap(amountIn, tokenInAddr)
    return await getAmountOut__uniswap(amountIn, tokenInAddr)
  }

  const swapToken = async (amountIn, amountOut, tokenInAddr) => {
    const groupData = getGroup()
    let result
    if (groupData.network === Network.TRON)
      result = await swapToken__sunswap(amountIn, amountOut, tokenInAddr)
    else result = await swapToken__uniswap(amountIn, amountOut, tokenInAddr)
    return result
  }

  const approveRouter = async (tokenInAddr) => {
    const groupData = getGroup()
    let result
    if (groupData.network === Network.TRON)
      result = await approveRouter__sunswap(tokenInAddr)
    else result = await approveRouter__uniswap(tokenInAddr)
    return result
  }

  const isRouterApproved = async (tokenInAddr) => {
    if (!userAddress) return false
    const groupData = getGroup()
    if (groupData.network === Network.TRON)
      return await isRouterApproved__sunswap(tokenInAddr)
    return await isRouterApproved__uniswap(tokenInAddr)
  }

  const getTokenBalance = async (tokenInAddr) => {
    if (!userAddress) return 0
    const groupData = getGroup()
    if (groupData.network === Network.TRON)
      return await getTokenBalance__sunswap(tokenInAddr)
    return await getTokenBalance__uniswap(tokenInAddr)
  }

  return {
    getTokenBalance,
    getNativeToken,
    getUserTokens,
    getUserTokensByCurrentNetwork,
    isRouterApproved,
    approveRouter,
    swapToken,
    getAmountOut
  }
}

export default useExchange
