import React, { useState } from "react";
import styles from "./PastRoundData.module.css";
import classNames from "classnames";
import {ReactComponent as EqualDark} from "../../../assets/dashboard/equalDark.svg";
import { useDispatch, useSelector } from "react-redux";

import {ReactComponent as TriangleSVG} from "../../../assets/dashboard/triangle.svg";
import usePrediction from "../../../core/hooks/usePrediction";
type Props = { roundData: any; decimal: number };


function numberWithCommas(x:any) {
  if (x && x != "") {
    if (x.toString().split(".").length == 2)
      return (
        x
          .toString()
          .split(".")[0]
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
        "." +
        x.toString().split(".")[1]
      );
    else return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else return null;
}

function PastRoundData({ roundData, decimal }: Props) {
  let diff: any;

  let isEntered = false;
  let lockedPrice;
  let pool;
  let closePrice;
  let position;


  console.log("we get the round data as ", roundData)

  //  ////////////////////////
  const dispatch = useDispatch();
  const { claim } = usePrediction();
  const paused = useSelector((state:any) => state.paused);
  const num = useSelector((state:any) => state.num);
  const currentGroup = useSelector((state:any) => state.currentGroup);
  const collected = useSelector((state:any) => state.onCollect);
  //  //////////////////////////

  const [claiming, setClaiming] = useState(0);

  // const { updateRound } = useRound();

  const onClaim = () => {
    setClaiming(1);
    claim([roundData?.roundData.roundNo]).then((result: any) => {
      dispatch({ type: "setOnCollect", onCollect: "Collecting" });
      if (result.status == true) {
        setClaiming(2);
      } else {
        setClaiming(0);
      }
    });
  };
  let _data:any = roundData?.roundData
  if (roundData) {
   
    lockedPrice = numberWithCommas(
      Number(_data?.lockPrice).toFixed(decimal)
    );
    closePrice = numberWithCommas(
      Number(_data?.closePrice).toFixed(decimal)
    );
    pool = numberWithCommas(_data.totalAmount);

    diff = _data.closePrice - _data.lockPrice;
    diff = Number(diff).toFixed(decimal);

    if (_data.userBet && _data.userBet.originalAmount > 0) {
      isEntered = true;
      position = _data.userBet.position;
    }
  }

  return (
    <div className={styles.data}>
      <div className={styles.left}>
        <div className={styles.header}>
          <Circle
            className={classNames(
              diff > 0 ? styles.green : diff < 0 ? styles.red : ""
            )}
          />
          <div className={styles.roundId}>#{_data.roundNo}</div>
        </div>
        <div className={styles.dataGrid}>
          <div>
            <div className={styles.title}>Closed Price</div>
            <div className={classNames(styles.gridData, styles.bold)}>
              ${numberWithCommas(Number(_data.closePrice).toFixed(decimal))}
            </div>
          </div>
          <div className={!isEntered ? styles.justifyLeft : ""}>
            <div className={styles.title}>Strike Price</div>

            <div className={styles.gridData}>
              ${numberWithCommas(Number(_data.lockPrice).toFixed(decimal))}
            </div>
          </div>

          {isEntered ? (
            <>
              <div>
                <div className={styles.trend}>
                  {diff == 0 ? (
                      <EqualDark />
                  ) : (
                    <PricePredictionSmallArrow
                      className={
                        diff > 0
                          ? styles.won
                          : classNames(styles.lost, styles.downTriangle)
                      }
                    />
                  )}
                  <div className={styles.trendValue}>
                    ${Number(diff).toFixed(decimal)}
                  </div>
                </div>
                <div className={styles.button}>
                  {_data?.isClaimable?.status ? (
                    <button
                      className={classNames(
                        styles.primaryBtn,
                        (claiming == 1 || claiming == 2) && styles.disable
                      )}
                      disabled={claiming == 1}
                      onClick={onClaim}
                    >
                      {claiming == 1
                        ? "Collecting"
                        : claiming == 2
                        ? "Collected"
                        : "Claim"}
                    </button>
                  ) : _data?.userBet?.claimed == true ? (
                    <button
                      className={classNames(styles.primaryBtn, styles.disable)}
                    >
                      Collected
                    </button>
                  ) : (
                    <button className={styles.secondaryBtn}>Lost</button>
                  )}
                </div>
              </div>
              <div>
                <div className={styles.title}>Direction</div>

                <div className={styles.gridData}>
                  {diff > 0 ? "UP" : "DOWN"}
                </div>
              </div>
            </>
          ) : (
            <>
              <div className={styles.trend}>
                {diff != 0 ? (
                  <PricePredictionSmallArrow
                    className={
                      diff > 0
                        ? styles.won
                        : classNames(styles.lost, styles.downTriangle)
                    }
                  />
                ) : 
                  <EqualDark />}
                
                <div className={styles.trendValue}>${diff}</div>
              </div>
              <div></div>
              <div className={styles.poolTitle}>Prize Pool:</div>
              <div className={styles.poolData}>
                {numberWithCommas(_data?.totalAmount)} BNB
              </div>
            </>
          )}
        </div>
      </div>
      <div className={styles.right}>
        <div className={styles.rightTop}>
          <PricePredictionBigArrow
            className={diff > 0 ? styles.won : styles.neutral}
            direction="up"
          />
          <div className={styles.multiplier}>X{_data?.payoutUp}</div>
        </div>
        <div className={styles.rightBottom}>
          <PricePredictionBigArrow
            className={diff < 0 ? styles.lost : styles.neutral}
            direction="down"
          />
          <div className={styles.multiplier}>X{_data?.payoutDown}</div>
        </div>
      </div>
    </div>
  );
}

const Circle = ({ className }: { className: string }) => {
  return <div className={classNames(styles.circle, className)} />;
};

const PricePredictionSmallArrow = ({ className }: { className: string }) => {
  return <div className={classNames(styles.triangle, className)} />;
};

const PricePredictionBigArrow = ({
  className,
  direction,
}: {
  className: string;
  direction: "up" | "down";
}) => {
  return (
    <div
      className={classNames(
        styles.bigtriangle,
        className,
        direction === "up" ? styles.upTriangle : styles.downTriangle
      )}
    >
      <TriangleSVG />
    </div>
  );
};

export default PastRoundData;
