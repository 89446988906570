import { useHistory } from "../core";
import { oldGroupData } from "../core/utils/constants/groupData";
import { getGroupsByKey } from "../core/utils/networkUtils";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAccount } from "wagmi";

const SetupHistory = () => {
  const dispatch = useDispatch();
  const currentRoundNo = useSelector((state: any) => state.num);
  const { getUserHistory, getUserGroupHistory } = useHistory();
  const { address } = useAccount();
  const network = useSelector((state: any) => state.network);
  const networkRef = useRef(network);
  networkRef.current = network;

  const refreshUserHistory = useSelector(
    (state: any) => state.refreshUserHistory
  );
  ///////
  const refreshHistory = () => {
    if (address) {
      getUserHistory(true).then((res: any) => {
        if (res && network == networkRef.current) {
          dispatch({ type: "setUserHistory", userHistory: res });
        }
      });
    } else {
      dispatch({ type: "setUserHistory", userHistory: null });
    }
  };
  ///////
  useEffect(() => {
    dispatch({ type: "setUserHistory", userHistory: null });
    refreshHistory();
  }, [address, network]);
  ///////
  useEffect(() => {
    refreshHistory();
  }, [refreshUserHistory, currentRoundNo]);
  //////////////////////////////////
  //const [lastRefresh, setLastRefresh] = useState<number>(0);
  //const refLastRefresh = useRef(lastRefresh);
  //refLastRefresh.current = lastRefresh;
  const handleVisibilityChange = () => {
    //const diff = (Date.now() - refLastRefresh.current) / 1000;
    if (!document.hidden) {
      //setLastRefresh(Date.now());
      refreshHistory();
    }
  };
  useEffect(() => {
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);
  //////////////////////////////////
  useEffect(() => {
    /////////for merge old an new contract/////////////
    dispatch({ type: "setUserOldHistory", userOldHistory: null });
    const grp = oldGroupData.filter((d) => d.network == network)?.[0];
    if (grp) {
      getUserGroupHistory(grp).then((res: any) => {
        if (res && network == networkRef.current) {
          //data.groupData = groupData;
          res.rounds = res.rounds.map((d: any) => ({ ...d, groupData: grp }));
          const history: any = {};
          history[grp.predictionId] = res;

          dispatch({
            type: "setUserOldHistory",
            userOldHistory: history,
          });
        } else {
          dispatch({
            type: "setUserOldHistory",
            userOldHistory: {},
          });
        }
      });
    } else {
      dispatch({
        type: "setUserOldHistory",
        userOldHistory: {},
      });
    }
  }, [network]);
  return <></>;
};

export default SetupHistory;
