const ENUMS = {
    Network: {
        BSC: 0,
        BSC_TESTNET: 10,
        POLYGON: 1,
        POLYGON_TESTNET: 11,
        TRON: 2,
        TRON_TESTNET: 12
    },
    API: {
        KUCOIN: "KUCOIN",
        BINANCE: "BINANCE",
        FOREX: "FOREX",
        FTX: "FTX",
        HUOBI: "HUOBI",
    }
}

Object.freeze(ENUMS);

export default ENUMS;