const useFetch = () => {
  const fetchWithCatch = async (url, options = {}) => {
    try {
      const data = await fetch(url, options);
      const result = await data.json();
      return [null, result];
    } catch (err) {
      return [err, null];
    }
  };
  return { fetchWithCatch };
};

export default useFetch;
