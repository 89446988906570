import BigNumber from "bignumber.js";
import useExecuteContract from "./useExecuteContract";
import usePrice from "./usePrice";
import useGroup from "./useGroup";
import useFetch from "./useFetch";
import ENUMS from "../utils/constants/enums";
import classicABI from "../utils/constants/ABI/prediction.json";
import frontendHelperABI from "../utils/constants/ABI/frontendHelperABI.json";
import multipleABI from "../utils/constants/ABI/multiple.json";
import { useAccount } from "wagmi";
import { updateMessage, messageStatus } from "../utils/message";
import partners from "../utils/constants/partners";
import messages from "../utils/constants/messages";
import { useState } from "react";
import useClassic from "./useClassic";
import { utils } from "ethers";

const Network = ENUMS.Network;
const BIG_TEN = new BigNumber(10);
const treasuryCache = {};

const addressFrontendHelpers = {
  0: "0x69d04c52C510369377103564Ac07a09186FC4C3A",
  1: "0x77Ac66724067935dD379BBb4A15BacAa062498b2",
};

const usePrediction = () => {
  const { sendContractWagmi, callContractWagmi } = useExecuteContract();
  const { getCurrentPrice, getBettingTokenUSDPrice } = usePrice();
  const { getGroup, getTargetGroup, getPredictionIdsArray } = useGroup();
  const { fetchWithCatch } = useFetch();
  const { address } = useAccount();
  const {
    getMultiRoundData: getMultiRoundDataClassic,
    betBull: betBullClassic,
    betBear: betBearClassic,
    getRoundData: getRoundDataClassic,
    getUserBet: getUserBetClassic,
    claim: claimClassic,
    getUserRounds: getUserRoundsClassic,
    getUserRoundsForEpochs: getUserRoundsForEpochsClassic,
    hasStopped: hasStoppedClassic,
  } = useClassic();
  const [allRoundsData, setAllRoundsData] = useState({});

  const getGroupAddressAndABI = () => {
    const groupData = getGroup();

    if (!groupData) return null;

    if (groupData.classic) {
      return {
        address: groupData.address,
        abi: classicABI,
      };
    }
    return {
      address: groupData.address,
      abi: multipleABI,
    };
  };

  const getCurrentRoundNo = async () => {
    const groupData = getGroup();
    const [err, result] = await callContractWagmi({
      ...getGroupAddressAndABI(),
      functionName: "currentEpoch",
      network: getGroup().network,
    });

    if (err) return 0;
    return result.toString();
  };

  const getTreasuryRate = async (predictionId) => {
    if (treasuryCache[predictionId]) {
      return treasuryCache[predictionId];
    }

    const [err, result] = await callContractWagmi({
      ...getGroupAddressAndABI(),
      functionName: "treasuryRates",
      args: [predictionId.toString()],
      network: getGroup().network,
    });

    treasuryCache[predictionId] = result.toString();

    if (err) return 0;
    return result.toString();
  };

  const processRoundData = ({
    round,
    timestamp,
    betData,
    claimable,
    refundable,
    roundNumber,
    treasuryRate,
    predictionId,
  }) => {
    const result = { ...round, ...timestamp };

    const priceDecimal = 8;
    const decimal = getChainDecimal();

    result.totalAmount = new BigNumber(result.bearAmount.toString()).plus(
      result.bullAmount.toString()
    );

    result.lockPrice = new BigNumber(result.lockPrice.toString())
      .dividedBy(BIG_TEN.pow(priceDecimal))
      .toString();
    result.closePrice = new BigNumber(result.closePrice.toString())
      .dividedBy(BIG_TEN.pow(priceDecimal))
      .toString();

    result.userBet = { ...betData };
    let amountWon = 0;

    result.payoutUp = "2.00";
    result.payoutDown = "2.00";
    if (result.bearAmount.toString() != "0") {
      result.payoutDown = new BigNumber(result.totalAmount.toString())
        .dividedBy(result.bearAmount.toString())
        .toFixed(2);
    }
    if (result.bullAmount.toString() != "0") {
      result.payoutUp = new BigNumber(result.totalAmount.toString())
        .dividedBy(result.bullAmount.toString())
        .toFixed(2);
    }

    result.totalAmount = new BigNumber(result.bearAmount.toString())
      .plus(result.bullAmount.toString())
      .dividedBy(BIG_TEN.pow(decimal))
      .toFixed(2);

    result.userBet.originalAmount = 0;
    if (
      betData &&
      BigNumber(betData.amount.toString()).isGreaterThan(BigNumber("0"))
    ) {
      result.userBet.originalAmount = new BigNumber(
        betData.amount.toString()
      ).toString();
      result.userBet.amount = new BigNumber(betData.amount.toString())
        .dividedBy(BIG_TEN.pow(decimal))
        .toFixed(2);

      const rewardBaseCalAmount =
        parseFloat(result.closePrice) > parseFloat(result.lockPrice)
          ? round.bullAmount
          : round.bearAmount;

      const rewardAmount = BigNumber(result.totalAmount).minus(
        BigNumber(result.totalAmount).multipliedBy(treasuryRate).dividedBy(1000)
      );

      amountWon = new BigNumber(betData.amount.toString())
        .multipliedBy(rewardAmount)
        .dividedBy(BigNumber(rewardBaseCalAmount.toString()))
        .toFixed(10);
    }

    result.isClaimable = { status: claimable, amount: amountWon };
    result.isRefundable = refundable;

    return result;
  };

  const needCaptcha = async () => {
    if (!address) return false;
    const groupData = getGroup();
    const [err, captcha] = await callContractWagmi({
      address: addressFrontendHelpers[groupData.network],
      abi: frontendHelperABI,
      functionName: "captcha",
      args: [address],
      network: groupData.network,
    });
    if (err) {
      console.log(err);
      return true;
    }
    return captcha;
  };

  const getNumBets = async () => {
    if (!address) return 0;
    const groupData = getGroup();
    const allPredictions = getPredictionIdsArray();
    const [err, numBets] = await callContractWagmi({
      address: addressFrontendHelpers[groupData.network],
      abi: frontendHelperABI,
      functionName: "getNumBets",
      args: [address, allPredictions],
      network: groupData.network,
    });
    if (err) {
      console.log(err);
      return 0;
    }
    return parseInt(numBets.toString());
  };

  const getMultiRoundData = async (roundNo, _numRounds) => {
    const groupData = getGroup();
    if (groupData.classic) {
      return getMultiRoundDataClassic(roundNo, _numRounds);
    }
    const numRounds = roundNo > _numRounds ? _numRounds : roundNo;
    const addr = address || "0x0000000000000000000000000000000000000000";
    const allPredictions = getPredictionIdsArray();

    const [err, multipleData] = await callContractWagmi({
      address: addressFrontendHelpers[groupData.network],
      abi: frontendHelperABI,
      functionName: "getMultipleMultiRoundsForUser",
      args: [allPredictions, addr, roundNo - numRounds + 1, numRounds],
      network: groupData.network,
    });

    if (err) {
      console.log(err);
      return {};
    }

    const result = {};
    for (let j = 0; j < allPredictions.length; j++) {
      const predictionId = allPredictions[j];
      const predictionData = multipleData[j];
      result[predictionId] = {};
      //const treasuryRate = await getTreasuryRate(predictionId);
      for (let i = 0; i < numRounds; i++) {
        if (predictionData) {
          const roundNumber = roundNo - numRounds + 1 + i;
          result[predictionId][roundNumber] = processRoundData({
            round: predictionData[i].round,
            timestamp: predictionData[i].timestamp,
            betData: predictionData[i].betInfo,
            claimable: predictionData[i].claimable,
            refundable: predictionData[i].refundable,
            treasuryRate: 50,
            roundNumber,
            predictionId,
          });

          result[predictionId][roundNumber].roundNo = roundNumber;
        }
      }
    }

    //console.log(result);
    return result;
  };

  const getUserRoundsForEpochs = async (epochs) => {
    const groupData = getGroup();
    if (groupData.classic) {
      return getUserRoundsForEpochsClassic(epochs, groupData);
    }

    const historyData = await callContractWagmi({
      address: addressFrontendHelpers[groupData.network],
      abi: frontendHelperABI,
      functionName: "getUserRoundsForEpochs",
      args: [groupData.predictionId, address, epochs],
      network: groupData.network,
    });

    if (historyData[0]) return;
    const _result = await processUserHistoryRounds({
      roundNumbers: epochs,
      betDatas: historyData[1][0],
      roundDatas: historyData[1][1],
      claimables: historyData[1][2],
      refundables: historyData[1][3],
      groupData,
      predictionId: groupData.predictionId,
    });

    return _result;
  };

  const getUserRounds = async () => {
    const groupData = getGroup();
    if (groupData.classic) {
      return getUserRoundsClassic();
    }

    if (!addressFrontendHelpers[groupData.network]) return;

    const allPredictions = getPredictionIdsArray();

    let calls = [];

    for (let i = 0; i < allPredictions.length; i++) {
      calls.push(
        callContractWagmi({
          address: addressFrontendHelpers[groupData.network],
          abi: frontendHelperABI,
          functionName: "getUserRounds",
          args: [allPredictions[i], address],
          network: groupData.network,
        })
      );
    }

    const historyData = await Promise.all(calls);

    calls = [];

    for (let i = 0; i < historyData.length; i++) {
      if (historyData[i][0]) return;
      calls.push(
        processUserHistoryRounds({
          roundNumbers: historyData[i][1][0],
          betDatas: historyData[i][1][1],
          roundDatas: historyData[i][1][2],
          claimables: historyData[i][1][3],
          refundables: historyData[i][1][4],
          groupData,
          predictionId: allPredictions[i],
        })
      );
    }

    const _result = await Promise.all(calls);

    const result = {};
    for (let i = 0; i < _result.length; i++) {
      result[allPredictions[i]] = _result[i];
    }

    //console.log("getUserRounds result", result);

    return result;
  };

  const processUserHistoryRounds = async ({
    roundNumbers,
    betDatas,
    roundDatas,
    claimables,
    refundables,
    groupData,
    predictionId,
  }) => {
    const [err, samePriceRefundRate] = await callContractWagmi({
      ...getGroupAddressAndABI(),
      functionName: "samePriceRefundRate",
      args: [predictionId],
      network: groupData.network,
    });
    const currentRoundNo = await getCurrentRoundNo();
    const numRounds = betDatas.length;

    const tokenName = groupData.token;
    const bettingTokenName = groupData.bettingToken;

    const tokenUSDPrice = await getCurrentPrice();
    const decimal = getChainDecimal();

    const bettingTokenUSDPrice = await getBettingTokenUSDPrice(
      bettingTokenName
    );

    let netEarning = new BigNumber(0);
    let avgReturn = new BigNumber(0);
    let bestRound = new BigNumber(0);
    let avgBet = new BigNumber(0);
    let totalBet = new BigNumber(0);

    let totalClaimable = new BigNumber("0");
    const claimableRounds = [];

    let won = 0;
    let lost = 0;
    const roundsFiltered = [];

    const numRoundsPerDay = Math.floor(86400 / groupData.intervalSeconds);

    let countToday = 0;

    let start = numRounds > 100 ? numRounds - 100 : 0;

    for (let i = 0; i < numRounds; i++) {
      if (betDatas[i].amount.toString() === "0") {
        continue;
      }

      const round = {};
      const roundData = { ...roundDatas[i] };
      const betData = { ...betDatas[i] };
      const claimable = claimables[i];
      const refundable = refundables[i];

      const isToday =
        currentRoundNo - numRoundsPerDay < parseInt(roundNumbers[i]);

      if (isToday) countToday++;

      round.roundData = roundData;
      round.amount = new BigNumber(betData.amount.toString());

      let totalAmount = BigNumber(roundData.bearAmount.toString()).plus(
        BigNumber(roundData.bullAmount.toString())
      );

      const treasuryRate = await getTreasuryRate(getGroup().predictionId);

      round.rewardAmount = totalAmount.minus(
        totalAmount.multipliedBy(treasuryRate).dividedBy(1000)
      );

      const rewardBaseCalAmount =
        parseFloat(roundData.closePrice) > parseFloat(roundData.lockPrice)
          ? roundData.bullAmount
          : roundData.bearAmount;

      round.rewardBaseCalAmount = new BigNumber(rewardBaseCalAmount.toString());

      round.position = betData.position;

      round.amountWon =
        round.rewardBaseCalAmount > 0
          ? round.amount
              .multipliedBy(round.rewardAmount)
              .dividedBy(round.rewardBaseCalAmount)
          : 0;

      const lockPrice = new BigNumber(roundData.lockPrice.toString())
        .dividedBy(BIG_TEN.pow(8))
        .toString();
      const closePrice = new BigNumber(roundData.closePrice.toString())
        .dividedBy(BIG_TEN.pow(8))
        .toString();
      const bearAmount = new BigNumber(roundData.bearAmount.toString())
        .dividedBy(BIG_TEN.pow(8))
        .toString();
      const bullAmount = new BigNumber(roundData.bullAmount.toString())
        .dividedBy(BIG_TEN.pow(8))
        .toString();
      totalAmount = totalAmount.dividedBy(BIG_TEN.pow(8)).toString();

      round.roundData.lockPrice = lockPrice;
      round.roundData.closePrice = closePrice;
      round.roundData.bearAmount = bearAmount;
      round.roundData.bullAmount = bullAmount;
      round.roundData.totalAmount = totalAmount;

      round.roundResult =
        parseFloat(closePrice) > parseFloat(lockPrice) ? 0 : 1;
      if (parseFloat(closePrice) === parseFloat(lockPrice))
        round.roundResult = 2;

      round.currentEpoch = roundNumbers[i];
      round.roundNo = round.currentEpoch;

      round.ongoingRound = round.currentEpoch >= currentRoundNo - 1;

      const samePrice =
        !roundData.canceled && round.roundResult && round.roundResult === 2;

      round.claimed = betData.claimed;
      round.won =
        !roundData.canceled && (claimable || (betData.claimed && !samePrice));
      round.isRefundable = refundable;
      if (refundable) {
        claimableRounds.push(parseInt(round.currentEpoch));
        if (samePrice) {
          totalClaimable = totalClaimable.plus(
            (round.amount * samePriceRefundRate) / 100
          );
        } else totalClaimable = totalClaimable.plus(round.amount);
      } else if (round.currentEpoch === currentRoundNo - 2) {
        round.waitingForRefundCheck = true;
      }

      if (isToday) {
        totalBet = totalBet.plus(round.amount);
      }

      round.profit = 0;
      if (round.won) {
        if (!round.amountWon) {
          round.amountWon = 0;
        }
        const profit = BigNumber(round.amountWon.toString()).minus(
          round.amount
        );
        round.profit = profit;

        if (isToday) {
          won++;
          netEarning = netEarning.plus(profit);
          if (profit > bestRound) bestRound = profit;
        }

        if (!round.claimed) {
          if (!claimable) round.claimed = true;
          else {
            claimableRounds.push(parseInt(round.currentEpoch));
            totalClaimable = totalClaimable.plus(round.amountWon);
          }
        }
      } else if (roundData.canceled) {
        round.profit = 0;
      } else if (samePrice) {
        round.profit =
          (round.amount * samePriceRefundRate) / 100 - round.amount;
        round.amountWon = (round.amount * samePriceRefundRate) / 100;
      } else if (round.isRefundable) {
        round.profit = 0;
      } else if (!round.ongoingRound) {
        round.profit = -round.amount;

        if (isToday) {
          lost++;
          netEarning = netEarning.minus(round.amount);
        }
      }
      if (!round.amountWon) round.amountWon = 0;

      round.amount = BigNumber(round.amount.toString())
        .dividedBy(BIG_TEN.pow(decimal))
        .toFixed();
      round.amountWon = parseFloat(
        BigNumber(round.amountWon.toString())
          .dividedBy(BIG_TEN.pow(decimal))
          .toFixed(3)
      );
      round.profit = parseFloat(
        BigNumber(round.profit.toString())
          .dividedBy(BIG_TEN.pow(decimal))
          .toFixed(3)
      );
      //to be deleted
      round.priceDiff = round.profit;

      if (i >= start || claimable || refundable) roundsFiltered.push(round);
    }

    avgBet =
      countToday == 0
        ? 0
        : totalBet
            .dividedBy(countToday)
            .dividedBy(BIG_TEN.pow(decimal))
            .toFixed(2);
    avgReturn =
      countToday === 0
        ? 0
        : netEarning
            .dividedBy(countToday)
            .dividedBy(BIG_TEN.pow(decimal))
            .toFixed(2);
    netEarning = netEarning.dividedBy(BIG_TEN.pow(decimal)).toFixed(2);
    bestRound = bestRound.dividedBy(BIG_TEN.pow(decimal)).toFixed(2);
    totalBet = totalBet.dividedBy(BIG_TEN.pow(decimal)).toFixed(2);

    totalClaimable = totalClaimable.dividedBy(BIG_TEN.pow(decimal)).toFixed(2);

    const result = {
      numRounds: roundsFiltered.length,
      countToday,
      won,
      lost,
      bettingTokenName,
      bettingTokenUSDPrice,
      tokenName,
      tokenUSDPrice,
      netEarning,
      avgReturn,
      bestRound,
      avgBet,
      totalBet,
      rounds: roundsFiltered,
      totalClaimable,
      claimableRounds,
    };

    return result;
  };

  const getRoundData = async (roundNo, _groupData = null) => {
    let groupData = _groupData || getGroup();

    if (groupData.classic) {
      return getRoundDataClassic(roundNo, groupData);
    }

    const [_round, _timestamp] = await Promise.all([
      callContractWagmi({
        address: groupData.address,
        abi: multipleABI,
        functionName: "rounds",
        args: [groupData.predictionId, roundNo],
        getPromise: true,
        network: groupData.network,
      }),
      callContractWagmi({
        address: groupData.address,
        abi: multipleABI,
        functionName: "timestamps",
        args: [roundNo],
        getPromise: true,
        network: groupData.network,
      }),
    ]);

    return processRoundData({ round: _round, timestamp: _timestamp });
  };

  const isClaimable = async (roundNo) => {
    try {
      const groupData = getGroup();

      const [isClaimable, ledger, roundData] = await Promise.all([
        callContractWagmi({
          address: groupData.address,
          abi: multipleABI,
          functionName: "claimable",
          args: [groupData.predictionId, roundNo, address],
          getPromise: true,
          network: getGroup().network,
        }),
        callContractWagmi({
          address: groupData.address,
          abi: multipleABI,
          functionName: "ledger",
          args: [groupData.predictionId, roundNo, address],
          getPromise: true,
          network: getGroup().network,
        }),
        callContractWagmi({
          address: groupData.address,
          abi: multipleABI,
          functionName: "rounds",
          args: [groupData.predictionId, roundNo],
          getPromise: true,
          network: getGroup().network,
        }),
      ]);

      if (!isClaimable) return { status: false };

      const amountWon = new BigNumber(ledger.amount.toString())
        .multipliedBy(new BigNumber(roundData.rewardAmount.toString()))
        .dividedBy(new BigNumber(roundData.rewardBaseCalAmount.toString()))
        .integerValue(BigNumber.ROUND_DOWN);

      return { status: !ledger.claimed, amount: amountWon.toString() };
    } catch {
      return { status: false };
    }
  };

  const isRefundable = async (roundNo) => {
    try {
      const groupData = getGroup();

      const [isRefundable, ledger] = await Promise.all([
        callContractWagmi({
          address: groupData.address,
          abi: multipleABI,
          functionName: "refundable",
          args: [roundNo, address],
          getPromise: true,
          network: groupData.network,
        }),
        callContractWagmi({
          address: groupData.address,
          abi: multipleABI,
          functionName: "ledger",
          args: [roundNo, address],
          getPromise: true,
          network: groupData.network,
        }),
      ]);

      if (!isRefundable) return false;
      return !ledger.claimed; //ledger.claimed
    } catch {
      return false;
    }
  };

  const getChainDecimal = () => {
    const groupData = getGroup();
    if (
      groupData.network === Network.TRON ||
      groupData.network === Network.TRON_TESTNET
    )
      return 6;
    return 18;
  };

  const getUserBet = async (roundNo) => {
    const groupData = getGroup();

    if (groupData.classic) {
      return getUserBetClassic(roundNo);
    }

    const fallback = {
      position: 0,
      originalAmount: 0,
      amount: 0,
      referrerAmount: 0,
      stakingAmount: 0,
      claimed: false,
    };
    try {
      if (roundNo < 0) return fallback;

      const [errM, _ledger] = await callContractWagmi({
        address: groupData.address,
        abi: multipleABI,
        functionName: "ledger",
        args: [groupData.predictionId, roundNo, address],
      });

      if (errM) return fallback;

      const ledger = { ..._ledger };
      const decimal = getChainDecimal();
      ledger.originalAmount = new BigNumber(
        ledger.amount.toString()
      ).toString();
      ledger.amount = new BigNumber(ledger.amount.toString())
        .dividedBy(BIG_TEN.pow(decimal))
        .toFixed(2);
      return ledger;
    } catch (err) {
      console.log(err);
      return fallback;
    }
  };

  const getMinBetAmount = async () => {
    const groupData = getGroup();
    return groupData.minBetAmount;
  };

  const hasStopped = async () => {
    const groupData = getGroup();
    if (groupData.classic) {
      return hasStoppedClassic();
    }

    const [err, result] = await callContractWagmi({
      ...getGroupAddressAndABI(),
      functionName: "paused",
      network: getGroup().network,
    });

    if (err) return false;
    return result;
  };

  const hasGroupStopped = async (groupName) => {
    const groupData = getTargetGroup(groupName);
    const [err, result] = await callContractWagmi({
      ...getGroupAddressAndABI(),
      functionName: "paused",
      network: groupData.network,
    });
    if (err) return false;
    return result;
  };

  const getCurrentRoundLockTimestamp = async () => {
    const groupData = getGroup();
    const [err, currentRoundNo] = await callContractWagmi({
      ...getGroupAddressAndABI(),
      functionName: "currentEpoch",
      network: getGroup().network,
    });
    if (err) return 0;

    const [errTs, roundData] = await callContractWagmi({
      ...getGroupAddressAndABI(),
      functionName: "timestamps",
      args: [currentRoundNo],
      network: getGroup().network,
    });

    if (errTs) return 0;
    return roundData.lockTimestamp;
  };

  const getRoundLockTimestamp = async (roundNo) => {
    const groupData = getGroup();
    const [err, roundData] = await callContractWagmi({
      ...getGroupAddressAndABI(),
      functionName: "timestamps",
      args: [roundNo],
      network: getGroup().network,
    });

    if (err) return 0;
    return roundData.lockTimestamp;
  };

  const getCurrentTimestamp = async () => {
    const [err, date] = await fetchWithCatch(
      `https://api.prdt.finance/api/v1/time`
    );
    if (err) {
      const dateNow = Date.now();
      return Math.ceil(dateNow / 1000);
    }

    return Math.ceil(date / 1000);
  };

  const getMonthlyVolume = async () => {
    const [err, volume] = await fetchWithCatch(
      `https://api.prdt.finance/api/v1/volume`
    );
    if (err) return 0;

    return volume;
  };

  const getRoundTX = async (roundNo) => {
    return null;
  };

  const getRoundsTX = async () => {
    return;
  };

  const isValidWalletAddress = (addr) => {
    if (!addr) return false;
    return addr.length == 42 && addr.substring(0, 2) == "0x";
  };

  const checkAndGetPartnerWallet = (_partnerAddress) => {
    if (!_partnerAddress) return _partnerAddress;
    const partnerName = _partnerAddress.toUpperCase();
    if (partners[partnerName]) {
      //console.log('partner is', partnerName, partners[partnerName])
      localStorage.setItem("partner_" + address, partners[partnerName]);
      return partners[partnerName];
    }
    return _partnerAddress;
  };

  const betBear = async (roundNo, amount, _partnerAddress = null) => {
    const partnerAddress = checkAndGetPartnerWallet(_partnerAddress);
    const partnerWallet = isValidWalletAddress(partnerAddress)
      ? partnerAddress
      : null;
    const functionName = partnerWallet ? "hostedBetBear" : "betBear";
    const amountWei = new BigNumber(amount.toString()).multipliedBy(
      BIG_TEN.pow(getChainDecimal())
    );
    const result = await _bet(
      roundNo,
      amountWei.toString(),
      functionName,
      partnerWallet
    );

    return result;
  };

  const betBull = async (roundNo, amount, _partnerAddress = null) => {
    const partnerAddress = checkAndGetPartnerWallet(_partnerAddress);
    const partnerWallet = isValidWalletAddress(partnerAddress)
      ? partnerAddress
      : null;
    const functionName = partnerWallet ? "hostedBetBull" : "betBull";
    const amountWei = new BigNumber(amount.toString()).multipliedBy(
      BIG_TEN.pow(getChainDecimal())
    );
    const result = await _bet(
      roundNo,
      amountWei.toString(),
      functionName,
      partnerWallet
    );
    return result;
  };

  const isOnCorrectChain = async () => {
    return true;
    //const groupData = getGroup()
    //const chainId = await web3wallet.eth.net.getId()
    //return rpcs[groupData.network].chainId.toString() === chainId.toString()
  };

  const getGreCaptcha = () => {
    let grecaptcha = window.grecaptcha;
    if (typeof grecaptcha === "undefined") {
      grecaptcha = {};
    }
    grecaptcha.ready = function (cb) {
      if (typeof grecaptcha === "undefined") {
        const c = "___grecaptcha_cfg";
        window[c] = window[c] || {};
        (window[c]["fns"] = window[c]["fns"] || []).push(cb);
      } else {
        cb();
      }
    };
    return grecaptcha;
  };

  const handleVerification = async ({ data, token, epoch, which }) => {
    try {
      const response = await fetch(process.env.REACT_APP_VALIDATOR_API_URL, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          data,
          token,
          address,
          epoch,
          type: which,
        }),
      });
      if (response.ok) {
        const { proof } = await response.json();
        return { error: false, errorMessage: null, proof, token };
      } else {
        const errorMessage = `${response.status} ${response.statusText}`;
        return { error: true, errorMessage, proof: null };
      }
    } catch (error) {
      return { error: true, errrorMessage: String(error), proof: null };
    }
  };

  const handleCaptcha = async ({ epoch }) => {
    if (!epoch || !address) return;

    const data = utils.hexlify(utils.randomBytes(32));
    const labelhash = utils.keccak256(
      utils.toUtf8Bytes("rot1" + address + epoch)
    );
    const inthash = parseInt(labelhash).toString();
    const r = inthash.substring(5, 15);
    const which = r % 2;

    const typeToKey = {
      0: "6LcKZJMoAAAAALR9fJqMksQOXSG9dItPXn9kzPbV",
      1: "6LeTUX4oAAAAAIimsgn-q9lvYQT5VK40R9fQF56Y",
    };
    const grecaptcha = getGreCaptcha();
    grecaptcha.reset(typeToKey[which]);
    const token = await grecaptcha.execute(typeToKey[which], {
      action: "submit",
    });
    const result = await handleVerification({
      data,
      token,
      address,
      epoch,
      which,
    });
    
    return result;
  };

  const _bet = async (roundNo, amount, functionName, partnerAddress) => {
    const askCaptcha = await needCaptcha();

    let _proof = "0x";
    if (askCaptcha) {
      const { error, errorMessage, proof } = await handleCaptcha({
        epoch: roundNo,
      });
      if (error) {
        console.log("Captcha proof failed",errorMessage);
        return {
          status: false,
          message: "Captcha proof failed.",
          txlink: "",
          //messageId: createMessage(options.functionName),
        };
      }
      _proof = proof;
    }

    const groupData = getGroup();

    const options = {
      ...getGroupAddressAndABI(),
      functionName,
      getGasPrice: true,
      amount,
      network: groupData.network,
      skipSuccessMessage: true,
      info: messages.info[functionName].replace(
        "REPLACEtoken",
        groupData.token
      ),
    };

    options.args = functionName.includes("hosted")
      ? [_proof, groupData.predictionId, roundNo, partnerAddress]
      : [_proof, groupData.predictionId, roundNo];
    options.value = amount;

    const result = await sendContractWagmi(options);

    if (result.status) {
      result.message = result.message.replace("REPLACEtoken", groupData.token);
      updateMessage(
        result.messageId,
        messageStatus.succesfull,
        result.message,
        result.txlink
      );
    }

    return result;
  };

  const claimGroup = async (roundsArray, groupData) => {
    if (groupData.classic) {
      return claimClassic(roundsArray, groupData);
    }

    const options = {
      ...getGroupAddressAndABI(),
      functionName: "claim",
      getGasPrice: true,
      network: groupData.network,
      args: [[groupData.predictionId], roundsArray],
    };

    const result = await sendContractWagmi(options);
    return result;
  };

  const claim = async (roundsArray, isOldContract = false) => {
    const groupData = getGroup();

    if (groupData.classic) {
      return claimClassic(roundsArray);
    }

    const options = {
      ...getGroupAddressAndABI(),
      functionName: "claim",
      getGasPrice: true,
      network: groupData.network,
      args: [[groupData.predictionId], roundsArray],
    };

    const result = await sendContractWagmi(options);
    return result;
  };

  return {
    getGroupAddressAndABI,
    getCurrentRoundNo,
    getRoundData,
    isClaimable,
    isRefundable,
    getUserBet,
    getMinBetAmount,
    hasStopped,
    hasGroupStopped,
    getCurrentRoundLockTimestamp,
    getRoundLockTimestamp,
    getCurrentTimestamp,
    getMonthlyVolume,
    getRoundTX,
    getRoundsTX,
    betBull,
    betBear,
    claim,
    claimGroup,
    getMultiRoundData,
    getUserRounds,
    getUserRoundsForEpochs,
    isOnCorrectChain,
    getChainDecimal,
    getNumBets,
    needCaptcha,
  };
};

export default usePrediction;
