import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink
} from '@apollo/client'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import React from 'react'

export const apolloClient = new ApolloClient({
  link: createHttpLink({
    uri: 'https://api.thegraph.com/subgraphs/name/cmdevbc/prdtbscbnb'
  }),
  cache: new InMemoryCache()
})

const Apollo = (props) => {
  const network = useSelector((state) => state.network)
  useEffect(() => {
    if (network == 0)
      apolloClient.setLink(
        createHttpLink({
          uri: 'https://api.thegraph.com/subgraphs/name/cmdevbc/prdtbscbnb'
        })
      )
    else
      apolloClient.setLink(
        createHttpLink({
          uri: 'https://api.thegraph.com/subgraphs/name/cmdevbc/prdt-matic'
        })
      )
  }, [network])

  return <ApolloProvider client={apolloClient}>{props.children}</ApolloProvider>
}

export default Apollo
