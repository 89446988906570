import React from 'react'
import { useSelector } from 'react-redux';

type Props = {}

function useRounds() {

    const currentGroup = useSelector((state:any) => state.currentGroup);
    const roundData = useSelector((state:any) => state.roundsData);


    /////////////
    const now = new Date();
    const currentDay = now.getUTCDay();
    const currentHour = now.getUTCHours();
    const tradingHoursCfg = currentGroup.tradingHours;
    const tradingDays = tradingHoursCfg?.days;
    const tradingStartHour = tradingHoursCfg?.startHour;
    const tradingEndHour = tradingHoursCfg?.endHour;
  
    const isTradingHours =
      tradingHoursCfg == null ||
      (tradingDays.includes(currentDay) &&
        currentHour >= tradingStartHour &&
        currentHour < tradingEndHour);
    //////////

    
 let slides:any = [];
 let liveRound:any={} 

 const slideTypes = {
  expire: 1,
  live: 2,
  next: 3,
  future: 4,
  leader: 5,
  chart: 6,
  lottery: 7,
  pause: 8,
};

 if (roundData && currentGroup?.predictionId >= 0) {
  const list = Object.entries(roundData[currentGroup.predictionId] ?? {})
    .map(([key, value]) => value)
    .sort((a:any, b:any) => a.roundNo - b.roundNo);

  list.forEach((d:any, idx, arr) => {
    // if (idx === arr.length - 1) return;
    if (idx < arr.length - 2) {

        let roundState= 0;   // 0 =>gray , 1 =>green , -1 => red 
        const diff = Number(d?.closePrice - d?.lockPrice);
        if (diff == 0) {
            roundState = 0;
        } else if (diff > 0) {
            roundState = 1;
        } else if (diff < 0) {
            roundState = -1;
        }

      slides.push({
        type: slideTypes.expire,
        roundData: d,
        roundState:roundState
      });

     
    }
    if (idx == arr.length - 2) {
      if (isTradingHours) {
        slides.push({
          type: slideTypes.live,
          roundData: d,
        });
      } else {
        slides.push({ type: slideTypes.pause, roundData: d });
      }
    }
  });
}

if (slides?.length > 0) {
    if (isTradingHours) {
      slides.push({ type: slideTypes.next });
    }
  }

return{ slides , slideTypes}

}

export default useRounds