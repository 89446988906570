import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { client } from "./wagmi/wagmi";
import { PRDTCore, store } from "./core";
import { WagmiProvider } from "./wagmi/provider";

ReactDOM.render(
  <React.StrictMode>
    <PRDTCore>
      <Provider store={store}>
        <WagmiProvider client={client}>
          <App />
        </WagmiProvider>
      </Provider>
    </PRDTCore>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
