const tokens = {
  0: {
    BNB: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    WBNB: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    USDT: '0x55d398326f99059fF775485246999027B3197955',
    BUSD: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    ETH: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
    CAKE: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
    USDC: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
    LINK: '0xf8a0bf9cf54bb92f17374d9e9a321e6a111a51bd'
  },
  10: {
    WBNB: '0xae13d989dac2f0debff460ac112a837c89baa7cd',
    BNB: '0xae13d989dac2f0debff460ac112a837c89baa7cd',
    USDT: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
    USDC: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d'
  },
  1: {
    MATIC: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    USDT: '0xc2132D05D31c914a87C6611C10748AEb04B58e8F', //6 decimals
    BUSD: '0xdAb529f40E671A1D4bF91361c21bf9f0C9712ab7',
    WETH: '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    USDC: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174', // 6 decimals
    DAI: '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063'
  },
  11: {
    MATIC: '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270',
    USDT: '0x326C977E6efc84E512bB9C30f76E30c160eD06FB',
    BTC: '0x09b902433AA9D883b6F521b396453639f8d4c172',
    USDC: '0xd6407933fC85d655bFc84D600A8cB9E964263f78',
    DAI: '0x001B3B4d0F3714Ca98ba10F6042DaEbF0B1B7b6F',
    LINK: '0x326C977E6efc84E512bB9C30f76E30c160eD06FB'
  },
  2: {
    USDT: 'TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t',
    USDC: 'TEkxiTehnzSmSe2XqrBj4w32RUN966rdz8',
    USDD: 'TPYmHEhy5n8TCEfYGqW2rPxsghSfzghPDn',
    BTC: 'TN3W4H6rK2ce4vX9YnFQHwKENnHjoxb3m9',
    ETH: 'THb4CqiFdwNHsWsQCs4JhzwjMWys4aqCbF',
    WBTC: 'TXpw8XeWYeTUd4quDskoUqeQPowRh4jY65',
    TUSD: 'TUpMhErZL2fhh4sVNULAbNKLokS4GjC1F4',
    WIN: 'TLa2f6VPqDgRE67v1736s7bJ8Ray5wYjU7',
    USDJ: 'TMwFHYXLJaRUPeW6421aqXL4ZEzPRFGkGT',
    NFT: 'TFczxzPhnThNSqr5by8tvxsdCFRRz6cPNq',
    JST: 'TCFLL5dx5ZJdKnWuesXxi1VPwjLVmWZZy9',
    SUN: 'TSSMHYeV2uE9qYH95DqyoCuNCzEL1NvU3S',
    WTRX: 'TNUC9Qb1rRpS5CbWLmNMxXBjyFoydXjWFR',
    LTC: 'TR3DLthpnDdCGabhVDbD3VMsiJoCXY3bZd'
  }
}

export default tokens
