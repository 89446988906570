import { useEffect, useRef, useState } from "react";
import styles from "./Chart.module.css";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
//import LoadingAnimation from "pro/loading/LoadingAnimation";
import { TVChart } from "./TVChart";

const Chart = ({ className }: { className: string }) => {
  const [dimension, setDimension] = useState<any>();
  const ref: any = useRef();

  useEffect(() => {
    if (!ref.current) return;
    const resizeObserver = new ResizeObserver((entries) => {
      const rect = entries?.[0]?.contentRect;
      if (rect && rect?.height > 0 && rect?.width > 0) {
        setDimension(undefined);
        setTimeout(() => {
          setDimension({
            width: rect.width,
            height: rect.height,
          });
        }, 10);
      }
    });
    resizeObserver.observe(ref.current);
    return () => resizeObserver.disconnect();
  }, []);

  const currentGroup = useSelector((state:any) => state.currentGroup);
  const chartKey = currentGroup?.apicode;
  const tokenName = currentGroup?.token;
  ////////
  return (
    <div className={classNames(styles.container, className)}>
      <div ref={ref} className={styles.chart}>
        <TVChart tokenName={tokenName} chartKey={chartKey} />
      </div>
    </div>
  );
};
export default Chart;
