import {ReactComponent as ClockIcon} from "../../../assets/dashboard/clock1.svg";
import {ReactComponent as DownIcon} from "../../../assets/dashboard/down.svg";
import {ReactComponent as UpIcon} from "../../../assets/dashboard/up.svg";
import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAccount } from "wagmi";
import styles from "./NextRound.module.css";
import { useERC20, usePrediction, useStats } from "../../../core";
import { getTokenIconName } from "../../../tokenIcon/tokenIcon";
import { numberWithCommas } from "../../../utils/libs";
import binance from "../../../assets/binance-coin-bnb-logo-freelogovectors.net_.png";
import Loading from "../../Loading/Loading";
const NextRound = ({
  data,
  partnerCode,
}: {
  data: any;
  partnerCode: any;
}) => {
    // // // / / / / // / // / /

    const [tokenBalance, setTokenBalance] = useState(0);
    const [amount, setAmount] = useState(0);
    const { getMultiRoundData, getMinBetAmount, betBull, betBear } =
    usePrediction();
  const { getUserBettingTokenBalance } = useStats();
  const { approvePrediction, isApproved } = useERC20();
  const currentGroup = useSelector((state:any) => state.currentGroup);
  const timerTick = useSelector((state:any) => state.timerTick);
  const roundLockTimestamp = useSelector((state:any) => state.roundLockTimestamp);
  const group = useSelector((state:any) => state.currentGroup);
  const { isConnected,address } = useAccount();
  const paused = useSelector((state:any) => state.paused);
  const calculating = useSelector((state:any) => state.calculating);
  const duration = useSelector((state:any) => state.duration);
  const currentIcon = getTokenIconName(currentGroup.token);
  const bet = useSelector((state:any) => state.bet);
  const network = useSelector((state:any) => state.network);
  const timer = useSelector((state:any) => state.roundLockTimestamp);
  const [bettingState, setBettingState] = useState<any>({});
  const audioFlag = useSelector((state:any) => state.audio);
  const num = useSelector((state:any) => state.num);
  const audio = new Audio(
    "https://ipfs.io/ipfs/bafybeicxzxynapxah5bkwtu2tip4xpod4qc4c3gzeltqu4aeadbbmw3ozi/mixkit-coins-handling-1939.wav"
  );
  const currentRoundNo = useSelector((state:any) => state.num);
  const currentRoundNoRef = useRef(currentRoundNo);
  currentRoundNoRef.current = currentRoundNo;
  
  const [roundsData, setRoundsData] = useState(null);
  const roundData:any = roundsData?.[currentGroup?.predictionId]?.[currentRoundNo];
  
  const [inputError, setInputError] = useState("");
  
    // // // / / / / // / // / /

    const dispatch = useDispatch();
    const networkRef = useRef(network);
    networkRef.current = network;
    let pool;
    let position;
    let isEntered = false;
    if (roundData) {
      pool = numberWithCommas(roundData?.totalAmount);
      if (roundData?.userBet!=null && roundData.userBet?.originalAmount > 0) {
        isEntered = true;
        position = roundData.userBet.position;
      }

    }
  
    //////////
    const maxBetAmount =
    tokenBalance > 0
        ? (tokenBalance * 0.95)?.toFixed(3)
        :  2;
    const selectAmounts = [
      { value:  0.01 , text: "MIN" },
      { value:  0.25 , text:  0.25 },
      { value:  0.5, text:  0.50 },
      { value:  1, text: 1 },
      { value: maxBetAmount, text: "MAX" },
    ];
  
    ////

    const setBettingUp = (s:any) => {
      const tmp = bettingState;
      if (tmp[currentGroup?._id]) {
        tmp[currentGroup?._id].up = s;
      } else {
        tmp[currentGroup?._id] = { up: s };
      }
      setBettingState({ ...tmp });
    };
    const setBettingDown = (s:any) => {
      const tmp = bettingState;
      if (tmp[currentGroup?._id]) {
        tmp[currentGroup?._id].down = s;
      } else {
        tmp[currentGroup?._id] = { down: s };
      }
      setBettingState({ ...tmp });
    };
    const bettingUp = bettingState[currentGroup?._id]?.up;
    const bettingDown = bettingState[currentGroup?._id]?.down;
    

    function onConnect() {
      dispatch({
        type: "setShowConnectDialog",
        showConnectDialog: true,
      });
    }

    function isValidPositiveNumber(str:any) {
      return /^(?=.*[1-9])\d*(?:\.\d+)?$/.test(str);
    }

  const checkIfValidToBet = (): boolean => {

    if (!amount) {
      setInputError("Error: Enter amount to predict");
      return false;
    }
    if (!isValidPositiveNumber(amount)) {
      setInputError("Error: invalid amount to predict");
      return false;
    }
    if (Number(tokenBalance) <= 0 || Number(amount) > Number(tokenBalance)) {
      setInputError("Error: You donot have enough Balance");
      return false;
    }
    if (Number(amount) < Number(0.01)) {
      setInputError("Error: Amount Lower than MIN Amount");
      return false;
    }
    if (Number(amount) > Number(maxBetAmount)) {
      setInputError("Error: Amount Higher than MAX Amount");
      return false;
    }

    return true;
  };

  function onBetUp() {
    if (audioFlag == true) {
      audio.play().then(() => {
        return;
      });
    }
    setBettingUp(true);
    betBull(num, Number(amount).toString(), partnerCode).then((res:any) => {
      if (res.status == true) {
        setTimeout(function () {
          setBettingUp(false);
          dispatch({ type: "betChange" });
          dispatch({ type: "setRefreshUserHistory" });
        }, 5000);
      } else {
        setBettingUp(false);
      }
    });
  }
  //////////
  function onBetDown() {
    if (audioFlag == true) {
      audio.play().then(() => {
        return;
      });
    }
    setBettingDown(true);
    betBear(num, Number(amount).toString(), partnerCode).then((res:any) => {
      if (res.status == true) {
        setTimeout(function () {
          setBettingDown(false);
          dispatch({ type: "betChange" });
          dispatch({ type: "setRefreshUserHistory" });
        }, 5000);
      } else {
        setBettingDown(false);
      }
    });
  }

  useEffect(() => {
    if (address) {
      getUserBettingTokenBalance().then((balance) => {
       
          setTokenBalance(Number(balance.toString()));
    });
    } else {
      setTokenBalance(0);
    }
  }, [address, currentGroup, bet]);

  const reloadData = () => {
    if (currentRoundNo > 0) {
      getMultiRoundData(currentRoundNo, 1).then((roundsData:any) => {
        if (
          roundsData &&
          networkRef.current == network &&
          currentRoundNoRef.current == currentRoundNo
        ) {
          setRoundsData(roundsData);
        } else {
          setRoundsData(null);
        }
      });
    }
  };
  useEffect(() => {
    if (timerTick > 0 && timerTick % 10 == 0) {
      reloadData();
    }
  }, [timerTick]);
  useEffect(() => {
    reloadData();
  }, [network, address, currentRoundNo, bet]);

  return (
    <section className={styles.nextRound}>
      <div className={styles.top}>
        <div  className={styles.clock}>

        <ClockIcon />
        </div>
        <div>Next round</div>
        <div className={styles.tm}>
          <TimerValue /> min
        </div>
      </div>

      {isEntered ? (
        <div className={styles.content}>
          <div
            className={classNames(
              styles.entered,
              position == 0 ? styles.eup : styles.edown
            )}
          >
            <span className={styles.t6}>Entered</span> 
            <span className={styles.t7}>{position == 0 ? " UP" : " DOWN"}</span>
          </div>
          <div className={styles.r0}>
            <div className={styles.t1}>Position Size:</div>
            <div className={styles.t1}>
              {roundData?.userBet?.amount?.toString()} {currentGroup?.bettingToken}
            </div>
          </div>
          <div className={styles.r0}>
            <div className={styles.t1}>Multiplier:</div>
            <div className={styles.t1}>
              X {position == 0 ? roundData?.payoutUp : roundData?.payoutDown}
            </div>
          </div>
          <div className={styles.r0}>
            <div className={styles.t1}>Prize Pool:</div>
            <div className={styles.t1}>
              {pool} {currentGroup?.bettingToken}
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.content}>
          <div className={styles.r1}>
            <span>Commit {currentGroup?.bettingToken}</span>
            <span>Wallet Balance {tokenBalance?.toFixed(3)}</span>
          </div>
          <div className={styles.inpr}>
            {/* <TokenIcon
              tokenName={currentGroup?.bettingToken}
              className={styles.tkn}
            /> */}
            <img src={binance} width={18}/>
            <input
              className={styles.inp}
              type="text"
              onWheel={(e: any) => e?.target?.blur()}
              value={amount ?? ""}
              onChange={(event: any) => {
                setInputError("");
                setAmount(event.currentTarget.value)
              }}
            />

            {/* <Price amount={amount} token={currentGroup?.bettingToken} /> */}
          </div>
          
            {inputError && <div className={styles.inputError}>
              {inputError}
              </div>}
          
          <div className={styles.sbp}>
            {selectAmounts.map((d: any, ix: number) => (
              <div
                key={ix}
                onClick={() => {
                  setInputError("");
                  setAmount(d.value)}}
                className={styles.selectBtn}
              >
                {d.text}
              </div>
            ))}
          </div>
          <div className={styles.btns}>
            <div
              onClick={() => {
                if (!address) {
                  onConnect();
                } else if (checkIfValidToBet()){
                  onBetUp()
                }
              }}
              className={classNames(
                styles.tradeBtn,
                styles.upbtn,
                (bettingUp || bettingDown) && styles.disable
              )}
            >
              <span>UP</span>
              {bettingUp ? 
                  <Loading iconSize={15} loaderSize={35}/>:
                  <div className={styles.betStat}>
                <div className={styles.upIcon} > 

                <UpIcon />
                </div>
                <div className={styles.xn}>
                  {roundData?.payoutUp ? "X" + roundData?.payoutUp : ""}
                </div>
              </div>
              // <LoadingAnimation width="5.3rem" height="5.3rem" />
              }
              
            </div>
            <div
              onClick={() => {
                if (!address) {
                  onConnect();
                } else if (checkIfValidToBet()){
                  onBetDown()
                }
              }}
              className={classNames(
                styles.tradeBtn,
                styles.downbtn,
                (bettingUp || bettingDown) && styles.disable
              )}
            >
              <span>DOWN</span>
              {bettingDown? 
                 <Loading iconSize={15} loaderSize={35}/> :
                 <div className={styles.betStat}>
                 <div  className={styles.downIcon}>
                 <DownIcon />
                 </div>
                 <div className={styles.xn}>
                   {roundData?.payoutDown ? "X" + roundData?.payoutDown : ""}
                 </div>
               </div>
              }
              
            </div>
          </div>

          <div className={styles.r}>
            <div className={styles.t1}>Prize Pool:</div>
            <div className={styles.t1}>
              {pool} {currentGroup?.bettingToken}
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

const TimerValue = () => {
  const timerTick = useSelector((state:any) => state.timerTick);
  const timer = useSelector((state:any) => state.roundLockTimestamp);

  var time = Math.max(0, Math.round(timer - Date.now() / 1000 - 10));

  const h = Math.floor(time / 3600);
  time = time % 3600;
  const m = Math.floor(time / 60);
  const s = time % 60;

  var timerStr =
    h > 0
      ? h.toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        }) + ":"
      : "";
  timerStr +=
    m.toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    }) + ":";

  timerStr += s.toLocaleString("en-US", {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });
  return <>{timerStr}</>;
};
export { NextRound };
