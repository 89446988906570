import React from "react";
import styles from "./PausedState.module.css";
import classNames from "classnames";
type Props = { isExpanded: boolean };

function PausedState({ isExpanded }: Props) {
  return (
    <div className={classNames(styles.paused, isExpanded && styles.expanded)}>
      Please come back later !
      <br />
      Prediction Market is Paused
    </div>
  );
}

export default PausedState;
