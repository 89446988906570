import bnb from "../assets/bnb.png";
import btc from "../assets/btc.svg";
import coin from "../assets/coin.png";
import eth from "../assets/eth.svg";
import jpy from "../assets/jpy.svg";
import matic from "../assets/matic.svg";
import polygonMatic from "../assets/polygon-matic.svg";
import usdt from "../assets/usdt.svg";

function getTokenIconName(tokenName) {
  switch (tokenName?.toUpperCase()) {
    case "JPY":
      return jpy;
    case "BNB":
      return bnb;
    case "WBNB":
      return bnb;
    case "MATIC":
      return matic;
    case "POLYGAN":
      return polygonMatic;
    case "POLYGANMATIC":
      return polygonMatic;
    case "ETH":
      return eth;
    case "USDT":
      return usdt;
    case "BTC":
    case "BTCB":
      return btc;
    default:
      return coin;
  }
}
function TokenIcon({ tokenName, className, onClick }) {
  return (
    <img
      src={getTokenIconName(tokenName)}
      onClick={onClick}
      className={className}
    />
  );
}
export { TokenIcon, getTokenIconName };
