export default {
    0: {
        "USDC":"0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d"
    },
    10:{
        "VAI":"0x84b9B910527Ad5C03A9Ca831909E21e236EA7b06"
    },  
    1: {
    },  
    11: {
        "BANANA":""
    }
  }