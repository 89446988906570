export const tokens = [
  {
    id: "BTC-USD",
    title: "BTC-USDT",
    name: "BTC",
    group: "CRYPTO",
    chartKey: "Crypto.BTC/USD",
    chartTitle: "BTC-USD",
    decimalPoint: 2,
    pricescale: 100,
  },
  {
    id: "ETH-USD",
    title: "ETH-USDT",
    name: "ETH",
    group: "CRYPTO",
    chartKey: "Crypto.ETH/USD",
    chartTitle: "ETH-USD",
    decimalPoint: 2,
    pricescale: 100,
  },
  {
    id: "BNB-USD",
    title: "BNB-USDT",
    name: "BNB",
    group: "CRYPTO",
    chartKey: "Crypto.BNB/USD",
    chartTitle: "BNB-USD",
    decimalPoint: 3,
    pricescale: 1000,
  },
  {
    id: "MATIC-USD",
    title: "MATIC-USDT",
    name: "MATIC",
    group: "CRYPTO",
    chartKey: "Crypto.MATIC/USD",
    chartTitle: "MATIC-USD",
    decimalPoint: 4,
    pricescale: 10000,
  },
  {
    id: "DOGE-USD",
    title: "DOGE-USDT",
    name: "DOGE",
    group: "CRYPTO",
    chartKey: "Crypto.DOGE/USD",
    chartTitle: "DOGE-USD",
    decimalPoint: 5,
    pricescale: 100000,
  },
  {
    id: "XRP-USD",
    title: "XRP-USDT",
    name: "XRP",
    group: "CRYPTO",
    chartKey: "Crypto.XRP/USD",
    chartTitle: "XRP-USD",
    decimalPoint: 4,
    pricescale: 10000,
  },
  {
    id: "SOL-USD",
    title: "SOL-USDT",
    name: "SOL",
    group: "CRYPTO",
    chartKey: "Crypto.SOL/USD",
    chartTitle: "SOL-USD",
    decimalPoint: 2,
    pricescale: 100,
  },
  {
    id: "PEPE-USD",
    title: "PEPE-USDT",
    name: "PEPE",
    group: "CRYPTO",
    chartKey: "Crypto.PEPE/USD",
    chartTitle: "PEPE-USD",
    decimalPoint: 8,
    pricescale: 100000000,
  },
  {
    id: "ARB-USD",
    title: "ARB-USDT",
    name: "ARB",
    group: "CRYPTO",
    chartKey: "Crypto.ARB/USD",
    chartTitle: "ARB-USD",
    decimalPoint: 4,
    pricescale: 10000,
  },
  {
    id: "USD-JPY",
    title: "USD-JPY",
    name: "JPY",
    group: "FOREX",
    chartKey: "Fx.USD/JPY",
    chartTitle: "USD-JPY",
    decimalPoint: 5,
    pricescale: 100000,
  },
  {
    id: "GBP-USD",
    title: "GBP-USD",
    name: "GBP",
    group: "FOREX",
    chartKey: "FX.GBP/USD",
    chartTitle: "GBP-USD",
    decimalPoint: 5,
    pricescale: 100000,
  },
  {
    id: "EUR-USD",
    title: "EUR-USD",
    name: "EUR",
    group: "FOREX",
    chartKey: "FX.EUR/USD",
    chartTitle: "EUR-USD",
    decimalPoint: 5,
    pricescale: 100000,
  },
];

export const getTokenById = (id: any) =>
  tokens.find((token) => token.id.toUpperCase() === id?.toUpperCase()) || null;

export const getTokenByName = (name: any) =>
  tokens.find((token) => token.name.toUpperCase() === name?.toUpperCase()) ||
  null;

export const getTokenDecimal = (name: any) => {
  let _token =
    tokens.find((token) => token.name.toUpperCase() === name?.toUpperCase()) ||
    null;
  return _token?.decimalPoint || 0;
};

export const getTokenByChartKey = (chartKey: any) =>
  tokens.find(
    (token) => token.chartKey.toUpperCase() === chartKey?.toUpperCase()
  ) || null;

export const periods = [
  { title: "1m", value: 60, apiSymbol: "1m" },
  //{ title: "3m", value: 180, apiSymbol: "3m" },
  { title: "5m", value: 300, apiSymbol: "5m" },
  { title: "15m", value: 900, apiSymbol: "15m" },
  { title: "30m", value: 1800, apiSymbol: "30m" },
  { title: "1h", value: 3600, apiSymbol: "1h" },
  { title: "2h", value: 7200, apiSymbol: "2h" },
  { title: "4h", value: 14400, apiSymbol: "4h" },
  //{ title: "6h", value: 21600, apiSymbol: "6h" },
  // { title: "8h", value: 28800, apiSymbol: "8h" },
  // { title: "12h", value: 43200, apiSymbol: "12h" },
  //  { title: "1d", value: 86400, apiSymbol: "1d" },
  //  { title: "3d", value: 259200, apiSymbol: "3d" },
  // { title: "1w", value: 604800, apiSymbol: "1w" },
  // { title: "1M", value: 2592000, apiSymbol: "1M" },
];

export const charts = [
  { title: "Candle", id: "candle", tvId: 1 },
  { title: "Area", id: "area", tvId: 3 },
  { title: "Line", id: "line", tvId: 2 },
  { title: "Bar", id: "bar", tvId: 0 },
];
