import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import BigNumber from 'bignumber.js'
import useExecuteContract from './useExecuteContract'
import rpcs from '../utils/constants/rpcs'
import { JsonRpcProvider } from '@ethersproject/providers'
import usePrice from './usePrice'
import soloPredictionABI from '../utils/constants/ABI/soloPrediction.json'
import erc20ABI from '../utils/constants/ABI/erc20.json'
import allContracts from '../utils/constants/contracts'
import configSolo from '../utils/constants/configSolo'
import decimals from '../utils/constants/decimals'
import { updateMessage, messageStatus } from '../utils/message'
import messages from '../utils/constants/messages'
import partners from '../utils/constants/partners'
import { useAccount } from 'wagmi'
const BIG_TEN = new BigNumber(10)

const addressZero = '0x0000000000000000000000000000000000000000'

const useSoloPrediction = () => {
  const { callContractWagmi, sendContractWagmi } = useExecuteContract()
  const { getPriceBINANCE } = usePrice()
  const { address: userAddress } = useAccount()
  //const userAddress = '0xd22230f468487899abdaa244a33fb59f31b28306';
  const [timeSync, setTimeSync] = useState(null)
  const currentNetwork = useSelector((state) => state.network)

  const BigString = (amount) => {
    return Number(amount.toString()).toLocaleString('fullwide', {
      useGrouping: false
    })
  }

  const getTargetPrices = async () => {
    const targetTokens = getTargetTokens()
    const calls = []
    for (let i = 0; i < targetTokens.length; i++) {
      calls.push(getPrice(targetTokens[i].name))
    }
    const prices = await Promise.all(calls)
    let priceObj = {}
    for (let i = 0; i < targetTokens.length; i++) {
      const tokenName = targetTokens[i].name
      priceObj = { ...priceObj, [tokenName]: prices[i] }
    }
    return priceObj
  }

  const getTargetPrice = async (tokenName) => {
    const price = await getPrice(tokenName)
    return price
  }

  const getCurrencyPrice = async (code, getTimestamp = false) => {
    try {
      const data = await fetch(
        `https://api.polygon.io/v1/last_quote/currencies/${code}/USD?apiKey=Leu8y3KNllT9uuSfTqP6riRpb4eNW6vI`
      )
      const dataJson = await data.json()
      if (dataJson.status === 'success' || dataJson.status === 'OK') {
        const price = dataJson.last.ask
        if (getTimestamp) {
          const timestamp = Math.round(dataJson.last.timestamp / 1000)
          return { price, timestamp }
        }
        return price
      }

      return null
    } catch (err) {
      return null
    }
  }

  const getStockPrice = async (apiCode, getTimestamp = false) => {
    try {
      const data = await fetch(
        `https://api.polygon.io/v2/last/nbbo/${apiCode}?apiKey=Leu8y3KNllT9uuSfTqP6riRpb4eNW6vI`
      )
      const dataJson = await data.json()
      if (dataJson.status === 'success' || dataJson.status === 'OK') {
        const price = dataJson.results.P
        if (getTimestamp) {
          const timestamp = Math.round(dataJson.results.t / 1000000000)
          return { price, timestamp }
        }
        return price
      }

      return null
    } catch (err) {
      return null
    }
  }

  const getPrice = async (target) => {
    if (configSolo.apiType[target] === 'CRYPTO') {
      const apiCode = configSolo.apiCodes[target]
      const price = await getPriceBINANCE(apiCode)
      if (!price) return 0
      return price.price
    } else if (configSolo.apiType[target] === 'CURRENCY') {
      const apiCode = configSolo.apiCodes[target]
      const price = await getCurrencyPrice(apiCode)
      if (!price) return 0
      return price.toFixed(6)
    } else if (configSolo.apiType[target] === 'STOCK') {
      const apiCode = configSolo.apiCodes[target]
      const price = await getStockPrice(apiCode)
      if (!price) return 0
      return price.toFixed(6)
    }
  }

  const getTargetType = (targetName) => {
    return configSolo.apiType[targetName]
  }

  const getBlockTimestampDiff = async () => {
    if (timeSync) return timeSync

    const provider = new JsonRpcProvider(rpcs[currentNetwork].targetRpc)
    const blockNumber = await provider.getBlockNumber()
    const block = await provider.getBlock(blockNumber)
    const timestamp = block.timestamp

    const datenow = Math.ceil(Date.now() / 1000)
    const diff = datenow - timestamp

    setTimeSync(diff)
    return diff
  }

  const getTimerLeft = async (endingTimestamp) => {
    const datenow = Math.ceil(Date.now() / 1000)
    const sync = await getBlockTimestampDiff()
    const secondsLeft = endingTimestamp - (datenow - sync) - 6
    return secondsLeft
  }

  const getUserBets = async (numBet, offset = 0) => {
    const [err, userRounds] = await callContractWagmi({
      address: allContracts.soloPrediction[currentNetwork],
      abi: soloPredictionABI,
      functionName: 'getUserRoundsOffset',
      args: [userAddress, numBet, offset]
    })

    if (err) {
      return { ongoingRounds: [], completedRounds: [] }
    }

    const ongoingRounds = []
    const completedRounds = []

    for (let i = 0; i < userRounds.length; i++) {
      const bettingTokenName = getBettingTokenName(userRounds[i].bettingToken)
      const tokenDecimals = decimals[currentNetwork][bettingTokenName] || 18
      const targetToken = userRounds[i].targetSource

      if (userRounds[i].startPrice == '0' || userRounds[i].closePrice == '0') {
        let timer = 0
        const cancelled = userRounds[i].isComplete ? true : false
        const roundData = {
          ...userRounds[i],
          timer: timer,
          cancelled,
          targetToken,
          bettingTokenName
        }
        roundData.amount = BigNumber(roundData.amount.toString())
          .dividedBy(10 ** tokenDecimals)
          .toFixed(2)

        //CANCELLED ROUND
        if (cancelled) {
          completedRounds.push(roundData)
          continue
        }

        //NOT CANCELLED
        const _timer = await getTimerLeft(userRounds[i].closeTimestamp)
        const interval =
          parseInt(userRounds[i].closeTimestamp) -
          parseInt(userRounds[i].startTimestamp)
        roundData.timer = _timer > interval ? interval : _timer

        roundData.startPrice =
          roundData.startPrice == 0
            ? 0
            : BigNumber(roundData.startPrice.toString())
                .dividedBy(10 ** 8)
                .toFixed(6)

        ongoingRounds.push(roundData)
      } else {
        let roundResult = '0'
        if (userRounds[i].startPrice === userRounds[i].closePrice)
          roundResult = '2'
        else if (
          parseInt(userRounds[i].startPrice) >
          parseInt(userRounds[i].closePrice)
        ) {
          roundResult = '1'
        }

        const result = roundResult === userRounds[i].position

        const roundData = {
          ...userRounds[i],
          roundResult: roundResult,
          result: result,
          cancelled: false,
          targetToken,
          bettingTokenName: getBettingTokenName(userRounds[i].bettingToken)
        }

        roundData.closePrice = BigNumber(roundData.closePrice.toString())
          .dividedBy(10 ** 8)
          .toFixed(6)
        roundData.startPrice = BigNumber(roundData.startPrice.toString())
          .dividedBy(10 ** 8)
          .toFixed(6)
        roundData.amount = BigNumber(roundData.amount.toString())
          .dividedBy(10 ** tokenDecimals)
          .toFixed(2)

        roundData.outcome = result
          ? (parseFloat(roundData.amount) * 1.8).toFixed(2)
          : 0

        completedRounds.push(roundData)
      }
    }

    return { ongoingRounds, completedRounds }
  }

  const getMinBetAmount = async (_bettingTokenAddress) => {
    const tokenName = getBettingTokenName(_bettingTokenAddress)
    if (
      !configSolo.betAmounts[currentNetwork] ||
      !configSolo.betAmounts[currentNetwork][tokenName]
    )
      return 0
    return configSolo.betAmounts[currentNetwork][tokenName].min.toString() || 0
  }

  const getMaxBetAmount = async (_bettingTokenAddress) => {
    const tokenName = getBettingTokenName(_bettingTokenAddress)
    if (
      !configSolo.betAmounts[currentNetwork] ||
      !configSolo.betAmounts[currentNetwork][tokenName]
    )
      return 0
    return configSolo.betAmounts[currentNetwork][tokenName].max.toString() || 0
  }

  const isValidWalletAddress = (addr) => {
    if (!addr) return false
    return addr.length == 42 && addr.substring(0, 2) == '0x'
  }

  const checkAndLoadPartnerCode = (_partnerAddress) => {
    if (_partnerAddress) {
      const partnerName = _partnerAddress.toUpperCase()
      if (partners[partnerName]) {
        localStorage.setItem('partner_' + userAddress, partners[partnerName])
        return partners[partnerName]
      }
      return _partnerAddress
    }
    const partnerWallet = localStorage.getItem('partner_' + userAddress)
    //if (partnerWallet) console.log('using cached partnerCode', partnerWallet)
    return partnerWallet
  }

  const betBear = async (
    targetTokenName,
    _bettingTokenAddress,
    interval,
    amount,
    _partnerAddress = null
  ) => {
    const partnerAddress = checkAndLoadPartnerCode(_partnerAddress)
    const partnerWallet = isValidWalletAddress(partnerAddress)
      ? partnerAddress
      : addressZero
    const bettingToken = isValidWalletAddress(_bettingTokenAddress)
      ? _bettingTokenAddress
      : addressZero

    const result = await _bet(
      targetTokenName,
      bettingToken,
      interval,
      amount,
      'betBear',
      partnerWallet
    )
    return result
  }

  const betBull = async (
    targetTokenName,
    _bettingTokenAddress,
    interval,
    amount,
    _partnerAddress = null
  ) => {
    const partnerAddress = checkAndLoadPartnerCode(_partnerAddress)
    const partnerWallet = isValidWalletAddress(partnerAddress)
      ? partnerAddress
      : addressZero
    const bettingToken = isValidWalletAddress(_bettingTokenAddress)
      ? _bettingTokenAddress
      : addressZero

    const result = await _bet(
      targetTokenName,
      bettingToken,
      interval,
      amount,
      'betBull',
      partnerWallet
    )
    return result
  }

  const _bet = async (
    targetTokenName,
    bettingTokenAddress,
    interval,
    amount,
    _functionName,
    partnerAddress
  ) => {
    const operationCost = await getOperationCostByAddress(bettingTokenAddress)
    const amountWithCost =
      Math.round((parseFloat(amount) + parseFloat(operationCost)) * 1e12) / 1e12

    const tokenName = getBettingTokenName(bettingTokenAddress)
    const tokenDecimals = decimals[currentNetwork][tokenName] || 18
    const amountWei = new BigNumber(amountWithCost.toString()).multipliedBy(
      BIG_TEN.pow(tokenDecimals)
    )

    const options = {
      address: allContracts.soloPrediction[currentNetwork],
      abi: soloPredictionABI,
      functionName: _functionName,
      getGasPrice: true,
      gasLimit: '500000',
      amount: BigString(amountWei),
      network: currentNetwork,
      skipErrorMessage: true,
      skipSuccessMessage: true,
      info: messages.info[_functionName].replace(
        'REPLACEtoken',
        targetTokenName
      )
    }

    if (bettingTokenAddress && bettingTokenAddress !== addressZero) {
      options.functionName = _functionName + 'WithToken'
      options.args = [
        BigString(amountWei),
        bettingTokenAddress,
        targetTokenName,
        partnerAddress,
        interval
      ]
    } else {
      options.value = BigString(amountWei)
      options.args = [targetTokenName, partnerAddress, interval]
    }

    const result = await sendContractWagmi(options)

    if (!result.status) {
      const minBet = await getMinBetAmount(bettingTokenAddress)
      result.message = result.message.replace('REPLACEmin', minBet)
      const maxBet = await getMaxBetAmount(bettingTokenAddress)
      result.message = result.message.replace('REPLACEmax', maxBet)
      updateMessage(result.messageId, messageStatus.error, result.message, null)
    } else {
      result.message = result.message.replace('REPLACEtoken', targetTokenName)
      updateMessage(
        result.messageId,
        messageStatus.succesfull,
        result.message,
        result.txlink
      )
    }

    return result
  }

  const claim = async (_bettingTokenAddress) => {
    const bettingToken = isValidWalletAddress(_bettingTokenAddress)
      ? _bettingTokenAddress
      : addressZero
    const options = {
      address: allContracts.soloPrediction[currentNetwork],
      abi: soloPredictionABI,
      functionName: 'claim',
      getGasPrice: true,
      network: currentNetwork,
      args: [bettingToken]
    }

    const result = await sendContractWagmi(options)
    return result
  }

  const approveBettingToken = async (_bettingTokenAddress) => {
    const bettingToken = isValidWalletAddress(_bettingTokenAddress)
      ? _bettingTokenAddress
      : addressZero

    const predictionAddress = allContracts.soloPrediction[currentNetwork]

    const result = await sendContractWagmi({
      address: bettingToken,
      abi: erc20ABI,
      functionName: 'approve',
      network: currentNetwork,
      getGasPrice: true,
      args: [
        predictionAddress,
        '115792089237316195423570985008687907853269984665640564039457584007913129639935'
      ]
    })

    return result
  }

  const isTokenApproved = async (_bettingTokenAddress) => {
    const bettingToken = isValidWalletAddress(_bettingTokenAddress)
      ? _bettingTokenAddress
      : addressZero

    if (bettingToken === addressZero) return true
    if (!userAddress) return false

    const predictionAddress = allContracts.soloPrediction[currentNetwork]

    const [err, result] = await callContractWagmi({
      address: bettingToken,
      abi: erc20ABI,
      functionName: 'allowance',
      args: [userAddress, predictionAddress]
    })

    return result > '1000000000000000000000000'
  }

  const getClaimableBalance = async (_bettingTokenAddress) => {
    const bettingToken = isValidWalletAddress(_bettingTokenAddress)
      ? _bettingTokenAddress
      : addressZero

    const [err, result] = await callContractWagmi({
      address: allContracts.soloPrediction[currentNetwork],
      abi: soloPredictionABI,
      functionName: 'userBalances',
      args: [userAddress, bettingToken]
    })
    if (err) return 0

    const tokenName = getBettingTokenName(_bettingTokenAddress)
    const tokenDecimals = decimals[currentNetwork][tokenName] || 18
    const balance = new BigNumber(result.toString())
      .dividedBy(BIG_TEN.pow(tokenDecimals))
      .toFixed(2)
    return balance
  }

  const getBettingTokens = () => {
    if (currentNetwork == -1) return []
    return Object.keys(configSolo.bettingTokens[currentNetwork])
  }

  const getBettingTokenName = (tokenAddress) => {
    if (currentNetwork == -1) return null
    const obj = configSolo.bettingTokens[currentNetwork]
    return Object.keys(obj).find((key) => obj[key] === tokenAddress)
  }

  const getBettingTokenAddress = (_tokenName) => {
    if (currentNetwork == -1) return addressZero
    return configSolo.bettingTokens[currentNetwork][_tokenName]
  }

  const getTargetTokens = () => {
    if (currentNetwork == -1) return []
    const allTokens = configSolo.targetTokens[currentNetwork]
    const tokens = []
    for (let i = 0; i < allTokens.length; i++) {
      tokens.push({ name: allTokens[i], type: getTargetType(allTokens[i]) })
    }
    return tokens
  }

  const getUserBalance = async (_bettingTokenAddress) => {
    if (_bettingTokenAddress === addressZero) {
      try {
        const _provider = new JsonRpcProvider(rpcs[currentNetwork].targetRpc)
        const result = await _provider.getBalance(userAddress)

        const tokenName = getBettingTokenName(_bettingTokenAddress)
        const tokenDecimals = decimals[currentNetwork][tokenName] || 18

        const balance = new BigNumber(result.toString())
          .dividedBy(BIG_TEN.pow(tokenDecimals))
          .toFixed(2)
        return balance
      } catch (err) {
        return 0
      }
    } else {
      const [err, result] = await callContractWagmi({
        address: _bettingTokenAddress,
        abi: erc20ABI,
        functionName: 'balanceOf',
        args: [userAddress]
      })
      const tokenName = getBettingTokenName(_bettingTokenAddress)
      const tokenDecimals = decimals[currentNetwork][tokenName] || 18

      return BigNumber(result.toString())
        .dividedBy(10 ** tokenDecimals)
        .toFixed()
    }
  }

  const getOperationCostByAddress = async (_bettingTokenAddress) => {
    const tokenName = getBettingTokenName(_bettingTokenAddress)
    return configSolo.operationCosts[currentNetwork][tokenName] || 0
  }

  const getOperationCostByName = async (_bettingToken) => {
    const address = getBettingTokenAddress(_bettingToken)
    const cost = await getOperationCostByAddress(address)
    return cost
  }

  const getIntervalLimits = async () => {
    return [180, 1800]
  }

  const isCryptoPaused = async () => {
    const [err, isPaused] = await callContractWagmi({
      address: allContracts.soloPrediction[currentNetwork],
      abi: soloPredictionABI,
      functionName: 'paused'
    })

    return isPaused
  }

  const isForexPaused = async () => {
    const [err, isPaused] = await callContractWagmi({
      address: allContracts.soloPrediction[currentNetwork],
      abi: soloPredictionABI,
      functionName: 'fxPaused'
    })

    return isPaused
  }

  const isStocksPaused = async () => {
    const [err, isPaused] = await callContractWagmi({
      address: allContracts.soloPrediction[currentNetwork],
      abi: soloPredictionABI,
      functionName: 'stocksPaused'
    })

    return isPaused
  }

  const isPaused = async () => {
    const [cryptoPaused, fxPaused, stocksPaused, fxPrice, stockPrice] =
      await Promise.all([
        isCryptoPaused(),
        isForexPaused(),
        isStocksPaused(),
        getCurrencyPrice('EUR', true),
        getStockPrice('AAPL', true)
      ])

    if (cryptoPaused) return { crypto: true, fx: true, stocks: true }
    else {
      const _now = Math.ceil(Date.now() / 1000)
      const fx = fxPaused || Math.abs(_now - fxPrice.timestamp) > 300

      const stocks = stocksPaused || Math.abs(_now - stockPrice.timestamp) > 300
      return { crypto: false, fx, stocks }
    }
  }

  const getNumBets = async () => {
    if (
      currentNetwork === -1 ||
      !allContracts.soloPrediction[currentNetwork] ||
      !userAddress
    )
      return 0

    const graph = 'https://api.thegraph.com/subgraphs/name/cmdevbc/prdtpolygon'

    const query = `query ($contract: String!, $sender: String!) { 
      userTotals(first:1, where:{contract: $contract, sender: $sender}) {
          countBet
      }
    }`

    const variables = {
      contract: allContracts.soloPrediction[currentNetwork],
      sender: userAddress
    }

    let results = await fetch(graph, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },

      body: JSON.stringify({
        query,
        variables
      })
    })

    const result = await results.json()
    const data = result.data.userTotals[0]
    return data.countBet || 0
  }

  return {
    getUserBets,
    getMinBetAmount,
    getMaxBetAmount,
    betBull,
    betBear,
    claim,
    getOperationCostByName,
    getOperationCostByAddress,
    getClaimableBalance,
    isTokenApproved,
    approveBettingToken,
    getBettingTokens,
    getBettingTokenAddress,
    getBettingTokenName,
    getTargetTokens,
    getUserBalance,
    getIntervalLimits,
    getTargetPrices,
    getTargetPrice,
    getTargetType,
    isPaused,
    isCryptoPaused,
    isForexPaused,
    isStocksPaused,
    getNumBets
  }
}

export default useSoloPrediction
