import LiveRound from "./LiveRound";
import styles from "./DashboardSm.module.css";
// import { NextRound } from "./NextRound";
// import PastRounds from "./PastRounds";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import PausedRound from "./PausedRound";
// import LastRound from "./LastRound";
// import SelectToken from "./SelectToken";
import classNames from "classnames";
// import Chart from "../chart/Chart";
import Tradingview from "../../tradingview/tradingview";
import { NextRound } from "./NextRound";
import PausedRound from "./PausedRound";
import PastRounds from "./PastRounds";
import SelectToken from "./SelectToken";
import Chart from "../chart/Chart";
// import { useSearchParams } from 'react-router-dom';

const DashboardSm = ({liveData,nextroundData,pastRoundData}:{liveData:any,nextroundData:any,pastRoundData:any,partnerCode?: any}) => {
  const dispatch = useDispatch();
  const [chartHeight, setChartHeight] = useState(null);
  const [initialLoad, setInitialLoad] = useState(false);
  // const [searchParams,setSearchParams] = useSearchParams();

 
  const showSmHistory = useSelector(
    (state: any) => state.showSmHistory
  );
  const paused = useSelector((state:any) => state.paused);

  const liveRoundData = liveData;
  // const lastRoundData = getLastRound();
  const pastRounds =pastRoundData;
  const nextRound = nextroundData;


  
  const partnerCode = "0x49562e9a8dde1006577bf6d08644e15e2194601b";

  const [openChart, setOpenChart] = useState(false);
  //return <></>;

  useEffect(() => {
    if (initialLoad) {
      return;
    }
    if (pastRounds && nextRound) {
      setInitialLoad(true);
    }
  }, [pastRounds, nextRound]);
  return (
    <>
      <section className={styles.classicSm}>
        
          <>
            <SelectToken/>
            {paused  ? (
              <PausedRound />
            
            ) : (
              <>
                <LiveRound roundData={liveRoundData} />
                <NextRound data={nextRound} partnerCode={partnerCode} />
                <PastRounds roundsData={pastRoundData}/>
              </>
            )}

            {/* <PastRounds roundsData={pastRounds} /> */}
          </>
        
      </section>
      {initialLoad && (
        <div className={styles.chartOpenBtn} onClick={() => setOpenChart(true)}>
          <button>Live Chart</button>
        </div>
      )}

      {initialLoad && (
        <div
          className={classNames(
            styles.chartModal,
            openChart && styles.chartModalOpen
          )}
        >
          <div className={styles.chartModalHeader}>
            <div className={styles.title}>Live Chart</div>
            <div
              className={styles.closeModal}
              onClick={() => setOpenChart(false)}
            >
              <CloseSVG />
            </div>
          </div>
          <div className={styles.chart}>
            <Chart className={styles.livechart} />
            {/* <Tradingview className={''}/> */}
          </div>
        </div>
      )}

      {openChart && (
        <div
          className={styles.backdrop}
          onClick={() => setOpenChart(false)}
        ></div>
      )}
    </>
  );
};

const CloseSVG = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="22"
      height="22"
      viewBox="0 0 50 50"
    >
      <path d="M 25 2 C 12.309534 2 2 12.309534 2 25 C 2 37.690466 12.309534 48 25 48 C 37.690466 48 48 37.690466 48 25 C 48 12.309534 37.690466 2 25 2 z M 25 4 C 36.609534 4 46 13.390466 46 25 C 46 36.609534 36.609534 46 25 46 C 13.390466 46 4 36.609534 4 25 C 4 13.390466 13.390466 4 25 4 z M 32.990234 15.986328 A 1.0001 1.0001 0 0 0 32.292969 16.292969 L 25 23.585938 L 17.707031 16.292969 A 1.0001 1.0001 0 0 0 16.990234 15.990234 A 1.0001 1.0001 0 0 0 16.292969 17.707031 L 23.585938 25 L 16.292969 32.292969 A 1.0001 1.0001 0 1 0 17.707031 33.707031 L 25 26.414062 L 32.292969 33.707031 A 1.0001 1.0001 0 1 0 33.707031 32.292969 L 26.414062 25 L 33.707031 17.707031 A 1.0001 1.0001 0 0 0 32.990234 15.986328 z"></path>
    </svg>
  );
};

export default DashboardSm;
