import { useSelector } from "react-redux";
import { useState } from "react";
import { getGroupData } from "../utils/constants/groupData.js";

const useGroup = () => {
  const currentGroup = useSelector((state) => state.currentGroup?._id ?? 0);
  const [keys, setKeys] = useState(null);
  const [allGroups] = useState(getGroupData());

  const getGroup = () => {
    let keysTemp = keys;
    if (!keysTemp) {
      keysTemp = Object.keys(allGroups);
      setKeys(keysTemp);
    }
    return allGroups[keysTemp[currentGroup]];
  };

  const getPredictionIdsArray = () => {
    const cur = getGroup();
    return Object.values(allGroups)
      .filter((g) => g.predictionId && g.network === cur.network)
      .map((g) => g.predictionId);
  };

  const getTargetGroup = (groupName) => {
    return allGroups[groupName];
  };

  const getUniqueTokenNames = () => {
    let tokenNames = [];

    let keysTemp = keys;
    if (!keysTemp) {
      keysTemp = Object.keys(allGroups);
      setKeys(keysTemp);
    }

    for (let i = 0; i < keysTemp.length; i++) {
      const token = allGroups[keysTemp[i]].token;
      if (!tokenNames.includes(token)) tokenNames.push(token);
    }
    return tokenNames;
  };

  const getPredictionIntervalSeconds = () => {
    if (!currentGroup && currentGroup !== 0) return 0;
    let keysTemp = keys;
    if (!keysTemp) {
      keysTemp = Object.keys(allGroups);
      setKeys(keysTemp);
    }

    const group = allGroups[keysTemp[currentGroup]];
    if (!group) return 0;
    return group.intervalSeconds;
  };

  const getGroupIntervalSeconds = (_groupName) => {
    const group = allGroups[_groupName];
    if (!group) return 0;
    return group.intervalSeconds;
  };

  return {
    getGroup,
    allGroups,
    getTargetGroup,
    getPredictionIdsArray,
    getUniqueTokenNames,
    getPredictionIntervalSeconds,
    getGroupIntervalSeconds,
  };
};

export default useGroup;
