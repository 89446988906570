import styles from "./Footer.module.css";
import classnames from "classnames";
import { useSelector, useDispatch } from "react-redux";
import logo from "../assets/NIOB-Token-Icon.ba84a2d1.svg";
import meta from "../assets/MetaMask-Icon.49a11e0f.svg";
import goTop from "../assets/scroll-up-icon.fab754f4.svg";

function Footer() {
  function numberWithCommas(x) {
    if (x && x != "") return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    else return null;
  }
  const niobObj = useSelector((state) => state.niobObj);
  const menuState = useSelector((state) => state.menuState);
  var Css = styles.menuOpen;
  if (menuState == false) {
    Css = styles.menuClose;
  }
  return (
    <div className={classnames(styles.total, Css)}>
      <div className={styles.col}>
        <img src={logo} className={styles.logo}></img>
        <span className={styles.niob}>NIOB</span>
      </div>

      <div className={classnames(styles.col, styles.col0)}>
        <img src={meta} className={styles.meta}></img>
        <button
          className={styles.button}
          onClick={() => {
            window.open(
              "https://niob.app/trade?tab=exchange&out-symbol=NIOB&in-symbol=BNB",
              "_self"
            );
          }}
        >
          Buy NIOB
        </button>
      </div>

      <div className={styles.col3}>
        <div className={styles.key}>Max supply:</div>
        <div className={styles.value}>
          {numberWithCommas(niobObj?.maxsupply)}&nbsp;NIOB - REACHED
        </div>
      </div>
      <div className={styles.col3}>
        <div className={styles.key}>Total Value Locked (TVL):</div>
        <div className={styles.value}>
          ${numberWithCommas(niobObj?.totalvaluelocked)}
        </div>
      </div>
      <img
        src={goTop}
        className={styles.goTop}
        onClick={() => {
          window.scrollTo({
            top: 0,
            behavior: "smooth",
            /* you can also use 'auto' behaviour
         in place of 'smooth' */
          });
        }}
      ></img>
    </div>
  );
}
export default Footer;
