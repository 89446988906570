import BigNumber from "bignumber.js";
import usePrice from "./usePrice";
import useMoralis from "./useMoralis";
import useGroup from "./useGroup";
import { useAccount } from "wagmi";
import rpcs from "../utils/constants/rpcs";
import { JsonRpcProvider } from "@ethersproject/providers";
const BIG_TEN = new BigNumber(10);

const useStats = () => {
  const { getGroup, getTargetGroup } = useGroup();
  const { getBettingTokenUSDPrice } = usePrice();
  const { address, isConnected } = useAccount();

  const getUserNativeTokenBalance = async () => {
    const groupData = getGroup();

    if (!groupData || !isConnected) return 0;

    try {
      const _provider = new JsonRpcProvider(rpcs[groupData.network].targetRpc);
      const result = await _provider.getBalance(address);

      const balance = new BigNumber(result.toString())
        .dividedBy(BIG_TEN.pow(18))
        .toFixed(2);

      return balance;
    } catch (err) {
      console.log("getBalance error", err);
      return 0;
    }
  };

  const getUserBettingTokenBalance = async () => {
    const balance = await getUserNativeTokenBalance();
    return balance;
  };

  return { getUserNativeTokenBalance, getUserBettingTokenBalance };
};

export default useStats;
