import { useSelector } from "react-redux";
import { ethers } from "ethers";
import { JsonRpcProvider } from "@ethersproject/providers";
import { useAccount } from "wagmi";
import BigNumber from "bignumber.js";
import rpcs from "../utils/constants/rpcs";
import useFetch from "./useFetch";
import useGroup from "./useGroup";
import scanlinks from "../utils/constants/scanlinks";
import ENUMS from "../utils/constants/enums";
import messages from "../utils/constants/messages";
import { createMessage, updateMessage, messageStatus } from "../utils/message";
const Network = ENUMS.Network;

const useExecuteContract = () => {
  const { address, isConnected, connector } = useAccount();
  const { getGroup } = useGroup();
  const { fetchWithCatch } = useFetch();
  const currentNetwork = useSelector((state) => state.network);

  const getCallContract = (address, abi, network = null) => {
    const _network = network !== null ? network : currentNetwork;
    if (_network === -1) return null;
    const rpc = rpcs[_network].targetRpc;
    const contract = new ethers.Contract(
      address,
      abi,
      new JsonRpcProvider(rpc)
    );

    return contract;
  };

  const getExecuteContract = async (address, abi) => {
    const signer = await connector.getSigner();
    let contract;
    if (isConnected) {
      contract = new ethers.Contract(address, abi, signer);
    }
    return contract;
  };

  //options= { contract, functionName: string, args: array, network: enum, getPromise: boolean}
  //options = { address, abi, functionName}
  const callContractWagmi = async (options) => {
    if (!options.address) return [{ error: "no address" }, null];
    if (!options.functionName) return [{ error: "no function defined" }, null];

    const contract = getCallContract(
      options.address,
      options.abi,
      options.network
    );
    if (!contract) return [{ error: "no contract" }, null];

    // console.log("callContractWagmi", options, contract);

    let call;
    const args = options.args || [];
    if (address) {
      call = contract.connect(address)[options.functionName](...args);
    } else call = contract[options.functionName](...args);

    if (options.getPromise) return call;

    try {
      const result = await call;
      return [null, result];
    } catch (err) {
      // console.log(options.functionName + ' call err', err)
      return [err, null];
    }
  };

  const sendContractTron = async (contract, data) => {
    const args = data.args ? data.args : [];
    try {
      const txOptions = { from: address };
      if (data.value > 0) {
        txOptions.callValue = data.value;
      }

      const tx = await contract[data.functionName](...args, txOptions);
      return { status: true, message: "success", txlink: getTxLink(tx) };
    } catch (error) {
      console.log(error);
      return { status: false, message: error.error, txlink: "" };
    }
  };

  const getErrorMessage = (err, functionName) => {
    if (
      err.message.includes("Internal JSON-RPC error") &&
      err.data &&
      err.data.message
    ) {
      err.message = err.data.message;
    }

    // console.log('ErrorMessage after JSON-RPC', err.message)

    let message;
    const errorIncludes = Object.keys(messages.errorIncludes);
    for (let i = 0; i < errorIncludes.length; i++) {
      if (err.message.includes(errorIncludes[i])) {
        message = messages.errorIncludes[errorIncludes[i]];
        break;
      }
    }

    if (!message) {
      message =
        messages.errorDefaults[functionName] || messages.globalDefaultError;
    }

    return message;
  };

  const sendContractWagmi = async (options) => {
    if (!options.address) return [{ error: "no address" }, null];
    if (!options.functionName) return [{ error: "no function defined" }, null];

    //console.log("sendContractWagmi is called", options.functionName, options);

    const contract = await getExecuteContract(options.address, options.abi);
    if (!contract) return [{ error: "no contract" }, null];

    const messageId = createMessage(options.functionName);

    const txOptions = { from: address };
    if (options.gasLimit) {
      txOptions.gasLimit = options.gasLimit;
    }
    if (options.value > 0) {
      txOptions.value = options.value;
    }
    if (!options.value) txOptions.value = "0";

    let err = null;
    const args = options.args ? options.args : [];

    //  console.log('sendContract contract', contract.provider)

    // console.log('sendContract contract', contract.provider.isBlockWallet)

    try {
      await contract.estimateGas[options.functionName](...args, txOptions);
    } catch (error) {
      //console.log("estimate error", error.message);
      const errorMessage = getErrorMessage(error, options.functionName);

      if (!options.skipErrorMessage) {
        updateMessage(messageId, messageStatus.error, errorMessage, null);
      }

      return {
        status: false,
        message: errorMessage,
        txlink: "",
        messageId,
      };
    }

    const network = options.network || currentNetwork;

    if (options.getGasPrice) {
      const amount = options.amount ? options.amount : 0;
      let _gasData;
      if (options.network)
        _gasData = await getGasPriceOfNetwork(options.network, amount);
      else _gasData = await getGasPrice(amount);

      txOptions.gasPrice = _gasData.gasPrice;
    }

    const info = options.info || messages.info[options.functionName];

    updateMessage(messageId, messageStatus.confirm, info, null);
    let tx;

    try {
      tx = await contract[options.functionName](...args, txOptions);
      updateMessage(
        messageId,
        messageStatus.pending,
        "Waiting for Confirmation on the Blockchain",
        null
      );

      await tx.wait();

      const message =
        messages.success[options.functionName] || messages.globalDefaultSuccess;

      // console.log("sendContractWagmi tx", tx);
      // console.log(
      //   "sendContractWagmi tx",
      //   messageId,
      //   message,
      //   getTxLink(tx.hash, options.network)
      // );

      if (!options.skipSuccessMessage) {
        updateMessage(
          messageId,
          messageStatus.succesfull,
          message,
          getTxLink(tx.hash, options.network)
        );
      }

      return {
        status: true,
        message: message,
        txlink: getTxLink(tx.hash, options.network),
        messageId,
      };
    } catch (error) {
      //console.log("cem err", error);
      err = error;

      const errorMessage = getErrorMessage(err, options.functionName);
      if (!options.skipErrorMessage) {
        updateMessage(messageId, messageStatus.error, errorMessage, null);
      }

      //console.log("cem errorMessage", errorMessage);

      return {
        status: false,
        message: errorMessage,
        txlink: "",
        messageId,
      };
    }
  };

  const getTxLink = (hash, _network = null) => {
    const network = _network ? _network : getGroup().network;
    //if(group.network === Network.TRON || group.network === Network.TRON_TESTNET)
    //    return scanlinks[group.network]+'#/transaction/'+hash;
    return scanlinks[network] + "tx/" + hash;
  };

  const getGasPriceOfNetwork = async (network, amount = 0) => {
    let api =
      "https://gbsc.blockscan.com/gasapi.ashx?apikey=key&method=gasoracle";

    if (network === Network.BSC_TESTNET) return "10000000000";
    else if (network === Network.POLYGON_TESTNET) return "30000000000";

    if (network === Network.POLYGON) {
      api =
        "https://gpoly.blockscan.com/gasapi.ashx?apikey=key&method=gasoracle";
    }

    const [err, dataJson] = await fetchWithCatch(api);
    if (err) {
      if (network === Network.BSC) return "5100000000";
      else if (network === Network.POLYGON) return "31000000000";
    }
    const gas = dataJson.result.FastGasPrice;
    const price = new BigNumber(gas).multipliedBy("1000000000");

    const gasPrice = price.toString();
    return { gasPrice };
  };

  const getGasPrice = async (amount = 0) => {
    const group = getGroup();
    const network = group.network;
    const gasData = await getGasPriceOfNetwork(network, amount);
    return gasData;
  };

  return {
    getTxLink,
    callContractWagmi,
    sendContractWagmi,
  };
};

export default useExecuteContract;
