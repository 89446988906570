//@ts-nocheck
import { configureChains, createClient } from "wagmi";
//import { BinanceWalletConnector } from "./binanceWalletConnector";
import { chains as _chains } from "./chains";
import { CoinbaseWalletConnector } from "wagmi/connectors/coinbaseWallet";
import { InjectedConnector } from "wagmi/connectors/injected";
import { MetaMaskConnector } from "wagmi/connectors/metaMask";
import { WalletConnectConnector } from "wagmi/connectors/walletConnect";
import { WalletConnectLegacyConnector } from "wagmi/connectors/walletConnectLegacy";
import { jsonRpcProvider } from "wagmi/providers/jsonRpc";

export const { provider, chains } = configureChains(_chains, [
  jsonRpcProvider({
    rpc: (chain) => {
      return { http: chain.rpcUrls.default };
    },
  }),
]);

export const injectedConnector = new InjectedConnector({
  chains,
  options: {
    shimDisconnect: false,
    shimChainChangedDisconnect: true,
  },
});

export const coinbaseConnector = new CoinbaseWalletConnector({
  chains,
  options: {
    appName: "PRDT",
    appLogoUrl: "https://prdt.finance/logo.png",
  },
});

export const walletConnectConnector =
  //new WalletConnectLegacyConnector({
  new WalletConnectConnector({
    chains,
    options: {
      qrcode: true,
      projectId: "8ec2c62ae3a3cb1f1d113d17c4c3d443",
    },
  });

// export const walletConnectNoQrCodeConnector = new WalletConnectConnector({
//   chains,
//   options: {
//     qrcode: false,
//   },
// });

export const metaMaskConnector = new MetaMaskConnector({
  chains,
  options: {
    shimDisconnect: false,
    shimChainChangedDisconnect: true,
  },
});

export const bscConnector = new InjectedConnector({
  options: {
    name: "Binance Wallet",
    getProvider: () =>
      typeof window !== "undefined" ? window.BinanceChain : undefined,
  },
});

export const client = createClient({
  autoConnect: true,
  provider,
  connectors: [
    metaMaskConnector,
    injectedConnector,
    coinbaseConnector,
    walletConnectConnector,
    bscConnector,
  ],
});

export const CHAIN_IDS = chains.map((c) => c.id);

//export const isChainSupported = memoize((chainId: number) => CHAIN_IDS.includes(chainId))
//export const isChainTestnet = memoize((chainId: number) => chains.find((c) => c.id === chainId)?.testnet)
