import { useEffect, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import styles from "./TVChart.module.css";
import datafeedFactory from "./datafeed";
import useWindowDimensions from "../../../utils/useWindowDimensions";
import { widget } from '../../../charting_library';
import { getTokenByName } from "./constants";

export const TVChart = ({ tokenName, chartKey }) => {
  const chartToken = getTokenByName(tokenName);
  const { height, width } = useWindowDimensions();
  const chartContainerRef = useRef();
  const tvWidgetRef = useRef(null);
  const tvWidget = tvWidgetRef.current;
  const isDarkTheme = true;
  /////////
  const refDatafeed = useRef(datafeedFactory());
  ///////
  // const symbolInterval = tvWidget?.symbolInterval()?.interval;
  const tokenPriceObj = useSelector((state) => state.tokenPrices[tokenName]);
  

  useEffect(() => {
    if (tvWidget) {
      tvWidget.activeChart().setChartType(1);
    }
  }, [tvWidget]);

  const overrides = useMemo(() => {
    return {
      "paneProperties.background": isDarkTheme ? "#0F1725" : "#FFFFFF",
      "paneProperties.backgroundType": "solid",
      ...(width <= 450
        ? { "scalesProperties.fontSize": 8 }
        : { "scalesProperties.fontSize": 12 }),
      priceScale: {
        precision: 4,
      },
      "scalesProperties.showStudyPlotLabels": true,
      "paneProperties.legendProperties.showStudyTitles": false,
      "paneProperties.legendProperties.showStudyValues": false,
      "mainSeriesProperties.lineStyle.color": "#5436AE",
    };
  }, [isDarkTheme, width]);

  useEffect(() => {
    if (tvWidget) {
      tvWidget?.changeTheme?.(isDarkTheme ? "dark" : "light").then(() => {
        tvWidget?.applyOverrides(overrides);
      });
    }
  }, [tvWidget, overrides]);

  useEffect(() => {
    if (tokenName && tokenPriceObj) {
      refDatafeed.current.update(tokenName, tokenPriceObj);
    }
  }, [tokenName, tokenPriceObj]);




  useEffect(() => {
    if (chartToken?.id) {
      tvWidget?.setSymbol?.(chartToken.id,5);
    }
  }, [chartToken]);

  useEffect(() => {
    if (chartToken && tokenPriceObj) {
        refDatafeed.current.update(chartToken, tokenPriceObj);
    }
    return () => {
      //cancelAnimationFrame(animationFrameId);
    };
  }, [chartToken, tokenPriceObj]);
  useEffect(() => {
    if (tvWidget) return;
    const widgetOptions = {
      symbol: chartToken?.id,
      datafeed: refDatafeed.current,
      interval: 5,
      container: chartContainerRef.current,
      library_path: "charting_library/",
      locale: "en",
      disabled_features: [
        "use_localstorage_for_settings",
        "header_symbol_search",
        "header_compare",
        "header_fullscreen_button",
        "header_saveload",
        "header_screenshot",
        "header_undo_redo",
      ],
      enabled_features: [
        "hide_left_toolbar_by_default",
        "iframe_loading_compatibility_mode",
      ],
      charts_storage_url: "https://saveload.tradingview.com",
      charts_storage_api_version: "1.1",
      client_id: "prdt.finance",
      user_id: "prdt.finance",
      fullscreen: false,
      autosize: true,
      studies_overrides: {},
      theme: isDarkTheme ? "dark" : "light",
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      overrides: {
        "paneProperties.background": isDarkTheme ? "#0F1725" : "#FFFFFF",
        "paneProperties.backgroundType": "solid",
        ...(width <= 450
          ? { "scalesProperties.fontSize": 8 }
          : { "scalesProperties.fontSize": 12 }),
        priceScale: {
          precision: 4,
        },
        "scalesProperties.showStudyPlotLabels": true,
        "paneProperties.legendProperties.showStudyTitles": false,
        "paneProperties.legendProperties.showStudyValues": false,
      },
      time_frames: [],
    };
    

    const _widget = new widget(widgetOptions);
    refDatafeed.current.resetChartData = () => {
      tvWidgetRef.current?.chart()?.resetData?.();
    };

    _widget.headerReady().then(function () {
      /*
            const button = _widget.createButton({ align: 'left' });
            button.setAttribute('title', 'Click to show a notification popup');
            button.classList.add('apppp');
            button.addEventListener('click', () => console.log(111));
            button.innerHTML = 'BTC-USDT';
      */
    });
    _widget.onChartReady(() => {
      tvWidgetRef.current = _widget;
      _widget.activeChart().getTimeScale().setRightOffset(15);
      _widget.activeChart().createStudy("UPEntryPrice", true, true);
      _widget.activeChart().createStudy("DOWNEntryPrice", true, true);
    });
    return () => {
      //downEntryPriceLineRef.current?.remove();
      // upEntryPriceLineRef.current?.remove();

      tvWidget?.remove();
      // downEntryPriceLineRef.current = null;
      //upEntryPriceLineRef.current = null;
      tvWidgetRef.current = null;
    };
  }, [chartToken]);
  ///////
  return <div ref={chartContainerRef} className={styles.container} />;
};
