export var groupData = {
  BTC: {
    ids: [0],
    oracle: false,
    noFactory: true,
    tokenCode: "BTC",
    network: 0,
    address: "0x3Df33217F0f82c99fF3ff448512F22cEf39CC208",
    showDecimal: 2,
    nativeBet: true,
    intervalSeconds: 300,
    bettingToken: "BNB",
    candlestickApi: "BINANCE",
    apiType: "BINANCE",
    token: "BTC",
    apicode: "BTCUSDT",
    isStock: false,
    codeBinance: "BTCUSDT",
  },
  BNB: {
    ids: [1001],
    oracle: false,
    noFactory: true,
    tokenCode: "BNB",
    network: 0,
    address: "0x31b8a8ee92961524fd7839dc438fd631d34b49c6",
    showDecimal: 1,
    nativeBet: true,
    intervalSeconds: 300,
    bettingToken: "BNB",
    candlestickApi: "BINANCE",
    apiType: "BINANCE",
    token: "BNB",
    apicode: "BNBUSDT",
    isStock: false,
    codeBinance: "BNBUSDT",
    codeGecko: "binancecoin",
    codeKucoin: "BNB-USDT",
  },
};

if (process.env.REACT_APP_TEST == "true") {
  groupData = {
    BNB: {
      ids: [2000],
      oracle: false,
      noFactory: true,
      tokenCode: "BNB",
      network: 0,
      address: "0x07cE71753A69fFA1118100b4D7CfBb1450D1E6Bb",
      showDecimal: 3,
      nativeBet: true,
      intervalSeconds: 300,
      bettingToken: "BNB",
      candlestickApi: "BINANCE",
      apiType: "KUCOIN",
      token: "BNB",
      apicode: "BNBUSDT",
      isStock: false,
      codeBinance: "BNBUSDT",
      codeGecko: "binancecoin",
      codeKucoin: "BNB-USDT",
    },
  };
}

export const loadGroupData = async () => {
  return groupData;
};

export const getGroupData = () => {
  return groupData;
};
