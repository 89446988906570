import { useSelector } from "react-redux";

const useCurrentGroupPrice = () => {
  const currentGroup = useSelector((s: any) => s.currentGroup);
  const tokenPrices = useSelector((s: any) => s.tokenPrices);
  if (
    currentGroup?.token &&
    tokenPrices &&
    tokenPrices[currentGroup.token]?.price > 0 &&
    tokenPrices[currentGroup.token]?.updateAt > 0 &&
    Date.now() - tokenPrices[currentGroup.token].updateAt < 60000
  ) {
    //console.log(Date.now() - tokenPrices[currentGroup.token].updateAt);
    return tokenPrices[currentGroup.token].price;
  }
  return 0;
};
export default useCurrentGroupPrice;
