import styles from "./Menu.module.css";
import icon1 from "../assets/icon1.svg";
import icon2 from "../assets/icon2.svg";
import icon3 from "../assets/icon3.svg";
import icon4 from "../assets/icon4.svg";
import icon5 from "../assets/icon5.svg";
import icon6 from "../assets/icon6.svg";
import icon7 from "../assets/icon7.svg";
import icon8 from "../assets/icon8.svg";
import icon9 from "../assets/icon9.svg";
import icon10 from "../assets/docs.svg";
import icon11 from "../assets/x.svg";
import circle1 from "../assets/circle1.svg";
import circle2 from "../assets/circle2.svg";
import circle3 from "../assets/circle3.svg";
import circle4 from "../assets/circle4.svg";
import circle6 from "../assets/circle6.svg";
import classnames from "classnames";
import logo from "../assets/NIOB-Token-Icon.ba84a2d1.svg";
import lang from "../assets/language-switcher-icon.da30697b.svg";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { TransformStream } from "./transformer.js";
import logo1 from "../assets/Group_11729.png";
function Menu(props) {
  const dispatch = useDispatch();
  const niobObj = useSelector((state) => state.niobObj);
  useEffect(() => {
    // TransformStream();

    if (document.documentElement.clientWidth < 960) {
      dispatch({ type: "menuStateChange", menuState: false });
    }
    const resizeListener = () => {
      if (document.documentElement.clientWidth < 960) {
        dispatch({ type: "menuStateChange", menuState: false });
      }
    };
    // set resize listener
    window.addEventListener("resize", resizeListener);

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener("resize", resizeListener);
    };
  }, []);

  const [selectedMenu, setSelectedMenu] = useState(9);
  var rectangleCss;
  switch (selectedMenu) {
    case 0: {
      rectangleCss = [
        null,
        styles.hide,
        styles.hide,
        styles.hide,
        styles.hide,
        styles.hide,
        styles.hide,
        styles.hide,
        styles.hide,
        styles.hide,
      ];
      break;
    }
    case 1: {
      rectangleCss = [
        styles.hide,
        null,
        styles.hide,
        styles.hide,
        styles.hide,
        styles.hide,
        styles.hide,
        styles.hide,
        styles.hide,
        styles.hide,
      ];
      break;
    }
    case 2: {
      rectangleCss = [
        styles.hide,
        styles.hide,
        null,
        styles.hide,
        styles.hide,
        styles.hide,
        styles.hide,
        styles.hide,
        styles.hide,
        styles.hide,
      ];
      break;
    }
    case 3: {
      rectangleCss = [
        styles.hide,
        styles.hide,
        styles.hide,
        null,
        styles.hide,
        styles.hide,
        styles.hide,
        styles.hide,
        styles.hide,
        styles.hide,
      ];
      break;
    }
    case 4: {
      rectangleCss = [
        styles.hide,
        styles.hide,
        styles.hide,
        styles.hide,
        null,
        styles.hide,
        styles.hide,
        styles.hide,
        styles.hide,
        styles.hide,
      ];
      break;
    }
    case 5: {
      rectangleCss = [
        styles.hide,
        styles.hide,
        styles.hide,
        styles.hide,
        styles.hide,
        null,
        styles.hide,
        styles.hide,
        styles.hide,
        styles.hide,
      ];
      break;
    }
    case 6: {
      rectangleCss = [
        styles.hide,
        styles.hide,
        styles.hide,
        styles.hide,
        styles.hide,
        styles.hide,
        null,
        styles.hide,
        styles.hide,
        styles.hide,
      ];
      break;
    }
    case 7: {
      rectangleCss = [
        styles.hide,
        styles.hide,
        styles.hide,
        styles.hide,
        styles.hide,
        styles.hide,
        styles.hide,
        null,
        styles.hide,
        styles.hide,
      ];
      break;
    }
    case 8: {
      rectangleCss = [
        styles.hide,
        styles.hide,
        styles.hide,
        styles.hide,
        styles.hide,
        styles.hide,
        styles.hide,
        styles.hide,
        null,
        styles.hide,
      ];
      break;
    }
    case 9: {
      rectangleCss = [
        styles.hide,
        styles.hide,
        styles.hide,
        styles.hide,
        styles.hide,
        styles.hide,
        styles.hide,
        styles.hide,
        styles.hide,
        null,
      ];
      break;
    }
  }

  //for close or open total menu
  const menuState = useSelector((state) => state.menuState);
  useEffect(() => {
    if (menuState == false) {
      setOpen1(false);
      setOpen2(false);
      setOpen3(false);
      setOpen4(false);
    }
  }, [menuState]);

  var spanCss = null;
  var menuCss = styles.openMenu;
  var spanCss2 = null;
  if (menuState == false) {
    spanCss = styles.hide;
    menuCss = styles.closeMenu;
    spanCss2 = styles.hide2;
  }
  //for close or open submenus
  //for trade
  const [open1, setOpen1] = useState(false);
  var submenu1 = null;
  var subRows1 = styles.hide;
  var arrowCss1 = [null, styles.hide];

  if (open1 == true) {
    submenu1 = styles.rowOpen;
    subRows1 = null;
    arrowCss1 = [styles.hide, null];
  }
  //for features
  const [open2, setOpen2] = useState(false);
  var submenu2 = null;
  var subRows2 = styles.hide;
  var arrowCss2 = [null, styles.hide];
  if (open2 == true) {
    submenu2 = styles.rowOpen;
    subRows2 = null;
    arrowCss2 = [styles.hide, null];
  }
  //for analytic
  const [open3, setOpen3] = useState(false);
  var submenu3 = null;
  var subRows3 = styles.hide;
  var arrowCss3 = [null, styles.hide];
  if (open3 == true) {
    submenu3 = styles.rowOpen;
    subRows3 = null;
    arrowCss3 = [styles.hide, null];
  }
  //for more
  const [open4, setOpen4] = useState(false);
  var submenu4 = null;
  var subRows4 = styles.hide;
  var arrowCss4 = [null, styles.hide];
  if (open4 == true) {
    submenu4 = styles.rowOpen;
    subRows4 = null;
    arrowCss4 = [styles.hide, null];
  }
  var visibleCss = null;
  var menuItemCss = null;
  if (props.visible == false) {
    visibleCss = styles.notVisible;
    menuItemCss = styles.notVisible2;
  }

  return (
    <div className={classnames(styles.menu, menuCss, visibleCss)}>
      <div className={classnames(styles.totalItems, menuItemCss)}>
        <div
          className={classnames(styles.row)}
          onClick={() => {
            setSelectedMenu(0);
          }}
        >
          <div className={classnames(styles.rectangle, rectangleCss[0])}></div>
          <div
            className={styles.col1}
            onClick={() => {
              window.open("https://niob.app/home", "_self");
            }}
          >
            <img className={styles.icon} src={icon1}></img>
            <span className={spanCss}>Homebase</span>
          </div>
        </div>
        <div
          className={classnames(styles.row)}
          onClick={() => {
            setSelectedMenu(9);
          }}
        >
          <div className={classnames(styles.rectangle, rectangleCss[9])}></div>
          <div
            className={classnames(styles.col1, styles.col1_1)}
            onClick={() => {
              window.open("https://prdt.niob.io", "_self");
            }}
          >
            <img className={styles.ourIcon} src={logo1}></img>
            <span className={spanCss}>Prediction Trading</span>
          </div>
        </div>
        <div
          className={classnames(styles.row, submenu1)}
          onClick={() => {
            setOpen1(!open1);
            setSelectedMenu(1);
          }}
        >
          <div className={classnames(styles.rectangle, rectangleCss[1])}></div>
          <div className={styles.col1}>
            <img className={styles.icon} src={icon2}></img>
            <span className={spanCss}>Trade</span>
          </div>
          <div className={spanCss}>
            <i
              className={classnames(
                "fas fa-angle-right",
                styles.arrow,
                arrowCss1[0]
              )}
            ></i>
            <i
              className={classnames(
                "fas fa-angle-down",
                styles.arrow,
                arrowCss1[1]
              )}
            ></i>
          </div>
        </div>
        <div className={classnames(styles.subRows, subRows1)}>
          <div
            className={classnames(styles.subRow, spanCss2)}
            onClick={() => {
              window.open("https://niob.app/trade/exchange", "_self");
            }}
          >
            Exchange
          </div>
          <div
            className={classnames(styles.subRow, spanCss2)}
            onClick={() => {
              window.open("https://niob.app/trade/liquidity", "_self");
            }}
          >
            Liquidity
          </div>
        </div>
        <div
          className={classnames(styles.row)}
          onClick={() => {
            setSelectedMenu(2);
          }}
        >
          <div className={classnames(styles.rectangle, rectangleCss[2])}></div>
          <div
            className={styles.col1}
            onClick={() => {
              window.open("https://niob.app/farmplanets/active", "_self");
            }}
          >
            <img className={styles.icon} src={icon3}></img>
            <span className={spanCss}>Farm Planets</span>
          </div>
        </div>
        <div
          className={classnames(styles.row)}
          onClick={() => {
            setSelectedMenu(3);
          }}
        >
          <div className={classnames(styles.rectangle, rectangleCss[3])}></div>
          <div
            className={styles.col1}
            onClick={() => {
              window.open("https://niob.app/poolgalaxy", "_self");
            }}
          >
            <img className={styles.icon} src={icon4}></img>
            <span className={spanCss}>Pool Galaxy</span>
          </div>
        </div>
        <div
          className={classnames(styles.row)}
          onClick={() => {
            setSelectedMenu(4);
          }}
        >
          <div className={classnames(styles.rectangle, rectangleCss[4])}></div>
          <div
            className={styles.col1}
            onClick={() => {
              window.open("https://niob.app/farmsv2", "_self");
            }}
          >
            <img className={styles.icon} src={icon3}></img>
            <span className={spanCss}>Farms V2</span>
          </div>
        </div>
        <div
          className={classnames(styles.row)}
          onClick={() => {
            setSelectedMenu(5);
          }}
        >
          <div className={classnames(styles.rectangle, rectangleCss[5])}></div>
          <div
            className={styles.col1}
            onClick={() => {
              window.open("https://niob.app/referral", "_self");
            }}
          >
            <img className={styles.icon} src={icon5}></img>
            <span className={spanCss}>Referral</span>
          </div>
        </div>
        <div
          className={classnames(styles.row)}
          onClick={() => {
            setSelectedMenu(6);
          }}
        >
          <div className={classnames(styles.rectangle, rectangleCss[6])}></div>
          <div
            className={styles.col1}
            onClick={() => {
              window.open("https://docs.niob.finance/audits/", "_blank");
            }}
          >
            <img className={styles.icon} src={icon6}></img>
            <span className={spanCss}>Audits</span>
          </div>
        </div>
        <div
          className={classnames(styles.row, submenu2)}
          onClick={() => {
            setOpen2(!open2);
            setSelectedMenu(7);
          }}
        >
          <div className={classnames(styles.rectangle, rectangleCss[7])}></div>
          <div className={styles.col1}>
            <img className={styles.icon} src={icon7}></img>
            <span className={spanCss}>Features</span>
          </div>
          <div className={classnames(styles.col2, spanCss)}>
            <i
              className={classnames(
                "fas fa-angle-right",
                styles.arrow,
                arrowCss2[0]
              )}
            ></i>
            <i
              className={classnames(
                "fas fa-angle-down",
                styles.arrow,
                arrowCss2[1]
              )}
            ></i>
          </div>
        </div>
        <div className={classnames(styles.subRows, subRows2)}>
          <div
            className={classnames(styles.subRow, spanCss2)}
            onClick={() => {
              window.open(
                "https://docs.niob.finance/products/#niob-sharing-season",
                "_blank"
              );
            }}
          >
            Niob-Sharing
          </div>
          <div
            className={classnames(styles.subRow, spanCss2)}
            onClick={() => {
              window.open(
                "https://docs.niob.finance/products/#prediction-trading",
                "_blank"
              );
            }}
          >
            Prediction Trading
          </div>
          <div
            className={classnames(styles.subRow, spanCss2)}
            onClick={() => {
              window.open(
                "https://docs.niob.finance/products/#niob-insurance-fund",
                "_blank"
              );
            }}
          >
            Insurance Fund
          </div>
          <div
            className={classnames(styles.subRow, spanCss2)}
            onClick={() => {
              window.open(
                "https://docs.niob.finance/products/#trading-fee-cashback",
                "_blank"
              );
            }}
          >
            Trading-Fee Cashback
          </div>
        </div>

        <div
          className={classnames(styles.row)}
          onClick={() => {
            setSelectedMenu(8);
          }}
        >
          <div className={classnames(styles.rectangle, rectangleCss[8])}></div>
          <div
            className={styles.col1}
            onClick={() => {
              window.open("https://docs.niob.finance/", "_blank");
            }}
          >
            <img className={styles.icon} src={icon10}></img>
            <span className={spanCss}>Docs</span>
          </div>
        </div>
        {menuState && (
          <div className={classnames(styles.socials, menuCss)}>
            <div className={styles.internalRow}>
              <div>
                <img src={logo} className={styles.logo}></img>
              </div>
              <div className={styles.price}>
                {niobObj?.tokenprice?.toFixed(4)}$
              </div>
            </div>
            <div className={classnames(styles.socialRow2, spanCss)}>
              <div
                onClick={() => {
                  window.open("https://niob.app/home", "_self");
                }}
              >
                <img src={circle1} className={styles.circle}></img>
              </div>

              <div
                onClick={() => {
                  window.open("https://niob.app/home", "_self");
                }}
              >
                <img src={circle3} className={styles.circle}></img>
              </div>
              <div
                onClick={() => {
                  window.open("https://twitter.com/niobfinance");
                }}
              >
                <img src={icon11} className={styles.circle}></img>
              </div>
              <div
                onClick={() => {
                  window.open("https://t.me/niobofficial");
                }}
              >
                <img src={circle6} className={styles.circle}></img>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
export default Menu;
