import classnames from "classnames";
import { useEffect, useState } from "react";
import CountUp from "react-countup";
import { useDispatch, useSelector } from "react-redux";
import logo from "../assets/Group_11747.png";
import binance from "../assets/binance-coin-bnb-logo-freelogovectors.net_.png";
import cherrif from "../assets/chiffre-IT-logo.png";
import logo1 from "../assets/logo1.1ef37445.png";
import predictions from "../assets/prediction.c8d1814d.png";
import usePrediction from "../core/hooks/usePrediction";
import { getGroupsByKey } from "../core/utils/networkUtils";
import { TokenIcon } from "../tokenIcon/tokenIcon";
import styles from "./Content.module.css";
import History from "./History.js";
import Slider from "./Slider.js";
import Top from "./Top.js";
import Tradingview from "./tradingview/tradingview";
import useCurrentGroupPrice from "./useCurrentGroupPrice";
import NewPrediction from "./prediction/NewPrediction";

function Content(props) {
  const dispatch = useDispatch();

  const {
    getMonthlyVolume /*, getCurrentRoundNo, hasStopped, getRoundLockTimestamp, getCurrentTimestamp*/,
  } = usePrediction();
  const [volume, setVolume] = useState(0);
  useEffect(() => {
    getMonthlyVolume().then((res) => {
      setVolume(res);
    });
  }, []);
  const currentGroup = useSelector((state) => state.currentGroup);


  const menuState = useSelector((state) => state.menuState);
  var mainCss = styles.closeMenu;
  if (menuState == true) mainCss = styles.openMenu;

  const menuItem = useSelector((state) => state.menuItem);

  function numberWithCommas(x) {
    if (x) return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    else return "0";
  }

  const selectAsset = (asset) => {
    dispatch({
      type: "groupChange",
      currentGroup: getGroupsByKey(asset),
    });
  };

  if (menuItem == 0)
    return (
      <div className={classnames(styles.total, mainCss)}>
        <Top />
        <div className={styles.firstRow}>
          <div className={styles.logoName}>
            <img src={logo} className={styles.logo}></img>
            <span className={styles.name}>Prediction Trading</span>
          </div>
          <div className={styles.chain}>
            <div className={styles.chainBox}>
              <img src={binance} className={styles.binance}></img>
              <span>Binance Smart Chain</span>
            </div>
          </div>
          <div className={styles.mounthAsset}>
            <div className={styles.mounthBox}>
              <span>Monthly Volume</span>
              <span className={styles.volume}>${numberWithCommas(volume)}</span>
            </div>
            {/* <div className={styles.mounthBox}>
              <div className={styles.assets}>
                <span>Asset</span>
                <div
                  onClick={() => selectAsset("BTC")}
                  className={classnames(
                    styles.icn,
                    currentGroup?.token == "BTC" && styles.selected
                  )}
                >
                  <TokenIcon tokenName="BTC" className={styles.assetLogo} />
                  <div className={styles.assetToken}>BTC</div>
                </div>
                <div
                  onClick={() => selectAsset("ETH")}
                  className={classnames(
                    styles.icn,
                    currentGroup?.token == "ETH" && styles.selected
                  )}
                >
                  <TokenIcon tokenName="ETH" className={styles.assetLogo} />
                  <div className={styles.assetToken}>ETH</div>
                </div>
                
              </div>
            </div> */}
          </div>
          {/* <div className={styles.timerPrice}>
            <Clock />
            <div className={styles.priceBox}>
              <TokenIcon
                tokenName={currentGroup?.token}
                className={styles.binance}
              />
              <span>{currentGroup?.token}</span>
              <div>
                <Price />
              </div>
            </div>
          </div> */}
        </div>
       <div className={styles.slider}>
          {/* <Slider /> */}
          <NewPrediction/>
        </div> 
        
        {/* <div className={styles.chartRow}>{<Tradingview />}</div> */}
        <div className={styles.imagesTotal}>
          <div className={styles.div1}>
            <div className={classnames(styles.poweredBy3)}>Powered by</div>
            <div className={styles.cherrifParrent}>
              <img src={cherrif} className={styles.cherrif}></img>
            </div>
          </div>
          <div className={styles.div2}>
            <div className={classnames(styles.poweredBy)}>
              In cooperation with
            </div>
            <div className={styles.power}>
              <img
                src={logo1}
                className={styles.logo1}
                onClick={() => {
                  window.open("https://prdt.finance/", "_blank");
                }}
              ></img>
              <img
                src={predictions}
                className={styles.predictions}
                onClick={() => {
                  window.open("https://prdt.finance/", "_blank");
                }}
              ></img>
            </div>
          </div>
        </div>
      </div>
    );
  else
    return (
      <div className={classnames(styles.total, mainCss)}>
        <Top />
        <div className={styles.firstRow}>
          <div className={styles.logoName}>
            <img src={logo} className={styles.logo}></img>
            <span className={styles.name}>Transaction History</span>
          </div>
          <div className={styles.chain}>
            <div className={styles.chainBox}>
              <img src={binance} className={styles.binance}></img>
              <span>Binance Smart Chain</span>
            </div>
            <div className={classnames(styles.chainBox, styles.topmargin)}>
              <div className={styles.assets}>
                <span>Assets</span>
                <div
                  onClick={() => selectAsset("BTC")}
                  className={classnames(
                    styles.icn,
                    currentGroup?.token == "BTC" && styles.selected
                  )}
                >
                  <TokenIcon tokenName="BTC" className={styles.assetLogo} />
                  <div className={styles.assetToken}>BTC</div>
                </div>
                <div
                  onClick={() => selectAsset("ETH")}
                  className={classnames(
                    styles.icn,
                    currentGroup?.token == "ETH" && styles.selected
                  )}
                >
                  <TokenIcon tokenName="ETH" className={styles.assetLogo} />
                  <div className={styles.assetToken}>ETH</div>
                </div>
                {/* <div
                  onClick={() => selectAsset("JPY")}
                  className={classnames(
                    styles.icn,
                    currentGroup?.token == "JPY" && styles.selected
                  )}
                >
                  <TokenIcon tokenName="JPY" className={styles.assetLogo} />
                  <div className={styles.assetToken}>JPY</div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.detailes}>
          Explore your past rounds from the prediction market for information,
          claiming or refunding canceled rounds.
        </div>
        <History />
        <div className={styles.imagesTotal}>
          <div className={styles.div1}>
            <div className={classnames(styles.poweredBy3)}>Powered by</div>
            <div className={styles.cherrifParrent}>
              <img src={cherrif} className={styles.cherrif}></img>
            </div>
          </div>
          <div>
            <div className={classnames(styles.poweredBy)}>
              In cooperation with
            </div>
            <div className={styles.power}>
              <img
                src={logo1}
                className={styles.logo1}
                onClick={() => {
                  window.open("https://prdt.finance/", "_blank");
                }}
              ></img>
              <img
                src={predictions}
                className={styles.predictions}
                onClick={() => {
                  window.open("https://prdt.finance/", "_blank");
                }}
              ></img>
            </div>
          </div>
        </div>
      </div>
    );
}
export default Content;

function Price() {
  const currentGroup = useSelector((state) => state.currentGroup);
  const price = useCurrentGroupPrice();
  return price > 0 ? (
    <>
      {currentGroup.currencySymbol}
      <CountUp
        preserveValue={true}
        end={price}
        duration={1}
        decimals={2}
        separator=","
        decimal="."
      />
    </>
  ) : (
    <></>
  );
}

const Clock = () => {
  const timerTick = useSelector((state) => state.timerTick);
  const timer = useSelector((state) => state.roundLockTimestamp);
  var time = Math.max(0, Math.round(timer - Date.now() / 1000));

  const h = Math.floor(time / 3600);
  time = time % 3600;
  const m = Math.floor(time / 60);
  const s = time % 60;

  var timerStr =
    h > 0
      ? h.toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        }) + ":"
      : "";
  timerStr +=
    m.toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    }) + ":";

  timerStr += s.toLocaleString("en-US", {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });
  const currentGroup = useSelector((state) => state.currentGroup);

  return (
    <div className={styles.timeParrent}>
      <div className={styles.timeBox}>
        {m.toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        })}
      </div>
      <span>:</span>
      <div className={styles.x}>
        <span className={styles.totalTime}>
          {currentGroup?.intervalSeconds / 60}m
        </span>
        <div className={styles.timeBox}>
          {s.toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          })}
        </div>
      </div>
    </div>
  );
};
