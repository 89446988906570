import store from "../redux/Store";
import { v4 as uuid } from "uuid";

const messageStatus = {
  initiate: 0,
  confirm: 1,
  pending: 2,
  succesfull: 3,
  error: 4,
};

const createMessage = (methodName) => {
  const messages = store?.getState()?.messages ?? [];
  const id = uuid();
  const timestamp = Date.now();
  store.dispatch({
    type: "setMessages",
    messages: [
      ...messages,
      {
        id: id,
        createTimestamp: timestamp,
        status: messageStatus.initiate,
        methodName: methodName,
        link: null,
        message: null,
        title: null
      },
    ],
  });
  return id;
};
const updateMessage = (id, status, message, link , title) => {
  const messages = store?.getState()?.messages ?? [];
  if (id && status) {
    const timestamp = Date.now();
    const newMessages = messages.map((msg) =>
      msg.id == id
        ? {
            ...msg,
            status: status,
            link: link,
            message: message,
            title:title,
            lastUpdateTimestamp: timestamp,
          }
        : { ...msg }
    );
    store.dispatch({
      type: "setMessages",
      messages: [...newMessages],
    });
    return true;
  }
  return false;
};
const deleteMessage = (id) => {
  const messages = store?.getState()?.messages ?? [];
  if (id) {
    const newMessages = messages.filter((msg) => msg.id != id);
    store.dispatch({
      type: "setMessages",
      messages: [...newMessages],
    });
    return true;
  }
  return false;
};
export { createMessage, updateMessage, messageStatus, deleteMessage };
