import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";
import { useNetwork, useSwitchNetwork } from "wagmi";
import styles from "./App.module.css";
import Content from "./application/Content";
import Footer from "./application/Footer";
import Header from "./application/Header.js";
import Menu from "./application/Menu.js";
import SetupSlider from "./application/SetupSlider";
import { transformStream } from "./application/transformer";
import { getGroupsByKey } from "./core/utils/networkUtils";
import { getChainByInternalID } from "./wagmi/chains";
import SetupHistory from "./application/SetupHistory";
import Messages from "./application/Message/Messages";

function App() {
  //transformStream();
  const { switchNetwork } = useSwitchNetwork();
  const { chain } = useNetwork();
  const network = useSelector((state) => state.network);
  useEffect(() => {
    const targetChain = getChainByInternalID(network);
    if (switchNetwork && targetChain && chain?.id !== targetChain.id) {
      switchNetwork?.(targetChain.id);
    }
  }, [network, switchNetwork]);

  useEffect(() => {
    fetch("https://apin.niob.app/api/tokenreport?symbol=NIOB")
      .then((res) => {
        //console.log(res);
        if (res.status == 200) {
          res.json().then((obj) => {
            dispatch({ type: "niobObjChange", niobObj: obj.success });
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: "networkChange", network: 0 });
    dispatch({
      type: "groupChange",
      currentGroup: getGroupsByKey("BTC"),
    });
  }, []);

  const [visible, setVisible] = useState(true);
  useEffect(() => {
    window.addEventListener("scroll", listenToScroll, { passive: true });
  }, []);

  var pre = 0;
  var scroll = 0;

  function listenToScroll() {
    pre = scroll;
    scroll = window.pageYOffset;

    if (scroll < 150 || scroll <= pre) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  }
  return (
    <>
      <Messages />
      <SetupSlider />
      <SetupHistory />
      <ReactTooltip place="bottom" class={styles.tooltip} html={true} />
      <Header visible={visible} />
      <div className={styles.main}>
        <Menu visible={visible} />
        <Content visible={visible} />
      </div>
      <Footer />
    </>
  );
}

export default App;
