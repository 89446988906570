import styles from "./LiveRound.module.css";
import classNames from "classnames";
import {ReactComponent as DownIcon} from "../../../assets/dashboard/down.svg";
import {ReactComponent as  UpIcon} from "../../../assets/dashboard/up.svg";
import {ReactComponent as  LeftIcon} from "../../../assets/dashboard/left1.svg";
import {ReactComponent as  UIcon} from "../../../assets/dashboard/up.svg";
import {ReactComponent as  DIcon} from "../../../assets/dashboard/down.svg";
import { useDispatch, useSelector } from "react-redux";
import { getTokenIconName } from "../../../tokenIcon/tokenIcon";
import { useAccount } from "wagmi";
import useCurrentGroupPrice from "../../useCurrentGroupPrice";
import Loading from "../../Loading/Loading";

function numberWithCommas(x:any) {
  if (x && x != "") {
    if (x.toString().split(".").length == 2)
      return (
        x
          .toString()
          .split(".")[0]
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
        "." +
        x.toString().split(".")[1]
      );
    else return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else return null;
}

const Round = ({ roundData }: { roundData: any }) => {
  const dispatch = useDispatch();
  ///
  let different: any;
  let isEntered = false;
  let locked;
  let pool;
  let position;
  ////
  const currentGroup = useSelector((state:any) => state.currentGroup);
  const num = useSelector((state:any) => state.num);
  const calculating = useSelector((state:any) => state.calculating);
  const paused = useSelector((state:any) => state.paused);
  const currentIcon = getTokenIconName(currentGroup.token);
  const { address } = useAccount();
  let currentPrice = useCurrentGroupPrice();
  var decimal = currentGroup?.showDecimal;
  let lastPrice = numberWithCommas(currentPrice);
  roundData = roundData.roundData
  if (roundData) {
    locked = numberWithCommas(Number(roundData?.lockPrice).toFixed(decimal));
    
    pool = numberWithCommas(roundData.totalAmount);

    if (currentPrice != null && currentPrice > 0) {
      different = currentPrice - roundData.lockPrice;
      different = Number(different).toFixed(decimal);
    }
    if (roundData.userBet && roundData.userBet.originalAmount > 0) {
      isEntered = true;
      position = roundData.userBet.position;
    }
  }

  if (different != null && different != undefined) {
    if (different > 0)
      document.title =
        "PRDT | " + different + " \u2191 Bet on the price of Cryptos";
    else
      document.title =
        "PRDT | " + different + " \u2193 Bet on the price of Cryptos";
  }
  return (
    <section className={styles.liveRound}>
      <div className={styles.top}>
        <div className={classNames(styles.lf, "center")}>
          <LeftIcon
            className={styles.lfi}
            onClick={() => dispatch({type:'showLastRound',showLastRound:true})}
          />
        </div>
        <div className={styles.dot} />
        <div>Live Round</div>
        <div className={styles.tm}>
          <TimerValue /> 
        </div>
      </div>
      <div
        className={classNames(
          styles.content,
          different > 0
            ? styles.greenBg
            : different < 0
            ? styles.redBg
            : styles.grayBg
        )}
      >
        <div className={styles.row}>
          <div>
            <div className={styles.t1}>LIVE PRICE</div>
            <div className={classNames(styles.t2, styles.pr, styles.m1)}>
              {/* <TokenIcon
                tokenName={currentGroup?.token}
                className={styles.img}
              /> */} 
              <img src={currentIcon} className={styles.tokenIcon}/>
              <div className={styles.lp}>${lastPrice}</div>
            </div>
          </div>
          <div>
            <div className={styles.t1}>STRIKE PRICE</div>
            <div className={classNames(styles.t2, styles.m1)}>${locked}</div>
          </div>

          <div className={classNames("row", styles.xrw)}>
            <div>
              <UIcon
                className={classNames(
                  styles.uicon,
                  different > 0 ? styles.green : styles.gray
                )}
              />
              <div className={styles.m1}>
                {roundData?.payoutUp ? "X" + roundData?.payoutUp : ""}
              </div>
            </div>
            <div>
              <DIcon
                className={classNames(
                  styles.dicon,
                  different < 0 ? styles.red : styles.gray
                )}
              />
              <div className={styles.m1}>
                {roundData?.payoutDown ? "X" + roundData?.payoutDown : ""}
              </div>
            </div>
          </div>
        </div>
        <div className={classNames("rowsp", styles.m2)}>
          <div className={classNames(styles.chn)}>
            {different > 0 && (
              <UpIcon className={classNames(styles.icn, styles.green)} />
            )}
            {different < 0 && (
              <DownIcon className={classNames(styles.icn, styles.red)} />
            )}
            <span className={classNames(styles.t3, styles.fw)}>
              ${different}
            </span>
            <span
              className={classNames(
                styles.t4,
                different > 0
                  ? styles.green
                  : different < 0
                  ? styles.red
                  : styles.gray
              )}
            >
              {different < 0 ? "Below" : different > 0 ? "Above" : "Same Price"}
            </span>
          </div>
          <div className={styles.pzp}>
            PRIZE POOL: {pool} {currentGroup?.bettingToken}
          </div>
        </div>
      </div>
      {isEntered && (
        <div className={styles.r2}>
          <div
            className={classNames(
              styles.entered,
              position == 0 && styles.eupBg,
              position == 1 && styles.edownBg
            )}
          >
            <span className={styles.t6}>Entered</span>
            <span className={styles.t7}>
              X {position == 0 ? roundData?.payoutUp : roundData?.payoutDown}
            </span>
            {position == 0 && (
              <UpIcon className={classNames(styles.icn2, styles.green)} />
            )}
            {position == 1 && (
              <DownIcon className={classNames(styles.icn2, styles.red)} />
            )}
          </div>

          <div>
            <div className={styles.t1}>DIRECTION</div>
            <div className={styles.t5}>{position == 0 ? "UP" : "DOWN"}</div>
          </div>
          <div>
            <div className={styles.t1}>POSITION SIZE</div>
            <div className={styles.t5}>
              {numberWithCommas(Number(roundData?.userBet?.amount).toFixed(2))}{" "}
              {currentGroup?.bettingToken}
            </div>
          </div>
        </div>
      )}

      {calculating  && (
        <div className={styles.animDiv}>
          {/* <LoadingAnimation width="9rem" height="9rem" /> */}
          <Loading iconSize={20} loaderSize={55}/>
        </div>
      )}
    </section>
  );
};

const TimerValue = () => {
  const timerTick = useSelector((state:any) => state.timerTick);
  const timer = useSelector((state:any) => state.roundLockTimestamp);

  var time = Math.max(0, Math.round(timer - Date.now() / 1000));

  const h = Math.floor(time / 3600);
  time = time % 3600;
  const m = Math.floor(time / 60);
  const s = time % 60;

  var timerStr =
    h > 0
      ? h.toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        }) + ":"
      : "";
  timerStr +=
    m.toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    }) + ":";

  timerStr += s.toLocaleString("en-US", {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });
  return <div>{timerStr} min</div>;
};

export default Round;
