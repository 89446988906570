import { useSelector } from 'react-redux'
import usePrediction from './usePrediction'
import useExecuteContract from './useExecuteContract'
import contracts from '../utils/constants/contracts'
import referralABI from '../utils/constants/ABI/referral.json'
import decimals from '../utils/constants/decimals'
import useSoloPrediction from './useSoloPrediction'
import soloPredictionABI from '../utils/constants/ABI/soloPrediction.json'
import BigNumber from 'bignumber.js'
import { useAccount } from 'wagmi'
import { createMessage, updateMessage, messageStatus } from '../utils/message'
const BIG_TEN = new BigNumber(10)

const useReference = () => {
  const { getGroupAddressAndABI } = usePrediction()
  const { getBettingTokenName } = useSoloPrediction()
  const { callContractWagmi, sendContractWagmi } = useExecuteContract()
  const { address: userAddress } = useAccount()
  const currentNetwork = useSelector((state) => state.network)

  const approveReferralSystem = async () => {
    const result = await sendContractWagmi({
      address: contracts['reference'][currentNetwork],
      abi: referralABI,
      functionName: 'enableAddress',
      getGasPrice: true,
      network: currentNetwork
    })
    return result
  }

  const hasApprovedReferralSystem = async (refCode = null) => {
    const address = refCode || userAddress
    const [err, result] = await callContractWagmi({
      address: contracts['reference'][currentNetwork],
      abi: referralABI,
      functionName: 'userExistence',
      args: [address]
    })

    if (err) return false
    return result
  }

  const getReferredUsers = async () => {
    const [err, referredUsers] = await callContractWagmi({
      address: contracts['reference'][currentNetwork],
      abi: referralABI,
      functionName: 'getReferredUsers',
      args: [userAddress]
    })
    if (err) return null
    return referredUsers
  }

  const getReferredUsersCount = async () => {
    const referredUsers = await getReferredUsers(userAddress)
    return referredUsers.length
  }

  const claimReferenceBonus = async (_groupName) => {
    const addrAndABI = getGroupAddressAndABI(_groupName)

    const options = {
      ...addrAndABI,
      functionName: 'claimReferenceBonus',
      getGasPrice: true,
      network: currentNetwork
    }
    const result = await sendContractWagmi(options)

    return result
  }

  const claimProReferenceBonus = async (_bettinTokenAddress) => {
    const options = {
      address: contracts.soloPrediction[currentNetwork],
      abi: soloPredictionABI,
      functionName: 'claimReferenceBonus',
      args: [_bettinTokenAddress],
      getGasPrice: true,
      network: currentNetwork
    }
    const result = await sendContractWagmi(options)
    return result
  }

  const getClaimableReferenceBonusOfPrediction = async (groupName) => {
    const addrAndABI = getGroupAddressAndABI(groupName)

    const [err, totalReferenceBonus] = await callContractWagmi({
      ...addrAndABI,
      functionName: 'userReferenceBonuses',
      args: [userAddress],
      network: currentNetwork
    })
    if (err) return 0

    const bonus = new BigNumber(totalReferenceBonus.toString())
      .dividedBy(BIG_TEN.pow(18))
      .toFixed(2)

    return bonus
  }

  const getProClaimableReferenceBonus = async (_bettingTokenAddress) => {
    const options = {
      address: contracts.soloPrediction[currentNetwork],
      abi: soloPredictionABI,
      functionName: 'userReferenceBalances',
      args: [userAddress, _bettingTokenAddress],
      network: currentNetwork
    }
    const [err, totalReferenceBonus] = await callContractWagmi(options)
    if (err) return 0

    const tokenName = getBettingTokenName(_bettingTokenAddress)
    const tokenDecimals = decimals[currentNetwork][tokenName] || 18
    const balance = new BigNumber(totalReferenceBonus.toString())
      .dividedBy(BIG_TEN.pow(tokenDecimals))
      .toFixed(2)
    return balance
  }

  const addReference = async (referenceCode) => {
    const codeEnabled = await hasApprovedReferralSystem(referenceCode)
    if (!codeEnabled) {
      const messageId = createMessage('addReference')
      updateMessage(
        messageId,
        messageStatus.error,
        'reference code is not enabled',
        null
      )
      return { status: false, message: 'reference code is not enabled' }
    }

    const options = {
      abi: referralABI,
      address: contracts['reference'][currentNetwork],
      functionName: 'setReferrer',
      args: [referenceCode],
      getGasPrice: true,
      network: currentNetwork
    }
    const result = await sendContractWagmi(options)

    return result
  }

  const getReferrer = async () => {
    const [err, referrer] = await callContractWagmi({
      address: contracts['reference'][currentNetwork],
      abi: referralABI,
      functionName: 'getReferrer',
      args: [userAddress],
      network: currentNetwork
    })
    if (err || referrer === '0x0000000000000000000000000000000000000000')
      return null
    return referrer
  }

  const isReferenceLocked = async () => {
    const [err, result] = await callContractWagmi({
      address: contracts['reference'][currentNetwork],
      abi: referralABI,
      functionName: 'userLocked',
      args: [userAddress],
      network: currentNetwork
    })
    if (err) return true
    return result
  }

  return {
    approveReferralSystem,
    claimReferenceBonus,
    getReferredUsers,
    hasApprovedReferralSystem,
    getReferredUsersCount,
    getClaimableReferenceBonusOfPrediction,
    getProClaimableReferenceBonus,
    addReference,
    getReferrer,
    claimProReferenceBonus,
    isReferenceLocked
  }
}

export default useReference
