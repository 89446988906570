import useGroup from "./useGroup";
import useMoralis from "./useMoralis";
import useFetch from "./useFetch";
import axios from "axios";
import { useState } from "react";
const api = process.env.REACT_APP_API_URI || "https://api.prdt.finance";

const usePrice = () => {
  const { getGroup, allGroups } = useGroup();
  const { fetchWithCatch } = useFetch();
  const [isUS, setIsUS] = useState(false);

  const getCurrentPrice = async (c = null) => {
    const price = await getPriceByGroup(getGroup());
    return price;
  };

  const getTokenPrice = async (tokenName) => {
    if (!tokenName) return { price: 0 };

    const groupValues = Object.values(allGroups);
    const group = groupValues.find((g) => g.token == tokenName);

    let price = 0;

    if (group) {
      price = await getPriceByGroup(group);
    } else {
      //failssafe for fake tokens
      let binanceapi = "";
      if (tokenName == "CAKE") binanceapi = "CAKEUSDT";
      else if (tokenName == "BNB") binanceapi = "BNBUSDC";
      else if (tokenName == "ETH") binanceapi = "ETHUSDC";
      else if (tokenName == "MATIC") binanceapi = "MATICUSDT";
      else if (tokenName == "XVS") binanceapi = "XVSUSDT";

      price = await getPriceBINANCE(binanceapi);
    }

    return price;
  };

  const getPriceFromBackend = async (apiCode, apiType = null) => {
    const token = apiType == "FOREX" ? apiCode : apiCode.slice(0, -4);

    try {
      const result = await axios.post(
        `${api}/api/v1/price/latest`,
        {
          token,
        },
        { withCredentials: true }
      );
      return { price: parseFloat(result.data) };
    } catch (err) {
      return { price: null };
    }
  };

  const getPriceBINANCE = async (binanceapi) => {
    if (!binanceapi) return { price: 0 };
    if (isUS) {
      const price = await getPriceFromBackend(binanceapi);
      return { price: parseFloat(price) };
    }

    const [err1, data] = await fetchWithCatch(
      `https://api.binance.com/api/v3/ticker/price?symbol=${binanceapi}`
    );
    if (data && data.msg && data.msg.includes("Service unavailable")) {
      setIsUS(true);
    }
    if (
      err1 ||
      (data && data.msg && data.msg.includes("Service unavailable"))
    ) {
      const price = await getPriceFromBackend(binanceapi);
      return { price: parseFloat(price) };
    }
    return { price: parseFloat(data.price) };
  };

  const getPriceByGroup = async (_groupData) => {
    let price;
    if (_groupData.apiType == "BINANCE")
      price = await getPriceBINANCE(_groupData.apicode);
    else if (_groupData.apiType == "FOREX")
      price = await getPriceFromBackend(_groupData.apicode, _groupData.apiType);
    return price;
  };

  const getBettingTokenUSDPrice = async (bettingTokenName) => {
    let bettingTokenUSDPrice;
    if (
      bettingTokenName === "USDC" ||
      bettingTokenName === "USDT" ||
      bettingTokenName === "VAI"
    ) {
      bettingTokenUSDPrice = 1;
    } else {
      const tokenPrice = await getTokenPrice(bettingTokenName);
      bettingTokenUSDPrice = tokenPrice.price;
    }

    return bettingTokenUSDPrice;
  };

  return {
    getPriceByGroup,
    getCurrentPrice,
    getBettingTokenUSDPrice,
    getPriceBINANCE,
  };
};

export default usePrice;
