import React from "react";
import styles from "./LiveRound.module.css";
import classNames from "classnames";
// import useCurrentGroupPrice from "../../slider/useCurrentGroupPrice";
import { useSelector } from "react-redux";
// import { numberWithCommas, roundDown } from "@/lib/libs";
// import { TokenIcon } from "@/components/tokenIcon/tokenIcon";
// import useCurrentTime from "@/hooks/useCurrentTime";
import PausedState from "./PausedState";
import Calculating from "./Calculating";
import CountUp from "react-countup";
import {ReactComponent as TriangleSVG} from "../../../assets/dashboard/triangle.svg";
import { getTokenIconName } from "../../../tokenIcon/tokenIcon";
import { useAccount } from "wagmi";
import { groupData } from "../../../utils/constants/groupData.js";
import vai from "../../../assets/7824.png";
import BANANA from "../../../assets/BANANA.svg";
import icon1 from "../../../assets/image_16.png";
import icon9 from "../../../assets/matic-network-matic-logo-600x600.png";
import paused2 from "../../../assets/paused2.png";
import usicon from "../../../assets/usdc.png";
import useCurrentGroupPrice from "../../useCurrentGroupPrice";
import { isEmpty } from "../../../core/utils/libs";
import { getDateAndTimeFromTimestamp } from "../../../core/utils/dateandtime";
import Tradingview from "../../tradingview/tradingview";
import Chart from "../chart/Chart";

// import Chart from "../chart/Chart";

type Props = { data: any };

function numberWithCommas(x:any) {
  if (x && x != "") {
    if (x.toString().split(".").length == 2)
      return (
        x
          .toString()
          .split(".")[0]
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
        "." +
        x.toString().split(".")[1]
      );
    else return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else return null;
}

const ProgressBar = () => {
  const timerTick = useSelector((state:any) => state.timerTick);
  const timer = useSelector((state:any) => state.roundLockTimestamp);

  const currenctGroup = useSelector((state:any) => state.currentGroup);
  var time = Math.max(0, Math.round(timer - Date.now() / 1000));

  var w =
    currenctGroup?.intervalSeconds > 0 && time > 0
      ? (time / currenctGroup.intervalSeconds) * 100
      : 0;

  return (
    <div
      className={styles.progressbar}
      style={{
        backgroundColor: "#5436AE",
        width: `${100 - w}%`,
        height: "2.5px",
        position:"absolute"
      }}
    ></div>
  );
};

function LiveRound({ data }: Props) {
  let isEntered = false;

  let position: any;


  const feePercent = 5; // fee for entering the prediction round

  let roundData:any={}
  
  if(!isEmpty(data)){
    roundData = data.roundData
  }
  


  // //////////////////////////////////////

  const currentGroup = useSelector((state:any) => state.currentGroup);
  const num = useSelector((state:any) => state.num);
  const calculating = useSelector((state:any) => state.calculating);
  const paused = useSelector((state:any) => state.paused);
  const currentIcon = getTokenIconName(currentGroup.token);
  const { address } = useAccount();
  const lastPrice = useCurrentGroupPrice();

  const prices = useSelector((state:any)=>state.tokenPrices);
  

  var different = 0;
  var locked = null;
  var last = null;
  var pool = null;
  var payoutdown = null;
  var payoutup = null;

  // //////////////////////////////////////

  const currentPrice = lastPrice;

  var decimal = currentGroup?.showDecimal;

  if (roundData) {
    locked = Number(roundData.lockPrice).toFixed(decimal);

    pool = roundData.totalAmount;
    payoutdown = roundData.payoutDown;
    payoutup = roundData.payoutUp;

    if (currentPrice != null && currentPrice > 0) {
      different = currentPrice - roundData.lockPrice;
      different = Number((different).toFixed(decimal));
    }
    if (roundData.userBet && roundData.userBet.originalAmount > 0) {
      isEntered = true;
      position = roundData.userBet.position;
    }

    if (lastPrice != null && lastPrice > 0) {
      different = lastPrice - roundData.lockPrice;
  
      different = Number((different).toFixed(decimal));
      last = Number(lastPrice).toFixed(decimal);
  
      if (Number(different) > 0)
        document.title =
          "PRDT | " + different + " \u2191 Bet on the price of Cryptos";
      else
        document.title =
          "PRDT | " + different + " \u2193 Bet on the price of Cryptos";
    }

    var bettingIcon = icon1;
  if (currentGroup?.bettingToken == "BNB") bettingIcon = icon1;
  else if (currentGroup?.bettingToken == "USDC") bettingIcon = usicon;
  else if (currentGroup?.bettingToken == "VAI") bettingIcon = vai;
  else if (currentGroup?.bettingToken == "MATIC") bettingIcon = icon9;
  else if (currentGroup?.bettingToken == "BANANA") bettingIcon = BANANA;

  }

  const getTotalPayount = () => {
    if (!roundData) {
      return "";
    }

    let multiplier = position == 0 ? roundData.payoutUp : roundData.payoutDown;

    let _totalPredictAmount = Number(roundData?.userBet?.amount);
    // We need to deduct fee
    let _totalFee = (feePercent / 100) * _totalPredictAmount;

    _totalPredictAmount = _totalPredictAmount - _totalFee;

    let amountToRetrun = _totalPredictAmount * Number(multiplier);


    return parseFloat(amountToRetrun.toFixed(4));
  };

  const getStyle = () => {
    if (
      currentGroup?.token &&
      currentGroup?.token.toString().toUpperCase() == "BTC"
    ) {
      return styles.btcWidth;
    } else {
      return styles.minTokenPriceWidth;
    }
  };

  return (
    <div className={styles.liveRound}>
      {paused ? (
        <PausedHeader />
      ) : (
        <LiveHeader roundNo={roundData?.roundNo || ""} />
      )}
      <div className={styles.body}>
        {/* Upper part will be for the bet details */}
        <>
          {paused ? (
            <>
              <PausedState isExpanded={isEntered} />
            </>
          ) : calculating ? (
            <Calculating isExpanded={isEntered} />
          ) : (
            <>
              <ProgressBar />

              <div className={styles.predict}>
                <div
                  className={classNames(
                    styles.predictDetails,
                    isEntered
                      ? ""
                      : different > 0
                      ? styles.bgPatternGreen
                      : different == 0
                      ? styles.bgPatternNeutral
                      : styles.bgPatternRed
                  )}
                >
                  <div
                    className={classNames(
                      styles.predictDetailsTop,
                      !isEntered
                        ? ""
                        : different > 0
                        ? styles.bgPatternGreen
                        : different == 0
                        ? styles.bgPatternNeutral
                        : styles.bgPatternRed
                    )}
                  >
                    <div className={styles.detail}>
                      <div className={styles.label}>Live Price</div>
                      <div className={styles.value}>
                        <div className={styles.tokenData}>
                          <span className={getStyle()}>
                            ${" "}
                            <CountUp
                              preserveValue={true}
                              end={currentPrice}
                              duration={1}
                              separator=","
                              decimals={decimal}
                              decimal="."
                            />
                          </span>
                          <span>
                          <img src={currentIcon} className={styles.tokenIcon}/>
                          </span>
                        </div>

                        <div className={styles.betStatus}>
                          {different != 0 && (
                            <PricePredictionSmallArrow
                              className={
                                different > 0 ? styles.won : styles.lostTriangle
                              }
                            />
                          )}
                          <div className={styles.betValue}>
                            ${different > 0 ? `+${different}` : different}
                          </div>
                          <div
                            className={classNames(
                              styles.betDirection,
                              different > 0 ? styles.won : styles.lost
                            )}
                          >
                            <div>
                              {different > 0
                                ? "Above"
                                : different < 0 && "Below"}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className={styles.detail}>
                      <div className={styles.label}>Prize Pool</div>
                      <div className={classNames(styles.value, styles.bold)}>
                        {pool} {currentGroup?.bettingToken}
                      </div>
                    </div>
                    <div className={styles.detail}>
                      <div className={styles.label}>Strike Price</div>
                      <div className={styles.value}>${locked}</div>
                    </div>
                    <div className={styles.detail}>
                      <div className={styles.label}>Time Stamp</div>
                      <div className={styles.value}>
                        {getDateAndTimeFromTimestamp(roundData?.lockTimestamp)}
                        
                      </div>
                    </div>
                  </div>
                  {isEntered && (
                    <div
                      className={classNames(
                        styles.predictDetailsBottom,
                        isEntered ? "" : styles.hide
                      )}
                    >
                      <div className={styles.detail}>
                        <button
                          className={classNames(
                            styles.enteredBtn,
                            position == 0 && styles.upBtn,
                            position == 1 && styles.downBtn
                          )}
                        >
                          <span>Entered</span>
                          <span>
                            <b>
                              {position == 0
                                ? "UP"
                                : position == 1
                                ? "DOWN"
                                : ""}
                            </b>
                          </span>
                        </button>
                      </div>
                      <div className={styles.detail}>
                        <div className={styles.label}>Multiplier</div>
                        <div className={styles.value}>
                          X
                          {position == 0
                            ? roundData?.payoutUp
                            : roundData?.payoutDown}
                        </div>
                      </div>
                      <div className={styles.detail}>
                        <div className={styles.label}>Position size</div>
                        <div className={styles.value}>
                          {numberWithCommas(
                            parseFloat(
                              Number(roundData?.userBet?.amount).toFixed(3)
                            )
                          )}{" "}
                          {currentGroup?.bettingToken}
                        </div>
                      </div>
                      <div className={styles.detail}>
                        <div className={styles.label}>Payout</div>
                        <div className={styles.value}>
                          {getTotalPayount()} {currentGroup?.bettingToken}
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                {/* initialBetPosition is classname before user predicts */}

                <div
                  className={classNames(
                    styles.betPosition
                    // !isEntered && styles.initialBetPosition
                  )}
                >
                  <div
                    className={classNames(styles.position, styles.topPosition)}
                  >
                    <PricePredictionBigArrow
                      direction="up"
                      className={different > 0 ? styles.won : styles.neutral}
                      isExpanded={isEntered}
                    />
                    <span>X{roundData?.payoutUp}</span>
                  </div>
                  <div
                    className={classNames(
                      styles.position,
                      styles.bottomPosition
                    )}
                  >
                    <PricePredictionBigArrow
                      direction="down"
                      className={different < 0 ? styles.lost : styles.neutral}
                      isExpanded={isEntered}
                    />
                    <span>X{roundData?.payoutDown}</span>
                  </div>
                </div>
              </div>
            </>
          )}
        </>

        {/* Lower part for the chart */}

<div className={styles.chart}>
  <Chart className={styles.livechart} />
   
   {/* <Tradingview className={''}/> */}

  </div>
       
      </div>
    </div>
  );
}

const LiveHeader = ({ roundNo }: { roundNo: string }) => {
  return (
    <div className={styles.header}>
      <div className={styles.headerDetail}>
        <div className={styles.liveCircle}></div>
        <div className={styles.title}>Live Round</div>
        <div className={styles.roundId}>#{roundNo}</div>
      </div>
      <div className={styles.timeLeft}>
        <TimerValue />  
      </div>
    </div>
  );
};

const PausedHeader = () => {
  return (
    <div className={styles.header}>
      <div className={styles.headerDetail}>
        {/* <div className={styles.pausedImg}>
          <img src={"/images/dashboard/PausedMarket.png"} alt="pausedState" />
        </div> */}
        <div className={styles.title}>Market Paused</div>
      </div>
      <div className={styles.timeLeft}>
      <TimerValue />  
      </div>
    </div>
  );
};

// won,lost state
const PricePredictionSmallArrow = ({ className }: { className: string }) => {
  return (
    <div className={classNames(styles.triangle, className)}>
      <TriangleSVG />
    </div>
  );
};

const PricePredictionBigArrow = ({
  className,
  direction,
  isExpanded,
}: {
  className: string;
  direction: "up" | "down";
  isExpanded: boolean;
}) => {
  return (
    <div
      className={classNames(
        styles.bigtriangle,
        className,
        isExpanded && styles.bigtriangleExpanded,
        direction === "up" ? styles.upTriangle : styles.downTriangle
      )}
    >
      <TriangleSVG />
    </div>
  );
};

const TimerValue = () => {
  const timerTick = useSelector((state:any) => state.timerTick);
  const timer = useSelector((state:any) => state.roundLockTimestamp);

  
  var time = Math.max(0, Math.round(timer - Date.now() / 1000));

  const h = Math.floor(time / 3600);
  time = time % 3600;
  const m = Math.floor(time / 60);
  const s = time % 60;

  var timerStr =
    h > 0
      ? h.toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        }) + ":"
      : "";
  timerStr +=
    m.toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    }) + ":";

  timerStr += s.toLocaleString("en-US", {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });

  let _temp = timerStr.split(":");
  if (_temp.length < 2) {
    return <></>;
  }
  let toReturnStr = `${_temp[0]} min ${_temp[1]} sec`;
  return <div>{toReturnStr}</div>;
};

export default LiveRound;
