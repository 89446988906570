import Apollo from './Apollo'
import React from 'react'
import store from '../redux/Store'
import { Provider } from 'react-redux'

const PRDTCore = (props) => {
  return (
    <Provider store={store}>
      <Apollo>{props.children} </Apollo>
    </Provider>
  )
}
export default PRDTCore
