import styles from "./Messages.module.css";
import { ReactComponent as ImgClose } from "../../assets/close2.svg";
import { ReactComponent as ImgConfirm } from "../../assets/confirm.svg";
import { ReactComponent as ImgError } from "../../assets/error.svg";
import { useSelector } from "react-redux";
import classnames from "classnames";
import { Key, useEffect, useState } from "react";
import { deleteMessage, messageStatus } from "../../core";

const Messages = () => {
  const c = {
    createTimestamp: 1671779463699,
    id: "3cecd01b-b4c3-4429-8591-6561d570a436",
    lastUpdateTimestamp: 1671779467144,
    link: "https://google.com",
    message: null,
    methodName: "enter",
    status: 1,
  };
  const list = [c];
  const messages: [] = useSelector((state: any) => state.messages);
  return (
    <div className={styles.over}>
      <div className={styles.messages}>
        {messages
          .filter((message: any) => message.status != messageStatus.initiate)
          .map((message: any, id) => (
            <Message key={message.id} message={message} />
          ))}
      </div>
    </div>
  );
};

const Message = ({ message }: { message: any }) => {
  const [close, setClose] = useState<boolean>(false);
  const network = useSelector((state: any) => state.network);

  useEffect(() => {
    //////auto close///////
    var timer: any;
    if (
      message.status == messageStatus.succesfull ||
      message.status == messageStatus.error
    ) {
      timer = setTimeout(onClose, 3000);
    }
    return () => clearTimeout(timer);
  }, [message]);

  const onOpenLink = () => {
    if (message?.link) {
      window.open(message.link);
    }
  };
  ////////////////////
  const onClose = () => {
    setClose(true);
    setTimeout(() => {
      deleteMessage(message.id);
    }, 1000);
  };
  ////////////////////
  var colorStyle = null;
  var title = null;
  var msg = null;
  var link = null;
  var icon = null;
  if (message.status == messageStatus.confirm) {
    colorStyle = styles.pending;
    title = "Please confirm transaction";
    msg = message.message;
    link = null;
    icon = <ImgConfirm className={styles.icon} />;
  } else if (message.status == messageStatus.pending) {
    colorStyle = styles.pending;
    title = "Transaction Pending";
    msg = message.message ?? "Waiting ...";
    link = null;
    icon = <ImgConfirm className={styles.icon} />;
  } else if (message.status == messageStatus.succesfull) {
    colorStyle = styles.confirmed;
    title = message.title ?? "Transaction Confirmed";
    msg = message.message;
    if (message?.link) {
      link = "View on BsC Scan";
    } else {
      link = null;
    }
    icon = <ImgConfirm className={styles.icon} />;
  } else if (message.status == messageStatus.error) {
    colorStyle = styles.error;
    title = "Transaction Failed";
    msg = message.message;
    link = null;
    icon = <ImgError className={styles.icon} />;
  }
  ///////////
  return (
    <div className={classnames(styles.message, close && styles.closeMessage)}>
      <div className={classnames(styles.c1, colorStyle)}>{icon}</div>
      <div className={styles.c2}>
        <div className={styles.c3}>
          <div className={styles.t1}>{title}</div>
          <div className={styles.t2}>{msg}</div>
          <div className={styles.t3} onClick={onOpenLink}>
            {link}
          </div>
        </div>
        <Progress msgStatus={message.status} colorStyle={colorStyle} />
      </div>
      {message.status != messageStatus.confirm && (
        <ImgClose className={styles.closeBtn} onClick={onClose} />
      )}
    </div>
  );
};

const Progress = ({
  msgStatus,
  colorStyle,
}: {
  msgStatus: any;
  colorStyle: string | null;
}) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    var interval: any;
    if (msgStatus == messageStatus.succesfull) {
      setProgress(100);
    } else if (msgStatus == messageStatus.pending) {
      interval = setInterval(() => {
        setProgress((progress) => {
          var newVal = progress + 0.25;
          return newVal <= 100 ? newVal : 0;
        });
      }, 50);
    } else {
      setProgress(0);
    }
    return () => clearInterval(interval);
  }, [msgStatus]);

  return (
    <div
      className={classnames(styles.progress, colorStyle)}
      style={{ width: progress + "%" }}
    />
  );
};

export default Messages;
