import classnames from "classnames";
import styles1 from "./History.module.css";
//import Chart from "./Chart.js";
import { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import medal from "../assets/medal.png";

import image1 from "../assets/animation_500_l01y4fgo.gif";

import { useAccount } from "wagmi";
import usePrediction from "../core/hooks/usePrediction";

function History() {
  const [loadMoreProgress, setLoadMoreProgress] = useState(false);
  const [counter, setCounter] = useState(6);
  const onLoadMore = () => {
    setCounter(counter + 6);
  };

  const handleClick = () => {
    setLoadMoreProgress(true);
    setTimeout(() => {
      onLoadMore();
      setLoadMoreProgress(false);
    }, 1000);
  };

  const { claim } = usePrediction();
  var styles = styles1;
  var divider = 10;

  const [selectedIndex, setSelectedIndex] = useState(0);
  const currentGroup = useSelector((state) => state.currentGroup);
  const userHistory = useSelector((state) => state.userHistory);
  const userOldHistory = useSelector((state) => state.userOldHistory);
  const bettingToken = currentGroup?.bettingToken;
  const { address } = useAccount();
  const [str, setStr] = useState("Collect");
  const [disable, setDisable] = useState(false);
  const [radio, setRadio] = useState("1");
  ///////////////
  var history = null;
  var oldHistory = null;
  if (currentGroup?.predictionId >= 0 && userHistory && userOldHistory) {
    history = userHistory?.[currentGroup?.predictionId];
    oldHistory = userOldHistory?.[currentGroup?.predictionId];
  }
  ////////
  var _state = 0;
  if (address == null || address == undefined) {
    _state = 1;
  } else if (userHistory == null || userOldHistory == null) {
    _state = 2;
  } else if (history.numRounds == 0) {
    _state = 3;
  } else {
    _state = 4;
  }
  //////////////////
  var bets = [];
  var numWon = 0;
  var numLoss = 0;

  if (history) {
    //numWon += history.won;
    //numLoss += history.lost;
    for (var i = history.numRounds - 1; i >= 0; i--) {
      history.rounds[i].groupData = currentGroup;
      bets.push(history.rounds[i]);
    }
  }
  if (oldHistory) {
    //numWon += oldHistory.won;
    // numLoss += oldHistory.lost;
    for (var i = oldHistory.numRounds - 1; i >= 0; i--) {
      bets.push(oldHistory.rounds[i]);
    }
  }

  var buttons = [];
  var totalbets = bets.length;
  for (var i = 0; i < Number(totalbets / divider); i++)
    buttons.push(
      <Button
        key={i}
        index={i.toString()}
        fakeIndex={i.toString()}
        selectedIndex={selectedIndex}
        f={setSelectedIndex}
      />
    );

  var netEarning = null;
  var netEarningUSD = null;
  var average = null;
  var averageUSD = null;
  var best = null;
  var bestUSD = null;
  var avgBet = null;
  var avgBetUSD = null;

  /////////
  if (history != null) {
    netEarning = history.netEarning;
    netEarningUSD = /*history.bettingTokenUSDPrice **/ history.netEarning;
    netEarning = Number(netEarning).toFixed(2);
    netEarningUSD = Number(netEarningUSD).toFixed(2);
    average = history.avgReturn;
    average = Number(average).toFixed(2);
    averageUSD = history.avgReturn; /* *history.bettingTokenUSDPrice*/
    averageUSD = Number(averageUSD).toFixed(2);
    best = history.bestRound;
    best = Number(best).toFixed(2);
    bestUSD = history.bestRound; /* * history.bettingTokenUSDPrice*/
    bestUSD = Number(bestUSD).toFixed(2);
    avgBet = history.avgBet;
    avgBet = Number(avgBet).toFixed(2);
    avgBetUSD = history.avgBet; /** history.bettingTokenUSDPrice*/
    avgBetUSD = Number(avgBetUSD).toFixed(2);
  }

  const dispatch = useDispatch();
  const menuState = useSelector((state) => state.menuState);
  var newCss = null;
  if (menuState == false) {
    newCss = styles.newChoose2;
  }

  const options = {
    labels: ["Win", "Loss"],
    legend: {
      show: false,
    },
    plotOptions: {
      pie: {
        donut: {
          dataLabels: {
            color: ["#ffffff", "#ffffff"],
          },

          labels: {
            name: {
              color: "#ffffff",
            },
            value: {
              color: "#ffffff",
            },
            color: "#ffffff",
            show: true,
            total: {
              color: "#ffffff",
              show: true,
              label: "Total",
              formatter: function (w) {
                return w.globals.seriesTotals.reduce((a, b) => {
                  return a + b;
                }, 0);
              },
            },
          },
        },
      },
    },
    fill: {
      colors: ["#FFFFFF", "#A64083"],
    },
    dataLabels: {
      enabled: false,
    },
  };
  const series = [numWon, numLoss]; //our data
  var overStr = ["All", "Collected", "Uncollected"];
  var over = [styles.over0, styles.over1, styles.over2];
  var showButtons = <>{buttons}</>;
  if (buttons && buttons.length > 10)
    showButtons = (
      <>
        {buttons.slice(0, 5)}...&nbsp;
        {buttons.slice(buttons.length - 5, buttons.length)}
      </>
    );
  return (
    <div className={styles.dash}>
      <AutoRefersh />

      {_state == 1 && (
        <div className={styles.row1}>Please connect to see your history!</div>
      )}
      {_state == 2 && (
        <div id="anim" className={classnames(styles.anim)}>
          <img src={image1} className={styles.animImg}></img>
        </div>
      )}
      {_state == 3 && (
        <div className={classnames(styles.row1)}>
          You do not have any transaction for this prediction.
        </div>
      )}
      {_state == 4 && (
        <div className={classnames(styles.row)}>
          <div className={styles.c1_1}>
            <div className={classnames(styles.newChoose, newCss)}>
              <div className={classnames(styles.over, over[radio - 1])}>
                {overStr[radio - 1]}
              </div>
              <div
                className={styles.newCol}
                onClick={() => {
                  setSelectedIndex(0);
                  setRadio("1");
                }}
              >
                All
              </div>
              <div
                className={styles.newCol}
                onClick={() => {
                  setSelectedIndex(0);
                  setRadio("2");
                }}
              >
                Collected
              </div>
              <div
                className={styles.newCol}
                onClick={() => {
                  setSelectedIndex(0);
                  setRadio("3");
                }}
              >
                Uncollected
              </div>
            </div>

            {bets
              .filter((d) => {
                if (radio == "1") {
                  return true;
                }
                if (radio == "2" && d.claimed) {
                  return true;
                }

                if (
                  radio == "3" &&
                  (d.isRefundable == true || (!d.claimed && d.won == true))
                ) {
                  return true;
                }
                return false;
              })
              .slice(selectedIndex * divider, (selectedIndex + 1) * divider)
              .map((d, ix) => (
                <Box
                  key={ix}
                  round={d.roundNo}
                  result={Number(d.priceDiff).toFixed(3)}
                  claim={d.claimed}
                  won={d.won}
                  roundData={d}
                />
              ))}

            <div className={styles.buttonParrent}>
              <i
                className={classnames(
                  "fas fa-angle-double-left",
                  styles.arrow,
                  styles.arrowLeft,
                  bets.length == 0 && styles.hide
                )}
                onClick={() => {
                  if (selectedIndex > 0) setSelectedIndex(selectedIndex - 1);
                }}
              ></i>
              {showButtons}
              <i
                className={classnames(
                  "fas fa-angle-double-right",
                  styles.arrow,
                  bets.length == 0 && styles.hide
                )}
                onClick={() => {
                  if (selectedIndex < Number(bets.length / divider) - 1)
                    setSelectedIndex(selectedIndex + 1);
                }}
              ></i>
            </div>
            <div id="container"></div>
            <div className={styles.row11col}>
              <div className={styles.c1_11}>
                <div className={styles.collectAll}>
                  <div className={styles.csspan}>
                    <button
                      className={styles.caButton}
                      disabled={disable}
                      onClick={() => {
                        setStr("Collecting");
                        setDisable(true);
                        dispatch({
                          type: "setOnCollect",
                          onCollect: "Collecting",
                        });

                        claim(history?.claimableRounds).then((result) => {
                          setDisable(false);
                          setStr("Collect");
                          dispatch({
                            type: "setOnCollect",
                            onCollect: "Collect",
                          });
                          if (result.status == true) {
                            dispatch({ type: "setRefreshUserHistory" });
                          }
                        });
                      }}
                    >
                      {str}
                    </button>
                    <span className={styles.myspan}>
                      {history?.totalClaimable}&nbsp; {bettingToken}&nbsp;{" "}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.c2_2}>
            <div className={styles.boxes}>
              <div className={styles.box1}>
                <div className={styles.chartBox}>
                  <div className={styles.chartRow}>
                    <div
                      className={classnames(styles.chartCol, styles.chartCol1)}
                    >
                      <div className={styles.chartStyle}>
                        {/*<Chart numWon={numWon} numLoss={numLoss} />*/}
                        <Chart
                          options={options}
                          series={series}
                          type="donut"
                          width="150%"
                        />
                      </div>
                    </div>
                    <div
                      className={classnames(styles.chartCol, styles.chartCol2)}
                    >
                      <div className={styles.boxHeader}>Rounds Won</div>
                      {/*<div className={styles.boxText}>See your total number of users referred</div>*/}
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.box1}>
                <div className={styles.statBox}>
                  <div className={styles.His}>Your History</div>
                  <div className={styles.statRow}>
                    <div className={styles.statCol}>
                      <div className={styles.statHeader}>NET RESULT</div>
                      <div className={styles.statText}>$ {netEarningUSD} </div>
                    </div>
                    <div className={styles.statCol}>
                      <div className={styles.statHeader}>Best Round</div>
                      <div className={styles.statText}>$ {bestUSD} </div>
                    </div>
                  </div>
                  <div className={styles.statRow2}>
                    {" "}
                    <div
                      className={classnames(
                        styles.statHeader,
                        styles.statHeader2
                      )}
                    >
                      Average position entered/Round
                    </div>
                    <div className={styles.statText}>$ {avgBetUSD}</div>
                  </div>
                  <div className={styles.statRow2}>
                    <div className={styles.statHeader}>
                      Average return per Round
                    </div>
                    <div className={styles.statText}>$ {averageUSD} </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div> &nbsp; &nbsp; </div>
    </div>
  );
}
export default History;
function Button(props, state) {
  var styles = styles1;

  var css = null;
  if (props.selectedIndex == Number(props.index)) css = styles.buttonsBack;
  return (
    <button
      className={classnames(styles.buttons, css)}
      onClick={() => {
        props.f(Number(props.index));
      }}
    >
      {Number(props.fakeIndex) + 1}
    </button>
  );
}

function Box(props, state) {
  const { getRoundData, claimGroup } = usePrediction();

  var styles = styles1;

  const [str, setStr] = useState("");
  const [css, setCss] = useState(null);
  const [css2, setCss2] = useState(null); //
  const [css4, setCss4] = useState(styles.hide);
  const [css3, setCss3] = useState(false); //active or inactive
  const [flag1, setFlag1] = useState(null); //for arrow
  const [flag2, setFlag2] = useState(styles.hide); //for arrow
  const [history, setHistory] = useState(null);
  const [direction, setDirection] = useState("");
  const [str2, setStr2] = useState(""); //for closing price
  const [directionCss, setDirectionCss] = useState(null);
  const [closingCss, setClosingCss] = useState(null);
  const dispatch = useDispatch();
  var closePrice;
  var lockedPrice;
  if (history != null) {
    closePrice = history.closePrice;
    closePrice = Number(closePrice).toFixed(2);
    lockedPrice = history.lockPrice;
    lockedPrice = Number(lockedPrice).toFixed(2);
  }

  useEffect(() => {
    if (props.roundData.position == 0) {
      setDirection("Up");
      setDirectionCss(styles.blue);
    } else {
      setDirection("Down");
      setDirectionCss(styles.orange);
    }

    if (props.result > 0) {
      setCss(styles.num);
    } else {
      setCss(styles.num2);
    }

    if (props.roundData.isRefundable == true) {
      setCss2(styles.btn4);
      setCss3(false);
      setStr("Refund");
      setCss4(styles.hide);
    } else {
      if (!props.won && !props.claim) {
        if (props.roundData.ongoingRound) {
          setStr("Ongoing Round");
          setCss4(styles.hide);
          setCss3(true);
          setCss2(styles.btn4);
        } else if (props.roundData.waitingForRefundCheck) {
          setStr("Calculating Refund");
          setCss4(styles.hide);
          setCss3(true);
          setCss2(styles.btn4);
        } else {
          setCss2(styles.btn2);
          setStr("Lost");
          setCss3(true);
          setCss4(styles.hide);
        }
      } else {
        setCss4(null);
        if (props.won == false) setCss4(styles.hide);
        if (props.claim == true) {
          setCss2(styles.btn3);
          setStr("Collected");
          setCss3(true);
        } else {
          setCss2(styles.btn1);
          setStr("Collect");
          setCss3(false);
        }
      }
    }
  }, [props.roundData]);
  useEffect(() => {
    if (history) {
      var difference = history.closePrice - history.lockPrice;
      difference = Number(difference).toFixed(2);
      if (difference > 0) {
        setStr2(difference);
        setClosingCss(styles.blue);
      } else {
        setStr2(difference);
        setClosingCss(styles.orange);
      }
    }
  }, [history]);
  return (
    <div className={classnames(styles.box, "font_5", "font_8_s")}>
      <div className={styles.row11}>
        <div className={styles.col11}>
          <div
            className={classnames(
              styles.round,
              "font_5",
              "font_8_s",
              "font_8_m"
            )}
          >
            <div>
              Round
              {"   " + props.round}
            </div>
          </div>
        </div>
        <div className={styles.col12}>
          {!props.roundData.ongoingRound && (
            <div
              className={classnames(
                styles.resnum,
                "font_5",
                "font_8_s",
                "font_8_m"
              )}
            >
              <div>
                Result
                <span className={css}>{"   " + props.result}</span>
              </div>
            </div>
          )}
        </div>
        <div className={styles.col14}>
          <img src={medal} className={classnames(styles.medal, css4)}></img>
        </div>
        <div className={styles.col13}>
          <button
            className={classnames(
              styles.button,
              "font_5",
              "font_8_s",
              "font_8_m",
              css2
            )}
            disabled={css3}
            onClick={() => {
              var round = [props.round];
              setStr("Collecting");
              dispatch({ type: "setOnCollect", onCollect: "Collecting" });
              claimGroup(round, props.roundData.groupData).then((result) => {
                if (result.status == false) {
                  setCss2(styles.btn1);
                  setStr("Collect");
                  setCss3(false);
                  dispatch({ type: "setOnCollect", onCollect: "Collect" });
                } else {
                  setCss2(styles.btn3);
                  setStr("Collected");
                  setCss3(true);
                  dispatch({ type: "setOnCollect", onCollect: "Collected" });
                  dispatch({ type: "setRefreshUserHistory" });
                }
              });
            }}
          >
            {str}
          </button>
        </div>
        <div
          className={styles.col15}
          onClick={() => {
            if (flag1 == null) {
              setFlag1(styles.hide);
              setFlag2(null);

              getRoundData(props.round).then((obj) => {
                setHistory(obj);
              });
            } else {
              setFlag2(styles.hide);
              setFlag1(null);
            }
          }}
        >
          <i className={classnames("fas fa-angle-down", flag1)}></i>
          <i className={classnames("fas fa-angle-up", flag2)}></i>
        </div>
      </div>
      <div className={flag2}>
        <div className={classnames(styles.row11)}>
          <div className={styles.c1}>
            <div className={styles.box2}>
              <div className={styles.row111}>
                <div className={styles.c11}>
                  <span className={styles.head}>Your History</span>
                </div>
                <div className={styles.c22}></div>
              </div>
              <div className={styles.row111}>
                <div className={styles.c11}>Your Direction</div>
                <div className={styles.c22}>
                  <button
                    className={classnames(styles.direction, directionCss)}
                  >
                    Entered {direction}
                  </button>
                </div>
              </div>
              <div className={styles.row111}>
                <div className={styles.c11}>Your Position</div>
                <div className={styles.c22}>{props.roundData?.amount}</div>
              </div>
              {!props.roundData.ongoingRound && (
                <div className={styles.row111}>
                  <div className={styles.c11}>Your Winnings</div>
                  <div className={styles.c22}>{props.roundData?.amountWon}</div>
                </div>
              )}
              <div className={styles.row111}>
                <div className={styles.c11}>Referee Bonus</div>
                <div className={styles.c22}>
                  {props.roundData?.refereeAmount}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.c2}>
            <div className={styles.box3}>
              <div className={styles.row111}>
                <div className={styles.c11}>
                  <span className={styles.head}>Your History</span>
                </div>
                <div className={styles.c22}></div>
              </div>
              {!props.roundData.ongoingRound && (
                <div className={styles.row111}>
                  <div className={styles.c11}>Closing Price</div>
                  <div className={styles.c22}>
                    {closePrice}&nbsp;&nbsp;&nbsp;
                    <button className={classnames(styles.closing, closingCss)}>
                      {str2}
                    </button>
                  </div>
                </div>
              )}
              <div className={styles.row111}>
                <div className={styles.c11}>Locked Price</div>
                <div className={styles.c22}>{lockedPrice}</div>
              </div>
              {!props.roundData.ongoingRound && (
                <div className={styles.row111}>
                  <div className={styles.c11}>Price Pool</div>
                  <div className={styles.c22}>{history?.totalAmount}</div>
                </div>
              )}
              <div className={styles.row111}>
                <div className={styles.c11}>&nbsp;&nbsp;</div>
                <div className={styles.c22}>&nbsp;&nbsp;</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
const AutoRefersh = () => {
  const dispatch = useDispatch();
  const timerTick = useSelector((state) => state.timerTick);
  useEffect(() => {
    dispatch({ type: "setRefreshUserHistory" });
  }, []);
  useEffect(() => {
    if (timerTick % 5 == 0) {
      dispatch({ type: "setRefreshUserHistory" });
    }
  }, [timerTick]);
  return <></>;
};
