import contracts from "../utils/constants/contracts.js";
import leadershipPrizeABI from "../utils/constants/ABI/leadershipPrizeABI";
import useExecuteContract from "./useExecuteContract.js";
import { useAccount } from "wagmi";
import axios from "axios";

const api = process.env.REACT_APP_API_URI || "https://api.prdt.finance";

const useLeadership = () => {
  const { address: userAddress } = useAccount();
  const { callContractWagmi, sendContractWagmi } = useExecuteContract();

  const getPrizeAmount = async () => {
    if (!userAddress) return 0;
    const [err, result] = await callContractWagmi({
      address: contracts["leadership"][0],
      abi: leadershipPrizeABI,
      functionName: "balanceBNB",
      args: [userAddress],
      network: 0,
    });
    if (err) return 0;
    return result;
  };

  const havePrize = async () => {
    const prizeAmount = await getPrizeAmount();
    return prizeAmount > 0;
  };

  const claimPrize = async () => {
    if (!userAddress) return;
    const result = await sendContractWagmi({
      address: contracts["leadership"][0],
      abi: leadershipPrizeABI,
      functionName: "claimBNB",
    });
    return result;
  };

  const getLeadershipData = async (numPlayers = 6) => {
    try {
      const result = await axios.get(`${api}/api/v1/prediction/leadership/top`);
      const data = result.data.map((d) => ({
        totalUSD: d.amount,
        objectId: d.address,
      }));
      return data;
    } catch (err) {
      console.log(err);
      return null;
    }
  };

  const getPreviousLeadershipData = async (numPlayers = 6) => {
    try {
      const result = await axios.get(
        `${api}/api/v1/prediction/leadership/top/previous`
      );
      const data = result.data.map((d) => ({
        tickets: Math.floor(d.amount),
        objectId: d.address,
        rewards: d.rewards,
        round: d.round,
      }));

      return data;
    } catch (err) {
      console.log(err);
      return null;
    }
  };

  const getLeadershipRoundEndingTimestamp = async () => {
    try {
      const result = await axios.get(
        `${api}/api/v1/prediction/leadership/ending`
      );
      return result.data;
    } catch (err) {
      console.log(err);
      return null;
    }
  };

  const getUserRank = async () => {
    try {
      const result = await axios.get(
        `${api}/api/v1/prediction/leadership/${userAddress}`
      );
      return result.data;
    } catch (err) {
      return { rank: -1, volume: -1 };
    }
  };

  const getUserRankPrevious = async () => {
    try {
      const result = await axios.get(
        `${api}/api/v1/prediction/leadership/${userAddress}/previous`
      );
      return result.data;
    } catch (err) {
      return { rank: -1, volume: -1 };
    }
  };

  return {
    getLeadershipData,
    getPreviousLeadershipData,
    getLeadershipRoundEndingTimestamp,
    getPrizeAmount,
    havePrize,
    claimPrize,
    getUserRank,
    getUserRankPrevious,
  };
};

export default useLeadership;
