import BigNumber from "bignumber.js";
import useExecuteContract from "./useExecuteContract";
import usePrice from "./usePrice";
import useGroup from "./useGroup";
import useFetch from "./useFetch";
import ENUMS from "../utils/constants/enums";
import multidataABI from "../utils/constants/ABI/multidata.json";
import predictionABI from "../utils/constants/ABI/prediction.json";
import allContracts from "../utils/constants/contracts";
import { useAccount } from "wagmi";
import { updateMessage, messageStatus } from "../utils/message";
import partners from "../utils/constants/partners";
import messages from "../utils/constants/messages";
const Network = ENUMS.Network;
const BIG_TEN = new BigNumber(10);

const usePrediction = () => {
  const { sendContractWagmi, getTxLink, callContractWagmi } =
    useExecuteContract();
  const { getCurrentPrice, getBettingTokenUSDPrice } = usePrice();
  const { getGroup, getTargetGroup } = useGroup();
  const { fetchWithCatch } = useFetch();
  //const userAddress = "0xaaea1ec4a69ff4feb8742cb88d7f6efeec26cbcb";
  const { address } = useAccount();

  const getGroupAddressAndABI = (groupName) => {
    const groupData = getTargetGroup(groupName);
    if (!groupData) return null;
    return {
      address: groupData.address,
      abi: predictionABI,
    };
  };

  const getCurrentRoundNo = async (_groupData = null) => {
    const groupData = _groupData || getGroup();
    const [err, result] = await callContractWagmi({
      address: groupData.address,
      abi: predictionABI,
      functionName: "currentEpoch",
      network: getGroup().network,
    });

    if (err) return 0;
    return result.toString();
  };

  const processRoundData = (
    _round,
    _timestamp,
    _betData,
    _claimable,
    _refundable,
    blockchain = null
  ) => {
    if (blockchain === "TRON") {
      _round.lockPrice = _round.lockPrice.toString();
      _round.closePrice = _round.closePrice.toString();
      _round.bearAmount = _round.bearAmount.toString();
      _round.bullAmount = _round.bullAmount.toString();
    }

    //if (_round[0] || _timestamp[0]) return null;

    const result = { ..._round, ..._timestamp };

    const priceDecimal = 8;
    const decimal = getChainDecimal();

    result.totalAmount = new BigNumber(result.bearAmount.toString()).plus(
      result.bullAmount.toString()
    );

    result.lockPrice = new BigNumber(result.lockPrice.toString())
      .dividedBy(BIG_TEN.pow(priceDecimal))
      .toString();
    result.closePrice = new BigNumber(result.closePrice.toString())
      .dividedBy(BIG_TEN.pow(priceDecimal))
      .toString();
    result.payoutUp = 0;
    result.payoutDown = 0;
    if (result.bearAmount > 0)
      result.payoutDown = new BigNumber(result.totalAmount.toString())
        .dividedBy(result.bearAmount.toString())
        .toFixed(2);
    if (result.bullAmount > 0)
      result.payoutUp = new BigNumber(result.totalAmount.toString())
        .dividedBy(result.bullAmount.toString())
        .toFixed(2);

    result.totalAmount = new BigNumber(result.totalAmount.toString())
      .dividedBy(BIG_TEN.pow(decimal))
      .toFixed(2);

    result.userBet = { ..._betData };
    let amountWon = 0;

    if (_betData && _betData.amount) {
      result.userBet.originalAmount = new BigNumber(
        _betData.amount.toString()
      ).toString();
      result.userBet.amount = new BigNumber(_betData.amount.toString())
        .dividedBy(BIG_TEN.pow(decimal))
        .toFixed(2);

      amountWon =
        _betData.amount > 0
          ? new BigNumber(_betData.amount.toString())
              .multipliedBy(new BigNumber(_round.rewardAmount.toString()))
              .dividedBy(new BigNumber(_round.rewardBaseCalAmount.toString()))
              .dividedBy(BIG_TEN.pow(decimal))
              .toFixed(2)
          : 0;
    }

    result.isClaimable = { status: _claimable, amount: amountWon };
    result.isRefundable = _refundable;

    return result;
  };

  //options = { address, abi, functionName, args}
  const getMultiRoundData = async (roundNo, _numRounds, repeat = 5) => {
    const groupData = getGroup();

    const numRounds = roundNo > _numRounds ? _numRounds : roundNo;
    const multidataAddress = allContracts["multidata"][groupData.network];

    const addr = address || "0x0000000000000000000000000000000000000000";

    const [err, data] = await callContractWagmi({
      address: multidataAddress,
      abi: multidataABI,
      functionName: "getMultiRoundsForUser",
      args: [groupData.address, addr, roundNo - numRounds + 1, numRounds],
      network: groupData.network,
    });

    if (err) {
      //console.log(err)
      return {};
    }

    const result = {};

    for (let i = 0; i < numRounds; i++) {
      if (data[0]) {
        const roundNumber = roundNo - numRounds + 1 + i;
        result[roundNumber] = processRoundData(
          data[i].round,
          data[i].timestamp,
          data[i].betInfo,
          data[i].claimable,
          data[i].refundable,
          groupData.network
        );

        result[roundNumber].roundNo = roundNumber;
      }
    }

    return { 0: result };
  };

  const getUserRoundsForEpochs = async (epochs, groupData) => {
    let betDatas = [];
    let roundDatas = [];
    let claimables = [];
    let refundables = [];
    let epochsLeft = [...epochs];
    let epochsToUse;

    let calls = [];

    const querySlice = 400;

    while (epochsLeft.length > 0) {
      if (epochsLeft.length <= querySlice) {
        epochsToUse = [...epochsLeft];
        epochsLeft = [];
      } else {
        epochsToUse = [...epochsLeft.slice(0, querySlice)];
        epochsLeft = epochsLeft.slice(querySlice, epochsLeft.length);
      }

      calls.push(
        callContractWagmi({
          address: allContracts["multidata"][groupData.network],
          abi: multidataABI,
          functionName: "getUserRoundsForEpochs",
          args: [groupData.address, address, epochsToUse],
          network: groupData.network,
          getPromise: true,
        })
      );
    }

    const callResults = await Promise.all(calls);

    for (let i = 0; i < callResults.length; i++) {
      const results = callResults[i];
      betDatas = [...betDatas, ...results[0]];
      roundDatas = [...roundDatas, ...results[1]];
      claimables = [...claimables, ...results[2]];
      refundables = [...refundables, ...results[3]];
    }

    const result = await processContract(
      epochs,
      betDatas,
      roundDatas,
      claimables,
      refundables,
      groupData
    );
    return result;
  };

  const getUserRounds = async () => {
    const groupData = getGroup();

    const [err, data] = await callContractWagmi({
      address: allContracts["multidata"][groupData.network],
      abi: multidataABI,
      functionName: "getUserRounds",
      args: [groupData.address, address],
      network: groupData.network,
    });

    if (err) return {};

    const result = await processContract(
      data[0],
      data[1],
      data[2],
      data[3],
      data[4],
      groupData
    );
    return result;
  };

  const processContract = async (
    roundNumbers,
    betDatas,
    roundDatas,
    claimables,
    refundables,
    groupData
  ) => {
    const currentRoundNo = await getCurrentRoundNo(groupData);
    const numRounds = betDatas.length;

    const tokenName = groupData.token;
    const bettingTokenName = groupData.bettingToken;

    const tokenUSDPrice = await getCurrentPrice();
    const decimal = getChainDecimal();

    const bettingTokenUSDPrice = await getBettingTokenUSDPrice(
      bettingTokenName
    );

    let netEarning = new BigNumber(0);
    let avgReturn = new BigNumber(0);
    let bestRound = new BigNumber(0);
    let avgBet = new BigNumber(0);
    let totalBet = new BigNumber(0);

    let totalClaimable = new BigNumber("0");
    const claimableRounds = [];

    let won = 0;
    let lost = 0;
    const roundsFiltered = [];

    const numRoundsPerDay = Math.floor(86400 / groupData.intervalSeconds);

    let countToday = 0;

    for (let i = 0; i < numRounds; i++) {
      if (betDatas[i].amount.toString() === "0") {
        continue;
      }

      const round = {};
      const roundData = { ...roundDatas[i] };
      const betData = { ...betDatas[i] };
      const claimable = claimables[i];
      const refundable = refundables[i];

      const isToday =
        currentRoundNo - numRoundsPerDay < parseInt(roundNumbers[i]);

      if (isToday) countToday++;

      round.roundData = roundData;
      round.amount = new BigNumber(betData.amount.toString());
      round.refereeAmount = new BigNumber(betData.refereeAmount.toString());
      round.rewardAmount = new BigNumber(roundData.rewardAmount.toString());
      round.rewardBaseCalAmount = new BigNumber(
        roundData.rewardBaseCalAmount.toString()
      );

      round.won = claimable || betData.claimed;

      round.position = betData.position;

      round.amountWon =
        round.rewardBaseCalAmount > 0
          ? round.amount
              .multipliedBy(round.rewardAmount)
              .dividedBy(round.rewardBaseCalAmount)
          : 0;

      const lockPrice = new BigNumber(roundData.lockPrice.toString())
        .dividedBy(BIG_TEN.pow(8))
        .toString();
      const closePrice = new BigNumber(roundData.closePrice.toString())
        .dividedBy(BIG_TEN.pow(8))
        .toString();
      const bearAmount = new BigNumber(roundData.bearAmount.toString())
        .dividedBy(BIG_TEN.pow(8))
        .toString();
      const bullAmount = new BigNumber(roundData.bullAmount.toString())
        .dividedBy(BIG_TEN.pow(8))
        .toString();
      const totalAmount = new BigNumber(roundData.bullAmount.toString())
        .plus(new BigNumber(roundData.bearAmount.toString()))
        .dividedBy(BIG_TEN.pow(8))
        .toString();

      round.roundData.lockPrice = lockPrice;
      round.roundData.closePrice = closePrice;
      round.roundData.bearAmount = bearAmount;
      round.roundData.bullAmount = bullAmount;
      round.roundData.totalAmount = totalAmount;

      round.roundResult = parseInt(closePrice) > parseInt(lockPrice) ? 0 : 1;
      if (parseInt(closePrice) === parseInt(lockPrice)) round.roundResult = 2;

      round.currentEpoch = roundNumbers[i];
      round.roundNo = round.currentEpoch;

      round.ongoingRound = round.currentEpoch >= currentRoundNo - 1;

      const samePrice = round.roundResult && round.roundResult === 2;

      if (refundable) {
        round.isRefundable = true;
        claimableRounds.push(parseInt(round.currentEpoch));
        totalClaimable = totalClaimable.plus(round.amount);
      } else if (round.currentEpoch === currentRoundNo - 2) {
        round.waitingForRefundCheck = true;
      }

      if (isToday) {
        totalBet = totalBet.plus(round.amount);
      }
      round.profit = 0;
      if (round.won) {
        if (!round.amountWon) {
          round.amountWon = 0;
        }
        const profit = BigNumber(round.amountWon.toString())
          .plus(round.refereeAmount)
          .minus(round.amount);
        round.profit = profit;

        if (isToday) {
          won++;
          netEarning = netEarning.plus(profit);
          if (profit > bestRound) bestRound = profit;
        }

        if (!round.claimed) {
          if (!claimable) round.claimed = true;
          else {
            claimableRounds.push(parseInt(round.currentEpoch));
            totalClaimable = totalClaimable
              .plus(round.amountWon)
              .plus(round.refereeAmount);
          }
        }
      } else if (round.isRefundable || samePrice) {
        round.profit = 0;
      } else if (!round.ongoingRound) {
        round.profit = -round.amount;

        if (isToday) {
          lost++;
          netEarning = netEarning.minus(round.amount);
        }
      }
      if (!round.amountWon) round.amountWon = 0;

      round.amount = BigNumber(round.amount.toString())
        .dividedBy(BIG_TEN.pow(decimal))
        .toFixed(2);
      round.refereeAmount = BigNumber(round.refereeAmount.toString())
        .dividedBy(BIG_TEN.pow(decimal))
        .toFixed(2);
      round.amountWon = BigNumber(round.amountWon.toString())
        .dividedBy(BIG_TEN.pow(decimal))
        .toFixed(2);
      round.profit = BigNumber(round.profit.toString())
        .dividedBy(BIG_TEN.pow(decimal))
        .toFixed(2);
      //to be deleted
      round.priceDiff = round.profit;
      roundsFiltered.push(round);
    }

    avgBet =
      countToday == 0
        ? 0
        : totalBet
            .dividedBy(countToday)
            .dividedBy(BIG_TEN.pow(decimal))
            .toFixed(2);
    avgReturn =
      countToday === 0
        ? 0
        : netEarning
            .dividedBy(countToday)
            .dividedBy(BIG_TEN.pow(decimal))
            .toFixed(2);
    netEarning = netEarning.dividedBy(BIG_TEN.pow(decimal)).toFixed(2);
    bestRound = bestRound.dividedBy(BIG_TEN.pow(decimal)).toFixed(2);
    totalBet = totalBet.dividedBy(BIG_TEN.pow(decimal)).toFixed(2);

    totalClaimable = totalClaimable.dividedBy(BIG_TEN.pow(decimal)).toFixed(2);

    const result = {
      numRounds: roundsFiltered.length,
      countToday,
      won,
      lost,
      bettingTokenName,
      bettingTokenUSDPrice,
      tokenName,
      tokenUSDPrice,
      netEarning,
      avgReturn,
      bestRound,
      avgBet,
      totalBet,
      rounds: roundsFiltered,
      totalClaimable,
      claimableRounds,
    };

    return result;
  };

  const getRoundData = async (roundNo, groupData) => {
    const [_round, _timestamp] = await Promise.all([
      callContractWagmi({
        address: groupData.address,
        abi: predictionABI,
        functionName: "rounds",
        args: [roundNo],
        getPromise: true,
        network: groupData.network,
      }),
      callContractWagmi({
        address: groupData.address,
        abi: predictionABI,
        functionName: "timestamps",
        args: [roundNo],
        getPromise: true,
        network: groupData.network,
      }),
    ]);

    return processRoundData(_round, _timestamp, groupData.network);
  };

  const isClaimable = async (roundNo) => {
    try {
      const groupData = getGroup();

      const [isClaimable, ledger, roundData] = await Promise.all([
        callContractWagmi({
          address: groupData.address,
          abi: predictionABI,
          functionName: "claimable",
          args: [roundNo, address],
          getPromise: true,
          network: getGroup().network,
        }),
        callContractWagmi({
          address: groupData.address,
          abi: predictionABI,
          functionName: "ledger",
          args: [roundNo, address],
          getPromise: true,
          network: getGroup().network,
        }),
        callContractWagmi({
          address: groupData.address,
          abi: predictionABI,
          functionName: "rounds",
          args: [roundNo],
          getPromise: true,
          network: getGroup().network,
        }),
      ]);

      if (!isClaimable) return { status: false };

      const amountWon = new BigNumber(ledger.amount.toString())
        .multipliedBy(new BigNumber(roundData.rewardAmount.toString()))
        .dividedBy(new BigNumber(roundData.rewardBaseCalAmount.toString()))
        .integerValue(BigNumber.ROUND_DOWN);

      return { status: !ledger.claimed, amount: amountWon.toString() };
    } catch {
      return { status: false };
    }
  };

  const isRefundable = async (roundNo) => {
    try {
      const groupData = getGroup();

      const [isRefundable, ledger] = await Promise.all([
        callContractWagmi({
          address: groupData.address,
          abi: predictionABI,
          functionName: "refundable",
          args: [roundNo, address],
          getPromise: true,
          network: groupData.network,
        }),
        callContractWagmi({
          address: groupData.address,
          abi: predictionABI,
          functionName: "ledger",
          args: [roundNo, address],
          getPromise: true,
          network: groupData.network,
        }),
      ]);

      if (!isRefundable) return false;
      return !ledger.claimed; //ledger.claimed
    } catch {
      return false;
    }
  };

  const getChainDecimal = () => {
    const groupData = getGroup();
    if (
      groupData.network === Network.TRON ||
      groupData.network === Network.TRON_TESTNET
    )
      return 6;
    return 18;
  };

  const getUserBet = async (roundNo) => {
    const fallback = {
      position: 0,
      originalAmount: 0,
      amount: 0,
      refereeAmount: 0,
      referrerAmount: 0,
      stakingAmount: 0,
      claimed: false,
    };
    try {
      if (roundNo < 0) return fallback;

      const groupData = getGroup();

      const [errM, _ledger] = await callContractWagmi({
        address: groupData.address,
        abi: predictionABI,
        functionName: "ledger",
        args: [roundNo, address],
      });

      if (errM) return fallback;

      const ledger = { ..._ledger };
      const decimal = getChainDecimal();
      ledger.originalAmount = new BigNumber(
        ledger.amount.toString()
      ).toString();
      ledger.amount = new BigNumber(ledger.amount.toString())
        .dividedBy(BIG_TEN.pow(decimal))
        .toFixed(2);
      return ledger;
    } catch (err) {
      console.log(err);
      return fallback;
    }
  };

  const hasStopped = async () => {
    const groupData = getGroup();
    const [err, result] = await callContractWagmi({
      address: groupData.address,
      abi: predictionABI,
      functionName: "paused",
      network: getGroup().network,
    });

    if (err) return false;
    return result;
  };

  const hasGroupStopped = async (groupName) => {
    const groupData = getTargetGroup(groupName);
    const [err, result] = await callContractWagmi({
      address: groupData.address,
      abi: predictionABI,
      functionName: "paused",
      network: groupData.network,
    });
    if (err) return false;
    return result;
  };

  const getCurrentRoundLockTimestamp = async () => {
    const groupData = getGroup();
    const [err, currentRoundNo] = await callContractWagmi({
      address: groupData.address,
      abi: predictionABI,
      functionName: "currentEpoch",
      network: getGroup().network,
    });
    if (err) return 0;

    const [errTs, roundData] = await callContractWagmi({
      address: groupData.address,
      abi: predictionABI,
      functionName: "timestamps",
      args: [currentRoundNo],
      network: getGroup().network,
    });

    if (errTs) return 0;
    return roundData.lockTimestamp;
  };

  const getRoundLockTimestamp = async (roundNo) => {
    const groupData = getGroup();
    const [err, roundData] = await callContractWagmi({
      address: groupData.address,
      abi: predictionABI,
      functionName: "timestamps",
      args: [roundNo],
      network: getGroup().network,
    });

    if (err) return 0;
    return roundData.lockTimestamp;
  };

  const isValidWalletAddress = (addr) => {
    if (!addr) return false;
    return addr.length == 42 && addr.substring(0, 2) == "0x";
  };

  const checkAndGetPartnerWallet = (_partnerAddress) => {
    if (!_partnerAddress) return _partnerAddress;
    const partnerName = _partnerAddress.toUpperCase();
    if (partners[partnerName]) {
      //console.log('partner is', partnerName, partners[partnerName])
      localStorage.setItem("partner_" + address, partners[partnerName]);
      return partners[partnerName];
    }
    return _partnerAddress;
  };

  const betBear = async (roundNo, amount, _partnerAddress = null) => {
    const partnerAddress = checkAndGetPartnerWallet(_partnerAddress);
    const partnerWallet = isValidWalletAddress(partnerAddress)
      ? partnerAddress
      : null;
    const functionName = partnerWallet ? "hostedBetBear" : "betBear";
    const amountWei = new BigNumber(amount.toString()).multipliedBy(
      BIG_TEN.pow(getChainDecimal())
    );
    const result = await _bet(
      roundNo,
      amountWei.toString(),
      functionName,
      partnerWallet
    );

    return result;
  };

  const betBull = async (roundNo, amount, _partnerAddress = null) => {
    const partnerAddress = checkAndGetPartnerWallet(_partnerAddress);
    const partnerWallet = isValidWalletAddress(partnerAddress)
      ? partnerAddress
      : null;
    const functionName = partnerWallet ? "hostedBetBull" : "betBull";
    const amountWei = new BigNumber(amount.toString()).multipliedBy(
      BIG_TEN.pow(getChainDecimal())
    );
    const result = await _bet(
      roundNo,
      amountWei.toString(),
      functionName,
      partnerWallet
    );
    return result;
  };

  const isOnCorrectChain = async () => {
    return true;
    //const groupData = getGroup()
    //const chainId = await web3wallet.eth.net.getId()
    //return rpcs[groupData.network].chainId.toString() === chainId.toString()
  };

  const _bet = async (roundNo, amount, functionName, partnerAddress) => {
    const groupData = getGroup();

    const options = {
      address: groupData.address,
      abi: predictionABI,
      functionName,
      getGasPrice: true,
      amount,
      network: groupData.network,
      skipSuccessMessage: true,
      info: messages.info[functionName].replace(
        "REPLACEtoken",
        groupData.token
      ),
    };

    options.args = functionName.includes("hosted")
      ? [roundNo, partnerAddress]
      : [roundNo];
    options.value = amount;

    const result = await sendContractWagmi(options);

    if (result.status) {
      result.message = result.message.replace("REPLACEtoken", groupData.token);
      updateMessage(
        result.messageId,
        messageStatus.succesfull,
        result.message,
        result.txlink
      );
    }

    return result;
  };

  const claim = async (roundsArray, _groupData) => {
    const groupData = _groupData || getGroup();
    const options = {
      address: groupData.address,
      abi: predictionABI,
      functionName: "claim",
      getGasPrice: true,
      network: groupData.network,
      args: [roundsArray],
    };

    const result = await sendContractWagmi(options);
    return result;
  };

  return {
    getGroupAddressAndABI,
    getCurrentRoundNo,
    getRoundData,
    isClaimable,
    isRefundable,
    getUserBet,
    hasStopped,
    hasGroupStopped,
    getCurrentRoundLockTimestamp,
    getRoundLockTimestamp,
    betBull,
    betBear,
    claim,
    getMultiRoundData,
    getUserRounds,
    getUserRoundsForEpochs,
    isOnCorrectChain,
    getChainDecimal,
  };
};

export default usePrediction;
