import BigNumber from "bignumber.js";
import usePrediction from "./usePrediction";
import useMoralis from "./useMoralis";
import useGroup from "./useGroup";
import ENUMS from "../utils/constants/enums";
import { useQuery, gql, useLazyQuery } from "@apollo/client";
import { apolloClient } from "../components/Apollo";
import { useAccount } from "wagmi";

const Network = ENUMS.Network;
const BIG_TEN = new BigNumber(10);

const useHistory = () => {
  const { getCurrentRoundNo, getUserRounds, getUserRoundsForEpochs } =
    usePrediction();
  const { getGroup } = useGroup();
  const { address: userAddress } = useAccount();

  const fetchUserRounds = async (groupData) => {
    try {
      let uri = "https://api.thegraph.com/subgraphs/name/cmdevbc/prdt-matic";
      if (groupData.network == 0)
        uri = "https://api.thegraph.com/subgraphs/name/cmdevbc/prdtbscbnb";

      const query = `query getRounds($sender: String, $contract: String) {
        predictionsBets(
          orderBy: currentEpoch
          orderDirection: desc
          where: { sender: $sender, contract: $contract }
          skip: 0
          first: 1000
        ) {
          currentEpoch
        }
      }`;

      let results = await fetch(uri, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },

        body: JSON.stringify({
          query,
          variables: {
            contract: groupData.address,
            sender: userAddress,
          },
        }),
      });

      const result = await results.json();
      const data = result.data.predictionsBets;

      return data;
    } catch (err) {
      return [];
    }
  };

  const getUserGroupHistory = async (groupData) => {
    const roundsData = await fetchUserRounds(groupData);
    const epochs = roundsData
      .reduce((acc, cur) => {
        const round = cur.currentEpoch;
        acc.push(round);
        return acc;
      }, [])
      .reverse();

    const data = await getUserRoundsForEpochs(epochs, groupData);
    return data;
  };

  const getUserHistory = async (forceReload = false) => {
    const groupData = getGroup();

    if (!groupData.classic) {
      return getUserRounds();
    }

    const roundsData = await fetchUserRounds(groupData);
    const epochs = roundsData
      .reduce((acc, cur) => {
        const round = cur.currentEpoch;
        acc.push(round);
        return acc;
      }, [])
      .reverse();

    const currentRoundNo = await getCurrentRoundNo();
    for (let i = 30; i > 0; i--) {
      const roundNo = currentRoundNo - i;
      if (parseInt(epochs[epochs.length - 1]) < roundNo) epochs.push(roundNo);
    }

    const data = await getUserRoundsForEpochs(epochs, groupData);
    return { 0: data };
  };

  return { getUserHistory, getUserGroupHistory };
};

export default useHistory;
